import React, { useState } from "react";
import { Card } from "../common";
import Line from "./Line";
import Multiple from "./Multiple";
import Stacked from "./Stacked";
import Category from "./Category";
import { Link } from "react-router-dom";
import LineTabular from "../tabular/LineTabular";
import MultipleTabular from "../tabular/MultipleTabular";
import StackedTabular from "../tabular/StackedTabular";
import CategoryTabular from "../tabular/CategoryTabular";
import moment from "moment";

export default class Chart extends React.Component {
  state = {
    isTableView: false,
  };
  toggleTabular = () => {
    this.setState({
      isTableView: !this.state.isTableView,
    });
  };
  render() {
    let MyComponent, MyComponentTabular;
    const {
      Type,
      Data,
      Title,
      Metric,
      MetricLabel,
      Increment,
      Items,
      frequencyType,
      duration,
    } = this.props;
    const isEmpty = Array.isArray(Data)
      ? Data.length === 0
      : Object.keys(Data).length === 0;
    let toggleOptionHide = this.props.hideToggleView;
    let newProps = {
      type: Type,
      data: ["line", "category"].includes(Type)
        ? Data.map((elem) => ({ x: elem.X, y: elem.Y }))
        : Data,
      title: Title,
      metric: Metric,
      metricLabel: MetricLabel,
      increment: Increment,
      duration: duration,
      format: frequencyType == "monthly" ? "MMMM" : "MMM DD",
      tabularFormat:
        frequencyType == "monthly" ? "MMMM, YYYY" : "MMMM DD, YYYY",
      items: Type === "stacked" ? Object.keys(Data) : Items,
    };
    if (this.props.hideXAxis) {
      newProps.hideXAxis = this.props.hideXAxis;
    }
    if (this.props.format) {
      newProps.format = this.props.format;
    }
    switch (Type) {
      case "line":
        MyComponent = Line;
        MyComponentTabular = LineTabular;
        break;
      case "multiple":
        MyComponent = Multiple;
        MyComponentTabular = MultipleTabular;
        break;
      case "stacked":
        MyComponent = Stacked;
        MyComponentTabular = StackedTabular;
        break;
      case "category":
        MyComponent = Category;
        MyComponentTabular = CategoryTabular;
        break;
    }
    const incrementDisplay =
      Increment === 0 ? (
        ""
      ) : Increment > 0 ? (
        <span className="pos-inc">+{Increment}%</span>
      ) : (
        <span className="neg-inc">-{Increment}%</span>
      );
    return (
      <Card style={{ padding: "15px 20px", height: "auto" }}>
        <div className="card-header">
          <div className="card-title">{Title}</div>
          <div className="card-right-toggles">
            {!toggleOptionHide && (
              <div className="tabular" onClick={this.toggleTabular}>
                {this.state.isTableView ? "Graph View" : "Tabular View"} →
              </div>
            )}
          </div>
        </div>
        {!isEmpty && (
          <div className="card-header-lower">
            <div className="card-left">
              <div className="card-text">{duration}</div>
              <div className="card-text">{incrementDisplay}</div>
            </div>
            <div className="card-right pad-right">
              <div className="big-number">{Metric.toLocaleString("en-IN")}</div>
              <div className="card-text collapse">{MetricLabel}</div>
            </div>
          </div>
        )}
        {isEmpty && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: 300,
            }}
          >
            <img
              src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/empty.svg"
              className="App-logo"
              alt="logo"
            />
            <h6 style={{ marginTop: 25 }}>No Data Collected</h6>
            <p>Data will show up once an API is hit</p>
          </div>
        )}
        {!this.state.isTableView && !isEmpty && <MyComponent {...newProps} />}
        {this.state.isTableView && !isEmpty && (
          <MyComponentTabular
            style={{ margin: "0 -15px" }}
            {...newProps}
            toggleTabular={this.toggleTabular}
          />
        )}
      </Card>
    );
  }
}
