import moment from "moment";
import React from "react";
import styled from "styled-components";

import "./deviceconnect.scss";

const InvalidWindow = styled.table`
	width: 100%;
	height: fit-content;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	background: #fce5e5;
	border: 1.5px solid #eca7a7;
	margin: 20px 0;
	border-radius: 4px;
	align-items: center;
	p {
		margin: 0;
		font-size: 15px;
		font-weight: 400;
	}
	a {
		cursor: pointer;
		color: #24ca7a;
		font-weight: 400;
		:link {
			color: #24ca7a;
		}
		:hover,
		:active {
			color: #24ca7a;
			text-decoration: underline;
		}
		:visited {
			color: #24ca7a;
			text-decoration: none;
		}
	}
`;

const Dable = styled.table`
	text-align: center;
	border: 1px solid #ececec;
	border-radius: 2px;
	width: 100%;
	border-collapse: collapse;
	background: white;

	overflow-y: hidden;

	a {
		cursor: pointer;
		color: #24ca7a;
		font-weight: 400;
		:link {
			color: #24ca7a;
		}
		:hover,
		:active {
			color: #24ca7a;
			text-decoration: underline;
		}
		:visited {
			color: #24ca7a;
			text-decoration: none;
		}
	}
	td,
	th {
		padding: 0.4rem 0.8rem;
	}
	td {
		font-weight: 400;
		font-size: 14px;
		border-bottom: 0;
		color: #3e3e3e;
		border-bottom: 0;
	}

	th {
		font-size: 12px;
		color: #3e3e3e;
		/* border: 2px solid white; */
	}
`;
function RequestDetailTable(props) {
	return (
		<>
			{props.invalidRows != 0 && props.status != "Completed" ? (
				<InvalidWindow>
					<p>
						We found {props.invalidRows} invalid rows in your file. Please review and upload
						them again.{" "}
					</p>{" "}
					<a href={props.invalidLink} download>Download</a>
				</InvalidWindow>
			) : (
				""
			)}
			<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
				<div style={{ overflowX: "scroll", width: "100%" }}>
					<Dable>
						<tr style={{ backgroundColor: "white" }}>
							<th style={{ paddingLeft: 50 }}>Requested at</th>

							{props.completedAt && <th>Completed at</th>}
							<th>Request ID</th>
							<th>Customers</th>
							<th>Status</th>
							<th>Action</th>
						</tr>

						<tr style={{ backgroundColor: "#f8f8f8" }}>
							<td style={{ paddingLeft: 50 }}>
								<div style={{ fontWeight: 400 }}>
									{moment(props.createdAt).format("MMM DD, YYYY")}
									<br />
									<span style={{ fontSize: "12px" }}>
										{moment(props.createdAt).format("hh:mm A")}
									</span>
								</div>
							</td>
							{props.completedAt && (
								<td>
									<div style={{ fontWeight: 400 }}>
										{moment(props.completedAt).format("MMM DD, YYYY")} <br />
										<span style={{ fontSize: "12px" }}>
											{moment(props.completedAt).format("LT")}
										</span>
									</div>
								</td>
							)}
							<td>{props.id}</td>
							<td>{props.completed ? props.completed : props.customers}</td>
							<td>{props.status}</td>
							<td>
								<a
									href={
										props.status == "Completed" ? props.outputLink : props.validatedLink
									}
									download
								>
									Download
								</a>
							</td>
						</tr>
						{props.invalidRows != null &&
						props.invalidRows != 0 &&
						props.status != "Completed" ? (
							<tr style={{ backgroundColor: "white" }}>
								<td style={{ paddingLeft: 50 }}>
									<div style={{ fontWeight: 400 }}>
										{moment(props.createdAt).format("MMM DD, YYYY")}
										<br />
										<span style={{ fontSize: "12px" }}>
											{moment(props.createdAt).format("hh:mm A")}
										</span>
									</div>
								</td>
								{props.completedAt && (
									<td>
										<div style={{ fontWeight: 400 }}>
											{moment(props.completedAt).format("MMM DD, YYYY")} <br />
											<span style={{ fontSize: "12px" }}>
												{moment(props.completedAt).format("LT")}
											</span>
										</div>
									</td>
								)}
								<td>{props.id}</td>
								<td>{props.invalidRows}</td>
								<td>Invalid Rows</td>
								<td>
									<a href={props.invalidLink}>Download</a>
								</td>
							</tr>
						) : (
							""
						)}
					</Dable>
				</div>
			</div>
		</>
	);
}
export default RequestDetailTable;
