import React, { useEffect } from "react";
import OtpInput from "react-otp-input";
import "spectre.css/dist/spectre.min.css";

import styled from "styled-components";
const EditNumber = styled.div`
  color: #24ca7a;
  font-weight: 400;
  font-size: 0.8em;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const Submit = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.4em 0 0.5em 0;
  width: 100%;
  cursor: ${(props) => (props.enabled ? "pointer" : "default")};
  background-color: ${(props) => (props.enabled ? "#24ca7a" : "#cacaca")};
  border-radius: 2px;
  color: white;
  font-weight: 600;
  font-size: 0.9;
  padding: 0.58em 0;
  border: none;
  outline: none;
  box-shadow: none;
`;
const SubmitFinal = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5em 0 2em 0;
  width: 100%;
  cursor: ${(props) =>
    props.submittingState == "Initial"
      ? "pointer"
      : props.submittingState == "Submitting" ||
        props.submittingState == "OTP short"
      ? "default"
      : ""};
  background-color: ${(props) =>
    props.submittingState == "Initial"
      ? "#24ca7a"
      : props.submittingState == "Submitting" ||
        props.submittingState == "OTP short"
      ? "#cacaca"
      : ""};
  border-radius: 2px;
  color: white;
  font-weight: 600;
  font-size: 0.9;
  padding: 0.58em 0;
  border: none;
  outline: none;
  box-shadow: none;
`;

const PassStrength = styled.div`
  font-weight: bold;
  color: ${(props) =>
    props.strength == "Poor"
      ? "#d92121"
      : props.strength == "Medium"
      ? "#ffaa1d"
      : props.strength == "Strong"
      ? "#24ca7a"
      : ""};
`;
const Label = styled.div`
  font-size: 0.9em;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-top: 1.2em;
`;

const SubmitSecondary = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.2em 0.5em 2.2em 0;
  width: 50%;
  cursor: ${(props) =>
    props.OTPState == "Send Code"
      ? "pointer"
      : props.OTPState == "Sent" || props.OTPState == "Sending"
      ? "default"
      : ""};
  background: white;
  border-radius: 2px;
  color: ${(props) =>
    props.OTPState == "Send Code"
      ? "#24ca7a"
      : props.OTPState == "Sent" || props.OTPState == "Sending"
      ? "#cacaca"
      : ""};
  font-weight: 600;
  font-size: 0.9em;
  padding-top: 7px;
  padding-bottom: 8px;
  border: 0.1em solid;
  border-color: ${(props) =>
    props.OTPState == "Send Code"
      ? "#24ca7a"
      : props.OTPState == "Sent" || props.OTPState == "Sending"
      ? "#cacaca"
      : ""};
  box-shadow: none;
  &:hover {
    background-color: ${(props) =>
      props.OTPState == "Send Code" ? "#24ca7a" : "white"};
    border: ${(props) =>
      props.OTPState == "Send Code"
        ? "0.1em solid #24ca7a"
        : "0.1em solid #cacaca"};
    color: ${(props) => (props.OTPState == "Send Code" ? "white" : "#cacaca")};
  }
`;

const Input = styled.input`
  padding: 0.6em 1.3em;
  font-size: 0.85em;
  margin-top: 0.7em;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
`;
const FakePhoneCode = styled.div`
  display: flex;
  align-items: center;
  padding: 0.65em 1.3em;
  font-size: 0.85em;
  margin-top: 0.7em;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
  width: fit-content;
`;

const sendOTP = async (
  event,
  phone,
  setPhoneErr,
  setOTPState,
  setPhoneErrText
) => {
  event.preventDefault();
  if (phone.length == 10) {
    setOTPState("Sending");
    setPhoneErr(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: phone,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://dashboardapi.finbox.in/v1/user/sendotp",
      requestOptions
    );
    const output = await response.text();
    const json = JSON.parse(output);
    console.log(json);
    if (json.Status == true) {
      setOTPState("Sent");
    } else {
      setOTPState("Send Code");
      setPhoneErr(true);
      setPhoneErrText(json.Error);
    }
  } else {
    setPhoneErr(true);
  }
};

const strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})"
);
const mediumRegex = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
);
// const emailRegex = new RegExp("/(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i")
const emailRegex = new RegExp(
  "^([a-zA-Z0-9_\\-.]+)@([a-zA-Z0-9_\\-.]+)\\.([a-zA-Z]{2,5})$"
);
const commonMailRegex = new RegExp(
  "^([a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|hotmail.com|yahoo.com|yahoo.in))$"
);

function SignupForm({
  heading,
  subheading,
  progressState,
  setProgressState,
  name,
  setName,
  organisation,
  setOrganisation,
  setEmail,
  email,
  setPassword,
  password,
  phone,
  setPhone,
  signUp,
  accountExist,
  validationError,
  setValidationError,
  submittingState,
  setSubmittingState,
}) {
  const [strength, setStrength] = React.useState(null);
  const [validStrength, setValidStrength] = React.useState(false);
  const [passErr, setPassErr] = React.useState(false);
  const [nameErr, setNameErr] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState(false);
  const [emailErrText, setEmailErrText] = React.useState(
    "*Enter a valid Email"
  );
  const [phoneErr, setPhoneErr] = React.useState(false);
  const [phoneErrText, setPhoneErrText] = React.useState(
    "*Phone Number should be of 10 digits"
  );
  const [orgErr, setOrgErr] = React.useState(false);
  const [OTPErr, setOTPErr] = React.useState(false);

  const [OTPState, setOTPState] = React.useState("Send Code");
  const [OTP, setOTP] = React.useState("");

  useEffect(() => {
    if (OTP.toString().length == 6) {
      setSubmittingState("Initial");
    } else if (OTP.toString().length < 6) {
      setSubmittingState("OTP short");
    }
  }, [OTP]);

  function checkemail(email) {
    setEmailErr(
      !emailRegex.test(email.toLowerCase()) ||
        commonMailRegex.test(email.toLowerCase())
    );
    setEmailErrText(
      "*Enter a valid Email. (Personal emails are also not allowed)"
    );
  }

  function analyze(event) {
    if (strongRegex.test(event.target.value)) {
      setStrength("Strong");
      setValidStrength(true);
      setPassErr(false);
    } else if (mediumRegex.test(event.target.value)) {
      setStrength("Medium");
      setValidStrength(true);
      setPassErr(false);
    } else {
      setStrength("Poor");
      setValidStrength(false);
      setPassErr(true);
    }
  }

  async function checkIfEmailExists() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://dashboardapi.finbox.in/v1/user/checkemail",
      requestOptions
    );
    const output = await response.text();
    const json = JSON.parse(output);
    if (!json.Status) {
      setEmailErr(true);
      setEmailErrText(json.Error);
    } else {
      setProgressState(2);
    }
  }

  function callSubmit(event) {
    if (OTP.toString().length == 6 && phone.length == 10) {
      setSubmittingState("Submitting");
      signUp(event, email, password, phone, organisation, name, OTP);
    } else {
      if (OTP.length != 6) {
        setOTPErr(true);
        setPhoneErrText("*Phone Number should be of 10 digits");
        console.log(OTPErr);
      }
      if (phone.length != 10) {
        setPhoneErr(true);
        setPhoneErrText("*Phone Number should be of 10 digits");
        console.log(OTPErr);
      }
    }
  }

  //   mailID && checkemail(email);

  return (
    <>
      {progressState == 1 ? (
        <div style={{ width: "100%", marginTop: "25px" }}>
          <h4 style={{ color: "#3e3e3e", fontWeight: "bold" }}>{heading}</h4>
          <p
            style={{
              fontSize: "0.85em",
              color: "#777777",
              marginTop: "15",
            }}
          >
            {subheading}
          </p>
          <form
            style={{ marginTop: "10px" }}
            // onSubmit={(event) => event.preventDefault()}
          >
            <Label>
              Name
              <Input
                type="text"
                name="name"
                placeholder="Enter your full name"
                value={name}
                onBlur={() => setNameErr(name == "")}
                onChange={(event) => setName(event.target.value)}
              />
            </Label>
            {nameErr && (
              <span
                style={{ color: "red", fontSize: "0.7em", fontWeight: "400" }}
              >
                *Name can not be empty
              </span>
            )}

            <Label>
              Organisation
              <Input
                type="text"
                name="organisation"
                placeholder="Enter the name of your organisation"
                value={organisation}
                onBlur={() => setOrgErr(organisation == "")}
                onChange={(event) => setOrganisation(event.target.value)}
              ></Input>
            </Label>
            {orgErr && (
              <span
                style={{ color: "red", fontSize: "0.7em", fontWeight: "400" }}
              >
                *Company name can not be empty
              </span>
            )}
            <Label>
              E-Mail
              <Input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onBlur={() => checkemail(email)}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              ></Input>
            </Label>
            {emailErr && (
              <span
                style={{ color: "red", fontSize: "0.7em", fontWeight: "400" }}
              >
                {emailErrText}
              </span>
            )}
            <Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "400",
                }}
              >
                Password{" "}
                <PassStrength strength={strength}>{strength}</PassStrength>
              </div>
              <Input
                type="password"
                name="password"
                placeholder="6 Characters, atleast 1 Uppecase, 1 lowercase and a number"
                onBlur={() => setPassErr(!validStrength)}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  analyze(event);
                }}
              ></Input>
            </Label>
            {passErr && (
              <span
                style={{ color: "red", fontSize: "0.7em", fontWeight: "400" }}
              >
                *Password must be 6 characters long containing atleast 1
                uppercase, 1 lowecase and 1 number
              </span>
            )}
          </form>
          <Submit
            onClick={(event) => {
              event.preventDefault();
              if (
                validStrength &&
                !emailErr &&
                name != "" &&
                organisation != ""
              ) {
                checkIfEmailExists();
              }
            }}
            enabled={
              validStrength && !emailErr && name != "" && organisation != ""
            }
          >
            Continue{" "}
            <img
              src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/continue.svg"
              style={{ marginLeft: "10px" }}
            />
          </Submit>
        </div>
      ) : progressState == 2 ? (
        <div style={{ width: "100%", marginTop: "2.5em" }}>
          <h4 style={{ color: "#3e3e3e", fontWeight: "bold" }}>{heading}</h4>
          <p
            style={{
              fontSize: "0.85em",
              color: "#777777",
              marginTop: "15",
            }}
          >
            {subheading}
          </p>
          {/* <form
            style={{ marginTop: "10px" }}
            //   onSubmit={(event) => handleSubmit(event, password, email)}
          > */}
          <Label>
            Phone Number
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FakePhoneCode>
                <img
                  src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/india.png"
                  style={{ marginRight: "8px" }}
                />
                +91
              </FakePhoneCode>
              <Input
                type="number"
                name="phone"
                placeholder="Enter your 10 digit phone number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                style={{ marginLeft: "20px", flex: "1" }}
              ></Input>
            </div>
          </Label>
          {phoneErr && (
            <span
              style={{ color: "red", fontSize: "0.7em", fontWeight: "400" }}
            >
              {phoneErrText}
            </span>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <SubmitSecondary
              OTPState={OTPState}
              onClick={(event) => {
                if (!accountExist && OTPState == "Send Code") {
                  sendOTP(
                    event,
                    phone,
                    setPhoneErr,
                    setOTPState,
                    setPhoneErrText
                  );
                  setValidationError("");
                  setSubmittingState("OTP short");
                  setOTP("");
                }
              }}
            >
              {OTPState == "Send Code"
                ? OTPState
                : OTPState == "Sending"
                ? "Sending..."
                : OTPState == "Sent"
                ? OTPState
                : ""}
            </SubmitSecondary>{" "}
            {OTPState == "Sent" || OTPState == "Sending" ? (
              <EditNumber onClick={() => setOTPState("Send Code")}>
                Edit Number
              </EditNumber>
            ) : (
              ""
            )}
          </div>
          {/* </form> */}
          {OTPState == "Sent" ? (
            <>
              <form>
                <span
                  style={{
                    fontWeight: "400",
                    color: "#3e3e3e",
                    fontSize: "1.1em",
                  }}
                >
                  Please confirm your phone
                </span>
                <p
                  style={{
                    fontSize: "0.85em",
                    color: "#777777",
                    margin: "1em 0",
                  }}
                >
                  Enter the one time verification code send to your mobile
                  number.
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <OtpInput
                    onChange={(otp) => {
                      setOTP(otp);
                      setOTPErr(false);
                      setValidationError(false);
                    }}
                    numInputs={6}
                    // separator={<span>-</span>}
                    value={OTP}
                    inputStyle={{
                      padding: " 0.65em ",
                      fontSize: "0.85em",
                      marginTop: "0.7em",
                      border: "1px solid #dee2e9",
                      boxSizing: "border-box",
                      borderRadius: "10px",
                      width: "3em",
                      borderColor: "#cacaca",
                    }}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "1em",
                    }}
                    isInputNum="true"
                    hasErrored={OTPErr || validationError}
                    errorStyle={{ borderColor: "red" }}
                  />
                </div>
                {!accountExist ? (
                  <p
                    style={{
                      fontSize: "0.9em",
                      color: "#3e3e3e",
                      fontWeight: "400",
                      margin: "0 0 1em 0",
                    }}
                  >
                    Didn’t receive the code?{" "}
                    <a
                      style={{
                        color: "#24ca7a",
                        cursor: "pointer",
                        marginBottom: "1.5em",
                        fontWeight: "700",
                      }}
                      onClick={(event) => {
                        sendOTP(event, phone, setPhoneErr, setOTPState);
                        setValidationError("");
                        setOTP("");
                        setSubmittingState("OTP short");
                      }}
                    >
                      Resend Code{" "}
                      <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/replay.svg" />
                    </a>
                  </p>
                ) : (
                  ""
                )}
              </form>
              {validationError && (
                <div
                  style={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: "0.85em",
                  }}
                >
                  Incorrect OTP entered
                </div>
              )}

              <SubmitFinal
                submittingState={submittingState}
                accountExist={accountExist}
                onClick={(event) => {
                  if (OTP.toString().length == 6) {
                    callSubmit(event);
                  }
                }}
              >
                {submittingState == "Initial" || submittingState == "OTP short"
                  ? "Submit"
                  : submittingState == "Submitting"
                  ? "Creating Account.."
                  : ""}
              </SubmitFinal>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default SignupForm;
