import React, { useState } from "react";
import PrivateRoute from "../components/PrivateRoute";
import EmptyProdError from "../components/EmptyProdError";
import DCHome from "../deviceconnect/DCHome";
import DeviceHealth from "../deviceconnect/Health";
import Home from "../screens/Home";
import Settings from "../screens/Settings";
import Usage from "../screens/Usage";
import BulkRequests from "../deviceconnect/BulkRequests";
import NewRequest from "../deviceconnect/NewRequest";
import RequestDetails from "../deviceconnect/RequestDetails";
import Navbar from "./Navbar";
import NavSideBar from "./NavSideBar";
import { Backdrop } from "./common";
import NotificationBar from "./notifications/NotificationBar";

const PrivateRouter = (props) => {
  const [navSideBarOpen, setNavSideBarOpen] = useState(false);
  const [notificationBarOpen, setNotificationBarOpen] = useState(false);

  const navDrawerToggleClickHandler = () => {
    setNavSideBarOpen(!navSideBarOpen);
  };
  const backdropClickHandler = () => {
    setNavSideBarOpen(false);
    setNotificationBarOpen(false);
  };

  let backdrop = (navSideBarOpen || notificationBarOpen) && (
    <Backdrop onClick={backdropClickHandler} />
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Navbar
        navDrawerToggleClickHandler={navDrawerToggleClickHandler}
        openNotification={() => setNotificationBarOpen(true)}
        notificationClosed={!notificationBarOpen}
        location={props.location}
      />
      <NavSideBar
        anchorClickHandler={backdropClickHandler}
        show={navSideBarOpen}
      />
      <NotificationBar
        anchorClickHandler={backdropClickHandler}
        show={notificationBarOpen}
      />
      {backdrop}
      <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
        <PrivateRoute path="/home" exact component={Home} />
        <PrivateRoute path="/usage/:productName" exact component={Usage} />
        <PrivateRoute
          path="/empty/:productName"
          exact
          component={EmptyProdError}
        />
        <PrivateRoute path="/settings" component={Settings} />
        {/* <PrivateRoute path="/analytics" component={Analytics} /> */}

        <PrivateRoute path="/deviceconnect" exact component={DCHome} />
        <PrivateRoute
          path="/deviceconnect/health"
          exact
          component={DeviceHealth}
        />
        <PrivateRoute
          path="/deviceconnect/list"
          exact
          component={BulkRequests}
        />
        <PrivateRoute
          path="/deviceconnect/newrequest"
          exact
          component={NewRequest}
        />
        <PrivateRoute
          path="/deviceconnect/requestDetails/:userId"
          exact
          component={RequestDetails}
        />
      </div>
    </div>
  );
};

export default PrivateRouter;
