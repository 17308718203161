import React from "react";
import styled from "styled-components";
import { ButtonCore } from "./common";

function GenericErrorState(props) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
				height: "100%",
				justifyContent: "space-around",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "50%",
				}}
			>
				{" "}
				<img
					src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/error.svg"
					alt="error"
				/>
				<h3 style={{ textAlign: "center" }}>{props.heading}</h3>
				<p style={{ textAlign: "center" }}>{props.message}</p>
			</div>
		</div>
	);
}

export default GenericErrorState;
