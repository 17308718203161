import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import BreadCrumbsNavbar from "../components/BreadCrumbsNavbar";
import {
  VizContainer,
  Card,
  Bold14,
  Main,
  Container,
} from "../components/common";
import "../../node_modules/react-vis/dist/style.css";
import "spectre.css/dist/spectre.min.css";
import Navbar from "../components/Navbar";
import Graph from "../components/visualizations";
import Shimmer from "react-shimmer-effect";
import AppContext from "../context/AppContext";

const Separator = styled.div`
  width: 1px;
  height: 60px;
  background-color: #dadada;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-direction: row;
  @media (max-width: 768px) {
    justify-content: space-around;
  }
`;

const Column = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

function Health(props) {
  const [graphData, setGraphData] = useState([]);
  const context = useContext(AppContext);

  useEffect(() => {
    fetchHealthData();
  }, []);

  const fetchHealthData = async () => {
    let fT = context.token;
    const ftData = JSON.parse(fT);
    var myHeaders = new Headers();
    myHeaders.append("token", ftData.Token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let response;
    try {
      response = await fetch(
        `https://dashboardapi.finbox.in/v1/health/device_connect`,
        requestOptions
      );
      if (response.status == 400) {
        window.location.href = "/deviceconnect";
        return;
      }
    } catch (err) {
      context.removeToken();
      return;
    }
    const resp = await response.text();
    const json = JSON.parse(resp);
    setGraphData(json.Data);
  };

  const _renderKeys = () => {
    return (
      graphData && (
        <VizContainer>
          {graphData.map((vi, viz_ind) => (
            <div
              key={viz_ind}
              style={{
                width: "100%",
              }}
            >
              <Graph
                hideXAxis={true}
                format="MMM DD, YYYY (HH:MM A)"
                duration="last 24 hours"
                hideToggleView={true}
                {...vi}
              />
            </div>
          ))}
        </VizContainer>
      )
    );
  };

  return (
    <Main>
      <BreadCrumbsNavbar
        // color="blue"
        loc={[
          { name: "DeviceConnect", link: "/deviceconnect" },
          { name: "API Health", link: props.location.pathname },
        ]}
        style={{ backgroundColor: "#f9f9f9" }}
      />
      <Container style={{ minHeight: "fit-content" }}>
        <div>
          <Card style={{ padding: "15px 15px", height: "fit-content" }}>
            <Row>
              <Column>
                <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/ic_check_green.svg" />
                <Bold14 style={{ marginTop: 10 }}>
                  Service is operating normally
                </Bold14>
              </Column>
              <Separator />
              <Column>
                <Bold14 style={{ fontSize: 34 }}>V4</Bold14>
                <Bold14>Current Version</Bold14>
              </Column>
            </Row>
          </Card>
        </div>
        {graphData.length == 0 ? (
          <Shimmer>
            <div
              style={{
                width: "100%",
                height: "370px",
                alignSelf: "center",
                marginTop: 30,
                borderRadius: 8,
              }}
            />
          </Shimmer>
        ) : (
          <div style={{ marginTop: 30 }}>{_renderKeys()}</div>
        )}
      </Container>
    </Main>
  );
}

export default Health;
