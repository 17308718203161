import React, { useState } from "react";
import {
  MarkSeries,
  AreaSeries,
  HorizontalGridLines,
  GradientDefs,
  XAxis,
  XYPlot,
  YAxis,
  Crosshair,
  makeWidthFlexible,
  DiscreteColorLegend,
} from "react-vis";
import moment from "moment";
const trimSet = (arr) => {
  if (arr.length < 8) return arr;
  const doneM = [];
  return arr.filter(function (v) {
    if (doneM.includes(moment(v).format("MM-YYYY"))) return false;
    else {
      doneM.push(moment(v).format("MM-YYYY"));
      return true;
    }
  });
};
// support 4 colours as of now
const categoryColors = ["#00b6ff", "#cd60dd", "#ffae62", "#6ed0e6"];

export default class Multiple extends React.Component {
  state = {
    crosshairValues: [],
  };
  static getDerivedStateFromProps(props) {
    const DATA = props.data.map((series) => {
      return series.map((elem) => {
        return { y: elem.Y, x: new Date(elem.X) };
      });
    });
    return {
      DATA,
    };
  }
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };
  _onNearestX = (value, { index }) => {
    this.setState({ crosshairValues: this.state.DATA.map((d) => d[index]) });
  };
  render() {
    const FlexibleXYPlot = makeWidthFlexible(XYPlot);
    return (
      <>
        <DiscreteColorLegend
          items={this.props.items.map((value, index) => {
            return { title: value, color: categoryColors[index] };
          })}
        />
        <FlexibleXYPlot
          height={270}
          margin={{ left: 60, right: 40, bottom: 80, top: 40 }}
          xType="time"
          onMouseLeave={this._onMouseLeave}
        >
          <GradientDefs>
            {categoryColors.map((element, index) => {
              return (
                <linearGradient
                  id={"CoolGradient" + index}
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="1.5"
                >
                  <stop offset="0%" stopColor={element} stopOpacity={0.24} />
                  {/* <stop offset="100%" stopColor={element} stopOpacity={0.01} /> */}
                </linearGradient>
              );
            })}
          </GradientDefs>

          <HorizontalGridLines />
          <XAxis
            tickFormat={(v) => moment(v).format(this.props.format)}
            tickValues={trimSet(this.state.DATA[0].map((d) => d.x))}
            tickLabelAngle={-90}
          />
          <YAxis />
          {this.state.DATA.map((series, i) => {
            return (
              <MarkSeries
                key={i}
                data={series}
                onNearestX={i === 0 ? this._onNearestX : () => {}}
                color={"url(#CoolGradient" + i + ")"}
                size="2"
              />
            );
          })}
          {this.state.DATA.map((series, i) => {
            return (
              <AreaSeries
                key={i}
                data={series}
                onNearestX={i === 0 ? this._onNearestX : () => {}}
                color={"url(#CoolGradient" + i + ")"}
              />
            );
          })}
          <Crosshair values={this.state.crosshairValues}>
            <div className="label-wrapper">
              {this.props.items.map((item, index) => (
                <div key={index}>
                  {index == 0 ? (
                    <div className="label-header">
                      {this.state.crosshairValues[index] &&
                        moment(this.state.crosshairValues[index].x).format(
                          this.props.format
                        )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="label-title">{item}</div>
                  <div className="label-value">
                    {this.state.crosshairValues[index] &&
                      parseInt(this.state.crosshairValues[index].y)}
                  </div>
                </div>
              ))}
            </div>
          </Crosshair>
        </FlexibleXYPlot>
      </>
    );
  }
}
