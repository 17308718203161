import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import UltimatePaginationBootstrap3 from "../components/PaginationComponent";
import "./deviceconnect.scss";
import Row from "./Row";

const Dable = styled.table`
  font-size: 14px;
  text-align: center;
  min-width: 950px;
  a {
    cursor: pointer;
    color: #24ca7a;
    font-weight: 400;
    :hover {
      color: #24ca7a;
      text-decoration: underline;
    }
    :link {
      color: #24ca7a;
    }
    :visited {
      color: #24ca7a;
      /* text-decoration: underline; */
    }
    :active {
      color: #24ca7a;
      /* text-decoration: underline; */
    }
  }
  td {
    font-weight: 400;
  }
  tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;
const DeleteButton = styled.table`
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  &:hover {
    color: #ff5722;
  }
`;
function RequestTable(props) {
  const [page, setpage] = useState(0);
  const [perpage, setPerpage] = useState(10);

  const history = useHistory();

  const getdetails = (status, id) => {
    var request_id = id;
    if (status != "created" && status != "validation_failed") {
      history.push("requestdetails/" + request_id);
    }
  };

  const rows = props.data;
  if (props.loading)
    return (
      <div
        style={{
          display: "flex",
          padding: 30,
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  if (rows.length === 0) {
    return (
      <div className="empty" style={{ marginTop: 30 }}>
        <p className="empty-title h5">No records found</p>
      </div>
    );
  }
  return (
    <>
      <Dable className="table">
        <thead>
          <tr style={{ backgroundColor: "white" }}>
            <th style={{ paddingLeft: "25px" }}>Request ID</th>
            <th>File Name</th>
            <th>Customers</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <Row row={row} setDeleteID={(id) => props.setDeleteID(id)} />
          ))}
        </tbody>
      </Dable>
      {props.totalPages != 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <UltimatePaginationBootstrap3
            currentPage={props.currentPage}
            totalPages={props.totalPages}
            hideFirstAndLastPageLinks={true}
            onChange={(page) => {
              props.fetchData(page);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default RequestTable;
