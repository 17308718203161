import React, { useContext, useEffect, useState } from "react";
import "spectre.css/dist/spectre.min.css";
import styled from "styled-components";
import {
  Bold14,
  Bold16,
  ScrollingCard,
  Container,
  LabelWithInput,
  LabelModalInput,
  TextAreaContainer,
  TriggerButton,
  Main,
  ButtonCore,
} from "../components/common";
import Navbar from "../components/Navbar";
import RowAvatarWithText from "../components/RowAvatarWithText";
import { Tab, Tabs } from "../components/SettingsNav";
import AppContext from "../context/AppContext";
import Developers from "./Developers";
// import DeleteModal from "../components/DeleteModal";

import "./Settings.scss";

// const emailRegex = new RegExp(
//   "^([a-zA-Z0-9_\\-.]+)@([a-zA-Z0-9_\\-.]+)\\.([a-zA-Z]{2,5})$"
// );

const commonMailRegex = new RegExp(
  "^([a-zA-Z0-9_.]{0,}([.]?[a-zA-Z0-9_]{1,})[@](gmail.com|hotmail.com|yahoo.com|yahoo.in))$"
);

// const Submit = styled.div`
//   display: flex;
//   justify-content: center;
//   margin: 2.4em 0 0.5em 0;
//   width: 25%;
//   cursor: ${(props) => (props.enabled ? "pointer" : "default")};
//   background-color: ${(props) => (props.enabled ? "#24ca7a" : "#cacaca")};
//   border-radius: 2px;
//   color: white;
//   font-weight: 600;
//   font-size: 0.9;
//   padding: 0.58em 0;
//   border: none;
//   outline: none;
//   box-shadow: none;
// `;

const ColContainer = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  margin-top: 15px;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const BoxPdf = styled.div`
  width: 36px;
  height: 22px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #24ca7a;
  box-sizing: border-box;
  border-radius: 10px;
`;

const HeaderWithButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #ececec;
  padding: 0 25px 15px 25px;
  /* padding-bottom: 15px; */
  margin: 0 -25px 15px -25px;
`;

const jsonDevs = [
  {
    name: "Profile",
  },
  {
    name: "Members",
  },
  {
    name: "Configuration",
  }
];

const renderKeys = (tab, usr, setUsr) => {
  switch (tab.name) {
    case "Profile":
      return ProfileSettings(usr, setUsr);
    // case "Billing Details":
    //   return BillingDetails(usr);
    case "Members":
      return Members(usr);
    case "Configuration":
      return <Developers />;
  }
};

const ProfileSettings = (profile, setUsr) => {
  const context = useContext(AppContext);
  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setUsr({
      ...profile,
      [name]: value,
    });
  };
  const updateProfile = async () => {
    const resp = context.token;
    const respJSON = JSON.parse(resp);
    const body = {
      name: profile.Name,
      companyName: profile.CompanyName,
      officeAddress: profile.OfficeAddress,
    };
    var myHeaders = new Headers();
    myHeaders.append("token", respJSON.Token);

    var raw = JSON.stringify(body);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://dashboardapi.finbox.in/v1/user/profile",
      requestOptions
    );
    const result = await response.json();
    const newObj = {
      ...respJSON,
      UserData: { ...respJSON.UserData, ...result.Data },
    };
    context.updateToken(JSON.stringify(newObj));
    window.location.reload();
  };
  return (
    <>
      <ScrollingCard>
        <Bold16>My Profile</Bold16>
        <br />
        <ColContainer>
          <LabelWithInput
            label="Name"
            name="Name"
            value={profile.Name}
            onChange={(e) => handleInputChange(e)}
          />
          <LabelWithInput
            label="Email"
            name="Email"
            value={profile.Email}
            readOnly
            onChange={(e) => handleInputChange(e)}
          />
        </ColContainer>
        <br />
        <Bold16>Company Details</Bold16>
        <ColContainer>
          <LabelWithInput
            label="Company Name"
            name="CompanyName"
            value={profile.CompanyName}
            onChange={(e) => handleInputChange(e)}
          />
          <div style={{ marginTop: 15 }}>
            <Bold14>Office Address</Bold14>
          </div>
          <TextAreaContainer
            rows={5}
            value={profile.OfficeAddress}
            name="OfficeAddress"
            onChange={(e) => handleInputChange(e)}
          />
        </ColContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #ECECEC",
            margin: "15px -25px 0 -25px",
            padding: "0 25px",
          }}
        >
          <ButtonCore onClick={updateProfile} style={{ marginTop: "13px" }}>
            Update
          </ButtonCore>
        </div>
      </ScrollingCard>
    </>
  );
};

function Members() {
  const context = useContext(AppContext);
  const resp = context.token;
  const respJSON = JSON.parse(resp);
  const [members, setMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [name, setName] = useState("");
  const [deleteId, setDeleteID] = useState("");
  const [deleteMemberName, setDeleteMemberName] = useState("");
  const myHeaders = new Headers();
  const [emailErr, setEmailErr] = React.useState(false);
  const [ownEmail, setownEmail] = React.useState(
    JSON.parse(localStorage.getItem("finboxToken")).UserData.Email
  );
  const [emailErrText, setEmailErrText] = React.useState(
    "*Enter a valid Email"
  );

  const ownMailRegex = new RegExp(
    `^([a-zA-Z0-9_.]{0,}([.]?[a-zA-Z0-9_]{1,})[@](${ownEmail.split("@")[1]}))$`
  );

  myHeaders.append("token", respJSON.Token);

  const inviteMember = async () => {
    let response, result;
    var raw = JSON.stringify({ name: name, email: email });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      response = await fetch(
        "https://dashboardapi.finbox.in/v1/user/members",
        requestOptions
      );
      result = await response.json();
    } catch (err) {
      alert(err);
      console.log(err);
    }
    if (!result.Status) {
      setEmailErr(true);
      if (result.Error == "Email already a member in a different company") {
        setEmailErrText(
          "This email is already a member in a different company"
        );
      } else if (result.Error == "already a member") {
        setEmailErrText("This email is already a member");
      }
    } else {
      fetchMembers();
      setShowModal(false);
      setSuccessModal(true);
    }
  };

  const fetchMembers = async () => {
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let response;
    try {
      response = await fetch(
        "https://dashboardapi.finbox.in/v1/user/members",
        requestOptions
      );
    } catch (err) {
      context.removeToken();
      window.location.href = "/";
      return;
    }
    const result = await response.json();
    setMembers(result.Data);
    // console.log(ownEmail.split("@")[1]);
  };

  // const deleteMember = async (email) => {
  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };
  //   let response;
  //   try {
  //     response = await fetch(
  //       `https://dashboardapi.finbox.in/v1/user/members/${email}`,
  //       requestOptions
  //     );
  //   } catch (err) {
  //     return;
  //   }
  //   const result = await response.json();
  //   fetchMembers();
  //   setDeleteID("");
  //   setDeleteMemberName("");
  //   setShowDeleteModal(false);
  // };

  function checkemail(email) {
    if (email == ownEmail) {
      setEmailErr(true);
      setEmailErrText("You can't add yourself twice");
    } else {
      setEmailErr(
          commonMailRegex.test(email.toLowerCase()) ||
          !ownMailRegex.test(email.toLowerCase())
      );
      setEmailErrText(
        `*Enter a valid Email. (you can only invite people with @${ownEmail})`
      );
    }
  }
  useEffect(() => {
    fetchMembers();
  }, []);
  return (
    <>
      {/* <div className="columns"> */}
      {/* <div className="column col-12"> */}
      <ScrollingCard>
        <HeaderWithButton>
          <Bold16>Team Members ({members.length})</Bold16>
          <ButtonCore onClick={() => setShowModal(true)}>
            Add Members
          </ButtonCore>
        </HeaderWithButton>
        <ColContainer>
          {members.map((member, mIndex) => {
            return (
              <RowAvatarWithText
                title={member.Name}
                content={member.Email}
                // deleteMember={() => {
                //   setShowDeleteModal(true);
                //   setDeleteID(member.Email);
                //   setDeleteMemberName(member.Name);
                // }}
              />
            );
          })}
        </ColContainer>
      </ScrollingCard>
      <div className={`modal ${successModal && "active"}`} id="modal-id">
        <a
          href="#close"
          className="modal-overlay"
          aria-label="Close"
          onClick={() => setSuccessModal(false)}
        ></a>
        <div
          className="modal-container"
          style={{
            borderRadius: 4,
            padding: "15px ",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <a
              onClick={() => setSuccessModal(false)}
              className="btn btn-clear float-right"
              aria-label="Close"
            ></a>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/completed.svg"
              width="110px"
            />
            <Bold16 style={{ fontSize: "22px", marginTop: "16px" }}>
              Success
            </Bold16>
            <p className="para">
              {email} has been successfully added to your team!
            </p>
          </div>
        </div>
      </div>

      {/* <div className={`modal ${showDeleteModal && "active"}`} id="modal-id">
        <a href="#close" className="modal-overlay" aria-label="Close"></a>
        <div
          className="modal-container"
          style={{
            borderRadius: 4,
            padding: "15px 25px 0 25px",
          }}
        >
          <div className="modal-title title">Remove Access</div>
          <div className="line"></div>
          <div>
            <Bold14 style={{ fontSize: "16px" }}>
              Are you sure you want to remove {deleteMemberName}?
            </Bold14>
            <p className="para">
              Are you sure you want to continue with this process? After
              removal, {deleteId} will lose all access from this dashboard.
            </p>
            <div className="footer">
              <ButtonCore
                color="white"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </ButtonCore>{" "}
              <ButtonCore
                color="red"
                onClick={() => {
                  deleteMember(deleteId);
                }}
              >
                Remove
              </ButtonCore>
            </div>
          </div>
        </div>
      </div>
       */}
      <div className={`modal ${showModal && "active"}`} id="modal-id">
        <a href="#close" className="modal-overlay" aria-label="Close"></a>
        <div
          className="modal-container"
          style={{
            borderRadius: 4,
            padding: "15px 25px 0 25px",
          }}
        >
          <div className="modal-title title">Invite New Members</div>
          <div className="line"></div>

          <div className="content modalinputs">
            <LabelModalInput
              label="Email"
              value={email}
              checkemail={(email) => checkemail(email)}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErrText("");
              }}
            />
            <LabelModalInput
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {emailErr && (
            <span
              style={{ color: "red", fontSize: "0.7em", fontWeight: "400" }}
            >
              {emailErrText}
            </span>
          )}
          <div className="footer">
            <ButtonCore color="white" onClick={() => setShowModal(false)}>
              Cancel
            </ButtonCore>
            <ButtonCore
              onClick={() => !emailErr && inviteMember()}
              style={{ margin: 0 }}
            >
              Invite
            </ButtonCore>
          </div>
        </div>
      </div>
    </>
  );
}

function Settings(props) {
  const context = useContext(AppContext);
  const resp = context.token;
  const respJSON = JSON.parse(resp);
  const user = respJSON.UserData;
  const [profile, setProfile] = useState({ ...user });
  return (
    <>
      <Main>
        <div
          style={{
            padding: "0 8%",
            display: "flex",
            background: "#f9f9f9",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          <Tabs>
            {jsonDevs.map((tab) => {
              if (
                tab?.name === "Configuration" &&
                profile?.Products?.length === 1 &&
                profile?.Products[0] != "device_connect"
              )
                return <></>;
              return (
                <Tab label={tab.name}>
                  {renderKeys(tab, profile, setProfile)}
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </Main>
    </>
  );
}

export default Settings;
