import React, { useContext } from "react";
import "spectre.css/dist/spectre.min.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: #24ca7a;
  font-size: 12;
  margin-top: 15px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: #24ca7a;
  }
`;

function PassUpdated() {
  const history = useHistory();

  function handleChange(evt) {
    const value = evt.target.value;
    setState({ ...state, [evt.target.name]: value });
  }

  const [state, setState] = React.useState({
    userName: "",
    pass: "",
    token: "",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#F7FAFD",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flexStart",
          paddingTop: "80px",
          paddingLeft: "80px",
          width: "100%",
        }}
      >
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/finbox.svg"
          className="App-logo"
          alt="logo"
        />
      </div>
      <div style={{ width: "25%", marginTop: "60px", textAlign: "center" }}>
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/authentication.svg"
          alt="picture"
        />
        <h2
          style={{
            fontWeight: "600",
            fontSize: "24",
            color: "#3e3e3e",
            marginTop: "40px",
          }}
        >
          Password Updated
        </h2>
        <p
          style={{
            fontWeight: "400",
            fontSize: "14",
            color: "#777777",
            marginTop: "20px",
          }}
        >
          Your password has been changed successfully.
        </p>
      </div>
      <StyledLink onClick={() => history.push("/")}>
        Click here to Login
      </StyledLink>
    </div>
  );
}

export default PassUpdated;
