import React, { useContext, useEffect, useState } from "react";
import { FaCheckDouble } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import AppContext from "../../context/AppContext";
import { BoldHeader, NotificationButton } from "../common";
import "./NotificationBar.css";

import NotificationHandler from "./NotificationHandler";
import NotificationEmpty from "./NotificationEmpty";
const Container = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
`;

const Separator = styled.div`
  border: 0.5px solid #ededed;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow-y: scroll;
`;

const Badge = styled.div`
  padding: 2px 4px;
  display: flex;
  border-radius: 4px;
  color: #fff;
  height: max-content;
  background: #ff6b74;
  margin-left: 10px;
  font-size: 12px;
`;

const NotificationBar = props => {
  const context = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (context.token && props.show) {
      fetchNotificationList();
    }
    return () => {
      if (props.show) {
        context.setUnreadNotif(0);
      }
    };
  }, [props.show]);

  const fetchNotificationList = async () => {
    var myHeaders = new Headers();
    const resp = context.token;
    myHeaders.append("token", JSON.parse(resp).Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    const response = await fetch(
      "https://dashboardapi.finbox.in/v1/notifications/list?page=1",
      requestOptions
    );
    const result = await response.text();
    const json = JSON.parse(result);
    if (json.Status) {
      setData(json.Data);
    } else {
      console.log("Error -> ", json.Error);
    }
  };

  const dismissNotification = async notifId => {
    var myHeaders = new Headers();
    const resp = context.token;
    myHeaders.append("token", JSON.parse(resp).Token);
    console.log("NotiofId ", notifId);
    var raw = JSON.stringify({
      notificationid: notifId
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };
    if (notifId) {
      requestOptions.body = raw;
      context.setUnreadNotif(context.unreadNotif - 1)
    } else {
      context.setUnreadNotif(0)
    }
    console.log("NotiofId ", requestOptions);
    const response = await fetch(
      "https://dashboardapi.finbox.in/v1/notifications/dismiss",
      requestOptions
    );
    const result = await response.text();
    const json = JSON.parse(result);
    if (json.Status) {
      if (notifId) {
        setData(data.filter(d => d.notification_id !== notifId));
      } else {
        setData([]);
      }
    } else {
      console.log("Error -> ", json.Error);
    }
  };

  return (
    <div
      className={
        props.show ? "notification-side-bar open" : "notification-side-bar"
      }
    >
      <div style={{ display: "flex" }}>
        <FiX
          className="notification-side-bar-close"
          onClick={props.anchorClickHandler}
        />
        <Container>
          <Row style={{ justifyContent: "space-between" }}>
            <Row style={{ marginLeft: 10 }}>
              <BoldHeader style={{ fontSize: 16, fontWeight: "bold" }}>
                Notifications
              </BoldHeader>
              {context.unreadNotif > 0 && <Badge>{context.unreadNotif}</Badge>}
            </Row>
            {data.length > 0 && (
              <div
                style={{
                  fontSize: 14,
                  color: "#24CA7A",
                  marginRight: 10,
                  cursor: "pointer"
                }}
                onClick={() => dismissNotification()}
              >
                <img
                  style={{ paddingRight: 4 }}
                  src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/ic_checked_double.svg"
                  height={10}
                />
                Dismiss All
              </div>
            )}
          </Row>
          <Separator
            style={{
              marginTop: 10
            }}
          />
          <Column>
            {data.length > 0 ? (
              <NotificationHandler
                data={data}
                dismissNotification={dismissNotification}
                closeNotification={props.anchorClickHandler}
              />
            ) : (
              <NotificationEmpty />
            )}
          </Column>
        </Container>
      </div>
    </div>
  );
};

export default NotificationBar;
