import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
export const Bold18 = styled.span`
  font-weight: 400;
  font-size: 18px;
`;

const mobile = 786;
const tablet = 1024;

export const BoldHeader = styled.span`
  font-weight: 600;
  font-size: 22px;
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: fit-content;
  height: 100%;
  /* min-width: 1330px; */
  background: white;
  @media (max-width: ${mobile}px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
export const SDK = styled.div`
  width: 370px;
  height: 680px;
  background-color:white;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  @media (max-width: 370px) {
    width: 90%;
    border-color:white;
    text-align: center;
    justify-contect: center;
  }
`;
export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: #f9f9f9;
  @media (max-width: ${mobile}px) {
    /* overflow-y: scroll; */
    overflow-x: hidden;
  }
`;
export const SandboxContainer = styled.div`
  padding: 0 9%;
  margin: 15px 0 15px 0;
  height: 100%;
  overflow-y: auto;
  .codeblock {
    width: 80%;
    pre {
      padding: 0;
    }
  }

  @media (max-width: ${mobile}px) {
    padding: 0 4%;
    .codeblock {
      width: 100%;
      pre {
        padding: 0;
      }
    }
  }
`;
export const BCDemoMain = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 752px;
  background: white;
  /* border: 1px solid #ececec; */
  @media (max-width: ${mobile}px) {
    display: none;
  }
`;
export const BCDemoContainer = styled.div`
  display: ${(props) => (props.errorState ? "none" : "flex")};
  width: 100%;
  overflow-x: hidden;
  padding: 0 9%;
  @media (max-width: ${mobile}px) {
    padding: 0 4%;
  }
`;

export const NormalDesc = styled.span`
  font-weight: normal;
  font-size: 14px;
  color: #777777;
  @media (max-width: ${tablet}px) {
    text-align: center;
  }
`;

export const Bold16 = styled.span`
  font-weight: 600;
  font-size: 18px;
`;
export const Bold14 = styled.span`
  font-weight: 600;
  color: #3e3e3e;
  font-size: 14px;
`;
export const Bold12 = styled.span`
  font-weight: 400;
  color: #3e3e3e;
  font-size: 12px;
`;
export const Card = styled.div`
  background: white;
  border-radius: 4px;
  padding: 15px 25px;
  margin-bottom: 20px;
  /* overflow-y: scroll; */
  height: 100%;
  h4 {
    margin-bottom: 20px;
  }
`;

export const ScrollingCard = styled.div`
  background: white;
  border-radius: 4px;
  padding: 15px 25px;
  margin-bottom: 20px;
  overflow-y: scroll;
  height: fit-content;
  border: 1px solid #ececec;
  @media (max-width: ${mobile}px) {
    overflow-x: hidden;
  }

  h4 {
    margin-bottom: 20px;
  }
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #bdbdbd;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const DeveloperContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const SwitchDeveloper = styled.div`
  padding: 1px 20px 3px;
  font-weight: 400;
  color: white;
  &:first-child {
    border: 1px solid #11a1f1;
    border-right: none;
    border-radius: 4px 0 0 4px;
    background: ${(props) => (props.stage == "dev" ? " #11A1F1" : "none")};
    color: ${(props) => (props.stage == "dev" ? "white" : " #11A1F1")};
  }
  &:last-child {
    border: 1px solid #11a1f1;
    border-left: none;
    border-radius: 0 4px 4px 0;
    background: ${(props) => (props.stage == "prod" ? " #11A1F1" : "none")};
    color: ${(props) => (props.stage == "prod" ? "white" : " #11A1F1")};
  }
`;
export const SwitchMaster = styled.div`
  padding: 1px 20px 3px;
  font-weight: 400;
  color: white;
  background: white;
  &:first-child {
    border: 1px solid #24ca7a;
    border-right: none;
    border-radius: 4px 0 0 4px;
    background: ${(props) => (props.stage == "dev" ? " #24ca7a" : "none")};
    color: ${(props) => (props.stage == "dev" ? "white" : " #24ca7a")};
  }
  &:last-child {
    border: 1px solid #24ca7a;
    border-left: none;
    border-radius: 0 4px 4px 0;
    background: ${(props) => (props.stage == "prod" ? " #24ca7a" : "none")};
    color: ${(props) => (props.stage == "prod" ? "white" : " #24ca7a")};
  }
`;

export const CenterCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 20px;
  flex-direction: column;
`;

export const ButtonCore = styled.button`
  background: ${(props) =>
    props.color == "white"
      ? "#e3e3e3"
      : props.color == "red"
      ? "#FF5757"
      : props.color == "blue"
      ? "#3BA4F9"
      : "#24ca7a"};
  display: inline-block;
  border-radius: 2px;
  font-weight: 500;
  font-size: ${(props) => (props.isSmall ? "12px" : "14px")};
  color: ${(props) => (props.color == "white" ? "#777777" : "white")};
  padding: 7px 20px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: ${(props) => (props.color == "white" ? "20px" : 0)};

  :hover,
  :visited,
  :active,
  :link {
    color: ${(props) => (props.color == "white" ? "#777777" : "white")};
    text-decoration: none;
    background-color: ${(props) =>
      props.color == "white"
        ? "#c5c5c5"
        : props.color == "red"
        ? "#D94545"
        : props.color == "blue"
        ? "#4A98D7"
        : "#05a16d"};
    /* border: ${(props) =>
      props.iswhite ? "1.5px solid #24ca7a" : "1.5px solid #05a16d"}; */
  }
`;

export const OutlineButtonText = styled.span`
  display: flex;
  align-items: center;
  color: #24ca7a;
  font-weight: 500;
  font-size: 16px;
  width: fit-content;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const LinkStyle = styled.button`
  display: flex;
  background: none;
  font-weight: 400;
  align-items: center;
  margin: 15px 0 3px 0;
  color: #24ca7a;
  cursor: pointer;
  border: none;
  :hover {
    color: #24ca7a;
    text-decoration: underline;
  }
  :link {
    color: #24ca7a;
    /* text-decoration: underline; */
  }
  :visited {
    color: #24ca7a;
    /* text-decoration: underline; */
  }
  :active {
    color: #24ca7a;
    /* text-decoration: underline; */
  }
`;
export const BlueLink = styled.button`
  background: none;
  font-weight: 400;
  align-items: center;

  color: #11a1f1;
  cursor: pointer;
  border: none;

  cursor: pointer;
  color: #11a1f1;
  font-weight: 400;
  :hover {
    color: #11a1f1;
    text-decoration: underline;
  }
  :link {
    color: #11a1f1;
  }
  :visited {
    color: #11a1f1;
    /* text-decoration: underline; */
  }
  :active {
    color: #11a1f1;
    /* text-decoration: underline; */
  }
`;

export const NotificationButton = styled.button`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  width: max-content;
  padding: 4px 10px;
  font-size: 12px;
  color: #11a1f1;
  cursor: pointer;
  &:hover {
    background-color: #11a1f1;
    border: 1px solid #11a1f1;
    color: #fff;
  }
`;

export const BlackButton = styled.button`
  border: 1.5px solid #1f1e20;
  color: #1f1e20;
  padding: 7px 20px;
  width: fit-content;
  text-align: center;
  text-decoration: none;
  background: none;
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;

  cursor: pointer;
  &:hover {
    background-color: #a78b8b;
  }
`;
export const OutlineButtonCore = styled.button`
  background-color: white;
  border: 0.07em solid #24ca7a;
  color: white;
  padding: ${(props) => (props.width ? "10px 0" : "8px 20px")};
  width: ${(props) =>
    props.width == "full"
      ? "100%"
      : props.width == "half"
      ? "300px"
      : "fit-content"};
  text-align: center;
  text-decoration: none;
  display: flex;
  /* justify-content: ${(props) => (props.width ? "center" : "")}; */
  justify-content: center;
  border-radius: 2px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  &:nth-child(2) {
    margin-left: 15px;
  }
  &:hover {
    background-color: #24ca7a;
    border: 1px solid #24ca7a;
    color: #24ca7a;
  }
  &:hover ${OutlineButtonText} {
    color: white;
  }
  @media (max-width: ${mobile}px) {
    &:nth-child(2) {
      margin-left: ${(props) => (props.noMargin == "true" ? "2px" : "15px")};
    }
  }
`;
export const Banner1 = styled.div`
  width: 370px;
  height: 670px;
  background-image: linear-gradient(#ffffff, #f4f7fa, #dae5ef);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 30px 50px;
  @media (max-width: 370px) {
    width: 90%;
    padding: 20px;
    text-align: center;
    justify-contect: center;
    img {
      height: 120px;
    }
  }
`;
export const Saved = styled.div`
  width: 300px;
  height: 300px;
  background-image: linear-gradient(#ffffff, #f4f7fa, #dae5ef);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 50px 150px;
  @media (max-width: 370px) {
    width: 90%;
    padding: 20px;
    text-align: center;
    justify-contect: center;
    img {
      height: 120px;
    }
  }
`;
export const ModalButton = styled.div`
  background: #24ca7a;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  color: white;
  padding: 10px 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 25px 0 12px 0;
  @media (max-width: 1024px) {
    font-size: 16px;
    text-align: center;
  }
`;
export const ProceedButton = styled.button`

  cursor : pointer;
  background: #24ca7a;
  font-weight: 700;
  font-size: 14px;
  color: white;
  padding: 7px 20px;
  display: flex;
  border-radius: 2px;
  width: fit-content;
  border-color:#24ca7a;
  justify-content: center;
  margin: 25px 0 12px 0;
  border: 0px;
  @media (max-width: 150px) {
    font-size: 16px;
    text-align: center;
  }
  &:hover {
    background-color: ${(props) =>
      !props.err ? "#139e66" : "#cecece"};
  }
`;
export const SmallText = styled.div`
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
`;
export const FillerText = styled.div`
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  white-space: normal;
  word-wrap: break-word;
  @media (max-width: 100px) {
    text-align: center;
  word-wrap: break-word;
  white-space: nowrap;
  }
`;
export const Buttonselect = styled.button`
height: 25px;

padding: 0px 20px;
  font-weight: 400;
  border: 1px solid grey;
  border-color:lightgrey;
  border-radius: 4px 4px 4px 4px;
  background : #e0e0dc;
  color: white;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  justify-content: center;
  white-space: nowrap;
  box-sizing: "border-box";
 
`;
export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  left: 0;
`;

export const Button = (props) => {
  return (
    <div
      style={{ marginTop: props.mt || 14, cursor: "pointer" }}
      onClick={() => window.open(props.href)}
    >
      <ButtonCore>
        <span style={{ display: "flex", alignItems: "center" }}>
          {props.children}
        </span>
      </ButtonCore>
    </div>
  );
};
export const TriggerButton = (props) => {
  return (
    <div
      style={{
        marginTop: props.mt || 14,
        marginRight: props.mr || 0,
        marginLeft: props.ml || 0,
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <ButtonCore isGray={props.isGray} isSmall={props.small}>
        <span style={{ display: "flex", alignItems: "center" }}>
          {props.children}
        </span>
      </ButtonCore>
    </div>
  );
};

export const OutlineButton = (props) => {
  return (
    <OutlineButtonCore
      onClick={props.onClick}
      width={props.width}
      noMargin={props.noMargin}
    >
      <OutlineButtonText>{props.children}</OutlineButtonText>
    </OutlineButtonCore>
  );
};

export const Code = styled.span`
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 16px;
  color: #3e3e3e;
`;

export const VizContainer = styled.div`
  /* flex-direction: column; */
  display: flex;
  justify-content: space-between;
  /* background: ${(props) => props.theme.main}; */
  height: fit-content;
  /* overflow: hidden; */
  @media (max-width: ${tablet}px) {
    flex-direction: column;
    overflow-y: scroll;
  }
`;
export const VizCard = styled.div`
  width: ${(props) => (props.product == "bank_connect" ? "48%" : "100%")};
  @media (max-width: ${tablet}px) {
    width: 100%;
  }
`;
// export const Container = styled.div`
//   flex-direction: column;
//   background: ${(props) => props.theme.main};
//   height: 100%;
//   overflow: hidden;
// `;
export const Container = styled.div`
  border-radius: 4px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 9%;
  height: 100%;
  @media (max-width: ${mobile}px) {
    padding: 0 4%;
  }
`;
export const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
  }
`;
export const ProductContainer = styled.div`
  width: 48%;
  margin-bottom: 20px;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;
export const ProductContainerDev = styled.div`
  width: 31%;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;
export const ProductCard = styled.div`
  background: white;
  border-radius: 4px;
  padding: 15px 25px;
  // margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ececec;
  padding: 1rem;
  h4 {
    margin-bottom: 20px;
  }
`;
export const ProductDirection = styled.div`
  display: flex;
  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`;
export const ProductText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  @media (max-width: ${mobile}px) {
    margin-left: 0px;
  }
`;
export const ProductButton = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  margin-top: 20px;
  @media (max-width: ${mobile}px) {
    justify-content: end;
  }
`;
export const Wrapper = styled.div`
  padding-top: 25px;
  padding-left: 9%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  background: ${(props) => (props.color ? "white" : "#f9f9f9")};
  color: #11a1f1;
  transition: 0.2s all ease-in;
  @media (max-width: ${mobile}px) {
    padding-left: 4%;
  }
`;

export const humanize = (str) => {
  return str
    .split("_")
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");
};
export const dehumanize = (str) => {
  return str
    .split(" ")
    .map((w) => w[0].toLowerCase() + w.slice(1))
    .join("_");
};

export const InputContainer = styled.input`
  flex: 1;
  height: 32px;
  margin-top: 8px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
  :focus {
    outline-width: 1px;
  }
`;

export const TextAreaContainer = styled.textarea`
  margin-top: 8px;
  background: #ffffff;
  padding: 8px 12px;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
`;

export const LabelWithInput = (props) => {
  return (
    <>
      {props.label && (
        <div style={{ marginTop: 15 }}>
          <Bold14>{props.label}</Bold14>
        </div>
      )}
      <InputContainer
        onKeyPress={(e) => props.onKeyPress(e)}
        placeholder={props.placeholder ? props.placeholder : ""}
        value={props.value}
        onChange={(e) => props.onChange(e)}
        name={props.name}
        style={{
          margin: props.search && 0,
          borderRight: props.search && "none",
          border: props.search && "2px 0 0 2px",
          height: props.search && "34.8px",
        }}
      />
    </>
  );
};

export const LabelModalInput = (props) => {
  return (
    <>
      <div style={{ width: "48%", display: "flex", flexDirection: "column" }}>
        <Bold14>{props.label}</Bold14>
        <InputContainer
          value={props.value}
          onChange={(e) => props.onChange(e)}
          onBlur={() => props.checkemail && props.checkemail(props.value)}
        />
      </div>
    </>
  );
};

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  let csv = items.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(",")
  );
  console.log("csv unshift", csv);
  csv = csv.join("\r\n");

  console.log("CSV -> ", csv);

  console.log("CSV -> ", csv);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download = exportedFilenmae;
  hiddenElement.click();
}

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.setAttribute(
      "finbox-api-key",
      localStorage.getItem("BankConnectAPI")
    );
    script.setAttribute("finbox-redirect-url", "");
    script.setAttribute("finbox-user-id", makeid(10));
    script.setAttribute("finbox-upload-type", "netbanking");
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const initializeName = (str) => {
  return str
    .toLowerCase()
    .replace("mr. ", "")
    .replace("ms. ", "")
    .replace("mrs. ", "")
    .replace("shri ", "")
    .split(" ")
    .slice(0, 2)
    .map((n) => n[0])
    .join("")
    .toUpperCase();
};

export const convertUTCToIST = (dat) => {
  const d = new Date(dat);

  let dateUTC = d.getTime();
  let dateIST = new Date(dateUTC);
  //date shifting for IST timezone (+5 hours and 30 minutes)
  dateIST.setHours(dateIST.getHours() + 5);
  dateIST.setMinutes(dateIST.getMinutes() + 30);
  // return nd.toLocaleString();

  return dateIST;
};

export const calcTime = (dat) => {
  var dateofvisit = moment(dat);
  var today = moment();
  return dateofvisit.diff(today, "days");
};

export function getRandomLinkId() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export function redirectToNewDashboard() {
  const token = localStorage.getItem("finboxToken");
  const stage = localStorage.getItem("finboxStage");
  const diff = window.open(
    `https://dashboard.bankconnect.finbox.in/statementlist?token=${token}${
      stage ? `&stage=${stage}` : ``
    }`,
    "_self"
  );
}
