import React from "react";
import styled from "styled-components";
import { Bold12, Bold14 } from "./common";
import { MdDeleteForever } from "react-icons/md";

const DeleteButton = styled.table`
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #808088;
  &:hover {
    color: #ff5722;
  }
`;

function RowAvatarWithText(props) {
  const RowContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
  `;

  const AvatarContainer = styled.div`
    display: flex;
  `;

  const TextContainer = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    margin-left: 16px;
  `;

  const IconContainer = styled.div`
    display: flex;
  `;

  const avatarUrl =
    "https://ui-avatars.com/api/?rounded=true&name=" + props.title;

  return (
    <>
      <RowContainer>
        <AvatarContainer>
          <img width={36} src={avatarUrl} />
        </AvatarContainer>
        <TextContainer>
          <Bold14>{props.title}</Bold14>
          <Bold12>{props.content}</Bold12>
        </TextContainer>
        {/* <IconContainer>
        <MdSettings />
      </IconContainer> */}
        {/* <DeleteButton style={{ height: 22, cursor: "pointer" }}>
          <MdDeleteForever
            style={{ height: 20, width: "auto" }}
            onClick={() => props.deleteMember()}
          />
        </DeleteButton> */}
      </RowContainer>
    </>
  );
}

export default RowAvatarWithText;
