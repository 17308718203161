import React, { useEffect } from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import { Bold16, ButtonCore } from "./common";
import { Button } from "../components2/common";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 4;
`;

const Overlay = styled.div`
  background-color: black;
  position: absolute;
  opacity: 0.5;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  z-index: 5;
`;

const Banner = styled.div`
  width: fit-content;
  height: fit-content;
  /* background-image: linear-gradient(#ffffff, #f4f7fa, #dae5ef); */
  background: white;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding: 70px 50px;
`;

const BasicBanner = styled.div`
  width: fit-content;
  height: fit-content;
  background-image: linear-gradient(#ffffff, #f4f7fa, #dae5ef);
  z-index: 4;
  border-radius: 20px;
`;

function DeleteModal(props) {
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
  }, [props.showModal]);

  const escFunction = (e) => {
    if (e.keyCode == "27") props.setDeleteID("");
  };

  console.log("props", props);
  return (
    <Container>
      <Overlay />
      <Banner>
        <h1
          style={{
            fontWeight: "400",
            fontSize: "20px",
            margin: "15px 0 50px 0",
          }}
        >
          Are you sure you want to delete this request?
        </h1>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
            padding: "0 90px",
          }}
        >
          <ButtonCore color="white" onClick={() => props.setDeleteID("")}>
            Cancel
          </ButtonCore>{" "}
          <ButtonCore
            onClick={() => {
              props.deleteItem(props.deleteId);
            }}
          >
            Yes, Delete
          </ButtonCore>
        </div>
      </Banner>
    </Container>
  );
}

export default DeleteModal;
