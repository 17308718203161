import moment from "moment";
import React from "react";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import { useHistory } from "react-router-dom";
import UltimatePaginationBootstrap3 from "../components/PaginationComponent";
import { MdDeleteForever } from "react-icons/md";
import { createRef, useState, useEffect, useContext } from "react";
import { BlueLink } from "../components/common";
import AppContext from "../context/AppContext";

import "./deviceconnect.scss";

let requestTimer;

const DeleteButton = styled.table`
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #808088;
  &:hover {
    color: #ff5722;
  }
`;
function Row(props) {
  const [requestApiProcessing, setrequestApiProcessing] = useState(true);
  const [refreshCount, setrefreshCount] = useState(0);
  const [newRow, setNewRow] = useState("");
  const context = useContext(AppContext);

  const history = useHistory();

  useEffect(() => {
    if (props.row.status == "validating") {
      fetchRequestData();
      //   refreshApi();
    }
    setNewRow(props.row);
  }, [props]);

  const fetchRequestData = () => {
    if (requestApiProcessing) {
      const key = context.deviceConnectApiKey;
      const resp = context.token;
      if (!resp) {
        window.location.href = "/";
      }
      const respJSON = JSON.parse(resp);
      var myHeaders = new Headers();
      myHeaders = {
        "content-type": "application/json",
        Accept: "application/json",
        "token": respJSON.Token
      };   
      var myInit100 = {
        method: "Get",
        headers: myHeaders,
        cache: "default",
      };
      if (refreshCount < 24) {
        const requestTimer = setInterval(async () => {
          const dnResponse = await fetch(
            "https://dashboardapi.finbox.in/risk_sdk/requests/" +
              props.row.request_id ,
            myInit100
          );
          const dnResultTxt = await dnResponse.text();
          const result = JSON.parse(dnResultTxt);

          if (result.status === "validating") {
            setrequestApiProcessing(true);
            setrefreshCount(refreshCount + 1);

            clearInterval(requestTimer);
          } else {
            setNewRow(result);
            setrefreshCount(0);
            setrequestApiProcessing(false);
            clearInterval(requestTimer);
            // this.props.change_after_refresh(this.props.uuid, result);
          }
        }, 5000);
      } else {
        setrefreshCount(0);
        clearInterval(requestTimer);
      }
    }
  };

  const getdetails = (status, id) => {
    if (status != "created" && status != "validation_failed") {
      history.push("requestdetails/" + id);
    }
  };

  const showstatus = (status, error) => {
    return status == "created" || status == "validating" ? (
      "Importing"
    ) : status == "aborted" ? (
      "Aborted"
    ) : status == "processing_in_progress" ||
      status == "processing_requested" ||
      status == "validation_complete" ? (
      "In Process"
    ) : status == "processing_complete" ? (
      "Completed"
    ) : status == "validation_failed" || status == "upload_failed" ? (
      <div>
        <div
          className="hasTooltip"
          style={{ color: "#df6978", fontWeight: "400" }}
        >
          Failed
          <span
            className="tooltiptext"
            style={{
              backgroundColor: "#ffffff",
              fontWeight: 400,
              left: 0,
              color: "#000",
            }}
          >
            {error}
          </span>
        </div>
      </div>
    ) : status == "refreshing" ? (
      <MDSpinner size={14} />
    ) : (
      status
    );
  };

  const statussymbol = (status, id) => {
    return status === "validating" ? (
      <MDSpinner size={14} />
    ) : status === "processing_complete" ||
      status === "processing_in_progress" ||
      status === "processing_requested" ||
      status === "validation_complete" ||
      status === "processing_complete" ? (
      <BlueLink onClick={() => getdetails(status, id)}>Details</BlueLink>
    ) : status === "created" ||
      status === "validation_failed" ||
      status === "upload_failed" ||
      status === "aborted" ? (
      <DeleteButton style={{ height: 22, cursor: "pointer" }}>
        <MdDeleteForever
          style={{ height: 20, width: "auto" }}
          onClick={() => props.setDeleteID(id)}
        />
      </DeleteButton>
    ) : status === "refreshing" ? (
      ""
    ) : (
      ""
    );
  };

  const row = props.row;

  return (
    <tr>
      <td style={{ paddingLeft: "25px" }}>{row.request_id}</td>
      <td>{row.file_name}</td>
      <td>
        {newRow.status == "validating"
          ? "Calculating..."
          : newRow.processed_rows == null
          ? newRow.valid_rows + newRow.invalid_rows
          : newRow.processed_rows}
      </td>
      <td>
        <div style={{ fontWeight: 400 }}>
          {moment(row.created).format("MMM DD, YYYY , h:mm:ss a")}
        </div>
      </td>
      <td>{showstatus(newRow.status, row.error_message)}</td>
      <td>{statussymbol(newRow.status, row.request_id)}</td>
    </tr>
  );
}

export default Row;
