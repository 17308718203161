import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { NotificationButton } from "../common";
import "./NotificationBar.css";
import AppContext from "../../context/AppContext";
import { FiX } from "react-icons/fi";

const NotifRow = styled.div`
  display: flex;
  width: 100%;
  padding-left: 12px;
  padding-top: 12px;
  background: ${props => (props.unread ? "#f1f4f9" : "#FFF")};
  padding-bottom: 10px;
`;

const Separator = styled.div`
  border: 0.5px solid #ededed;
`;

const RequestContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  flex: 12;
`;

const ActionRow = ({ key, data, unread, dismiss, closeNotification }) => {
  const history = useHistory();
  return (
    <div
      key={key}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <NotifRow unread={unread}>
        <img
          style={{ flex: 1 }}
          src={`https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/${data.type}.svg`}
          height={35}
        />
        <RequestContent>
          <div
            style={{ fontSize: 14, color: "#3E3E3E" }}
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
          <div style={{ marginTop: 4, fontSize: 14, color: "#777777" }}>
            {data.extra_key}
          </div>
          <NotificationButton
            style={{ marginTop: 8 }}
            onClick={() => {
              closeNotification();
              history.push("/deviceconnect/requestdetails/" + data.extra_key);
            }}
          >
            View Details
          </NotificationButton>
          <div style={{ marginTop: 8, fontSize: 12, color: "#777777" }}>
            {moment(
              moment.utc(data.created_at).local(),
              "YYYY.MM.DD HH:mm:ss"
            ).fromNow()}
          </div>
        </RequestContent>
        <FiX
          style={{ flex: 1, marginRight: 8, cursor: "pointer" }}
          onClick={() => dismiss(data.notification_id)}
        />
      </NotifRow>
      <Separator />
    </div>
  );
};

const InfoRow = ({ key, data, unread, dismiss }) => {
  return (
    <div
      key={key}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <NotifRow unread={unread}>
        <img
          style={{ flex: 1 }}
          src={`https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/${data.type}.svg`}
          height={35}
        />
        <RequestContent>
          <div
            style={{ fontSize: 14, color: "#3E3E3E" }}
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
          <div style={{ marginTop: 8, fontSize: 12, color: "#777777" }}>
            {moment(
              moment.utc(data.created_at).local(),
              "YYYY.MM.DD HH:mm:ss"
            ).fromNow()}
          </div>
        </RequestContent>
        <FiX
          style={{ flex: 1, marginRight: 8, cursor: "pointer" }}
          onClick={() => dismiss(data.notification_id)}
        />
      </NotifRow>
      <Separator />
    </div>
  );
};

function NotificationHandler({ data, dismissNotification, closeNotification }) {
  const context = useContext(AppContext);

  const NotificationFactory = ({ notif, index }) => {
    switch (notif.type) {
      case "bank_down":
      case "bank_live":
      case "add_member":
      case "api_health":
        return (
          <InfoRow
            key={index}
            data={notif}
            unread={context.unreadNotif > index}
            dismiss={dismissNotification}
          />
        );
      case "dc_processed":
        return (
          <ActionRow
            key={index}
            data={notif}
            unread={context.unreadNotif > index}
            dismiss={dismissNotification}
            closeNotification={closeNotification}
          />
        );
    }
  };
  return (
    <div>
      {data &&
        data.map((notif, index) => (
          <NotificationFactory notif={notif} index={index} />
        ))}
    </div>
  );
}

export default NotificationHandler;
