import React, { Component } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import styled from "styled-components";

const ShowHideContainer = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  height: 26px;
  width: 26px;
  border-radius: 4px;
  margin-left: 12px;
  padding: 0 5px;
`;

const ProtectedKeyInputContainer = styled.span`
  flex: 1;
  border: 1px solid rgb(170, 170, 170);
  padding: 2px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
`;

class Showhide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      hiddenText: "",
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({
        hiddenText: new Array(30).join("•"),
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ProtectedKeyInputContainer
          type={this.state.hidden ? "password" : "text"}
        >
          {this.state.hidden ? this.state.hiddenText : this.props.password}
        </ProtectedKeyInputContainer>
        <ShowHideContainer onClick={this.toggleShow}>
          {this.state.hidden ? <FiEye size={12} /> : <FiEyeOff size={12} />}
        </ShowHideContainer>
      </div>
    );
  }
}

export default Showhide;
