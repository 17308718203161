import React, { useContext, useEffect, useState } from "react";
import { FaRegChartBar, FaRegListAlt, FaRegBell } from "react-icons/fa";
import { MdKeyboardArrowDown, MdNotificationsNone, MdKeyboardArrowRight } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
	calcTime,
	initializeName,
	DeveloperContainer,
	SwitchDeveloper,
	SwitchMaster,
	ButtonCore,
} from "../components/common";
import LoadingSwitch from "../components/LoadingSwitch";
import { TrialDays, Upgrade } from "../components2/common";
import AppContext from "../context/AppContext";
import ThemeContext from "../context/ThemeContext";
import { FiMenu } from "react-icons/fi";
import "./Navbar.scss";

const Switch = styled.div`
	padding: 0px 20px 1px;
	border-radius: 4px;
	font-weight: 400;
	cursor: pointer;
	border: ${(props) => (props.stage == "dev" ? "1.5px solid #11A1F1" : "1.5px solid #24ca7a")};
	color: ${(props) => (props.stage == "dev" ? "#11A1F1" : "#24ca7a")};
`;
const Wrapper = styled.div`
	/* padding: 27px 0px; */
	/* padding: 22px 35px 12px 35px; */
	padding: 13px 35px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* background: #2e3f50; */
	background: #091d2c;
	color: ${(props) => (props.demo ? props.theme.txt : "black")};
	transition: 0.2s all ease-in;
	@media (max-width: 768px) {
		padding: 13px 4%;
	}
`;

const Label = styled.span`
  margin: 0 15px;
  height:100%;
  display:flex;
  align-items:center;
  padding: 5px 0px;
  font-weight: 500;
  font-size: 14px;
  /* text-decoration: ${(props) => (props.active ? "underline" : "none")}; */
  border-bottom:${(props) => (props.active ? "2px solid #24ca7a" : "2px solid #091d2c ")};
  color: ${(props) => (props.active ? "#24ca7a" : "white")};
  :hover {
    color: ${(props) => (props.active ? "#24ca7a" : "#24ca7a")};
  }
`;

const NavBarList = styled.ul`
	display: flex;
	margin: -13px 1.8rem;
	height: 100%;
	li {
		margin: -13px 1rem;

		list-style-type: none;
		width: fit-content;

		a {
			text-decoration: none;
			color: #c4c4c4;
			display: flex;
			align-items: center;
			transition: 0.1s color;
			height: 100%;
		}
		a:hover {
			color: white;
			cursor: pointer;
		}
		a:focus {
			box-shadow: none;
		}
	}
`;

export default (props) => {
	const [profile, setProfile] = useState(null);
	const [daysLeft, setDaysLeft] = useState(null);
	// const [stage, setStage] = useState("dev");
	const [productStage, setProductStage] = useState(false);
	const [switchToggle, setSwitchToggle] = useState(false);
	const { theme, toggleTheme } = useContext(ThemeContext);
	const [seconds, setSeconds] = useState(0);
	// const [dcAccess, setDeviceAccess] = useState(false);
	const history = useHistory();
	const context = useContext(AppContext);
	const [notificationClient, setNotificationClient] = useState();

	const isTrial = async () => {
		let fT = context.token;
		const ftData = JSON.parse(fT);
		var myHeaders = new Headers();
		myHeaders.append("token", ftData.Token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let response = await fetch("https://dashboardapi.finbox.in/v1/user/config", requestOptions);
		const result = await response.text();
		const json = JSON.parse(result);
		if (json.Status) {
			setDaysLeft(calcTime(json.Data.ExpiryDate));

			context.updateDaysLeft(calcTime(json.Data.ExpiryDate));
			context.updateFirstLogin(json.Data.IsFirstLogin && json.Data.ShowStartTrial);
			context.updateProductStage(JSON.stringify(json.Data.ProductWiseStage));
			setProductStage(
				json.Data.ProductWiseStage.bank_connect == "prod" ||
					json.Data.ProductWiseStage.device_connect == "prod"
			);
			// console.log(json.Data.ProductWiseStage);
			// context.updateStage(stage);
			props.daysLeft && props.daysLeft(calcTime(json.Data.ExpiryDate));
			props.trialExpired && props.trialExpired(daysLeft < 0);
			props.productStage && props.productStage(json.Data.ProductWiseStage);
			props.isFirstLogin &&
				props.isFirstLogin(json.Data.IsFirstLogin && json.Data.ShowStartTrial);
		}
	};

	const logout = async () => {
		var myHeaders = new Headers();
		const resp = context.token;
		myHeaders.append("token", JSON.parse(resp).Token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		await fetch("https://dashboardapi.finbox.in/v1/user/logout", requestOptions);
		context.removeToken();
		window.location.href = "/";
	};

	const notificationListListener = () => {
		let fT = context.token;
		const ftData = JSON.parse(fT);
		var client = new EventSource(
			"https://dashboardapi.finbox.in/v1/notifications/stream?token=" + ftData.Token
		);
		setNotificationClient(client);
		client.onmessage = function (msg) {
			context.setUnreadNotif(msg.data);
		};
	};

	const timer = () => {
		var sec = 5;
		setSeconds(sec);
		var timer = setInterval(function () {
			console.log(sec);
			sec--;
			setSeconds(sec);
			if (sec < 0) {
				clearInterval(timer);
			}
		}, 1000);
	};

	useEffect(() => {
		console.log("before");
		if (props.notificationClosed) {
			const resp = context.token;
			if (!resp) return;
			const respJSON = JSON.parse(resp);
			setProfile({
				name: respJSON.UserData.Name,
				company: respJSON.UserData.CompanyName,
			});
			isTrial();
			// context.updateStage(stage);
			notificationListListener();
		} else {
			notificationClient && notificationClient.close();
		}
	}, [props.notificationClosed]);

	return (
		<>
			<Wrapper theme={theme} demo={props.demo}>
				<div className="leftSide">
					<div style={{ marginRight: "1rem" }} className="nav-drawer-toggle">
						<FiMenu onClick={props.navDrawerToggleClickHandler} />
					</div>

					<img
						src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/navbarLogo.svg"
						className="App-logo"
						alt="logo"
						height="35px"
						width="110.35px"
					/>

					<NavBarList className="nav-bar-list">
						<li>
							<Link to="/home">
								<Label active={props.location.pathname.includes("/home")}>Home</Label>
							</Link>
						</li>
						<li>
							<Link to="/deviceconnect">
								<Label
									active={
										props.location.pathname.includes("/deviceconnect") ||
										props.location.pathname.includes("/device_connect")
									}
								>
									DeviceConnect
								</Label>
							</Link>
						</li>

						<li>
							<Link to="/settings">
								<Label active={props.location.pathname == "/settings"}>Settings</Label>
							</Link>
						</li>
					</NavBarList>
					{productStage && context.stage === "dev" ? (
						<Switch className="toolTipSwitch">Dev</Switch>
					) : (
						""
					)}
				</div>
				<div className="rightSide nav-bar-list">
					<div className="rightElem toggleTheme"></div>
					{profile && (
						<div className="rightElem Who">
							{context.isBankConnectTrialUser && daysLeft && !productStage ? (
								<div
									style={{
										display: "flex",
										// flexDirection: "column",
										marginRight: "45px",
										alignItems: "center",
									}}
								>
									{daysLeft > 0 ? (
										<TrialDays className="trailDays">
											<div
												style={{
													background: "#24ca7a",
													color: "white",
													fontWeight: "700",
													width: "29px",
													minWidth: "29px",
													height: "29px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													borderRadius: "50%",
													marginRight: "7px",
												}}
											>
												{daysLeft}
											</div>{" "}
											Trial Days Left
										</TrialDays>
									) : daysLeft < 0 ? (
										<div className="expired">
											<img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/expired.svg" />
											<>Trial Expired</>
										</div>
									) : (
										""
									)}
									<a
										href={" https://support643206.typeform.com/to/TZ0Joj"}
										target="_blank"
										style={{ margin: "0" }}
									>
										<Upgrade className="upgrade"> Upgrade Now </Upgrade>
									</a>
								</div>
							) : (
								""
							)}

							<div>
								<div
									className={context.unreadNotif > 0 && "badge-icon"}
									data-count={context.unreadNotif}
									style={{
										marginTop: 4,
										marginRight: context.unreadNotif > 0 ? 14 : 26,
									}}
									onClick={props.openNotification}
								>
									<MdNotificationsNone
										size={20}
										color="#FFF"
										style={{ marginRight: "7px", cursor: "pointer" }}
									/>
								</div>
							</div>

							<div className="dropdown" style={{ display: "flex" }}>
								<div
									className="dropdown-toggle"
									tabIndex="0"
									style={{
										display: "flex",
										alignItems: "center",
										alignSelf: "flex-end",
										justifyContent: "flex-end",
										cursor: "pointer",
										boxShadow: "none",
										border: "none",
										outline: "none",
									}}
								>
									<figure
										className="avatar"
										data-initial={initializeName(profile.name || "")}
										style={{ backgroundColor: " #90A4AE", fontWeight: "700" }}
									></figure>

									<span
										style={{
											marginLeft: 10,
											color: " white",
											display: "flex",
											alignItems: "center",
										}}
									>
										<MdKeyboardArrowDown />
									</span>
								</div>
								{switchToggle === false ? (
									<ul
										className="menu styledlink"
										style={{
											left: "inherit",
											right: 0,
											marginTop: 20,
											padding: "0",
										}}
									>
										<li>
											<div>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "flex-end",
														boxShadow: "none",
														border: "none",
														outline: "none",
													}}
												>
													<figure
														className="avatar"
														data-initial={initializeName(profile.name || "")}
														style={{
															backgroundColor: "#5755d9",
															marginLeft: "12px",
															minWidth: "1.6rem",
														}}
													></figure>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
															marginLeft: 15,
															color: " white",
														}}
													>
														<h6 style={{ margin: 0, color: "#3e3e3e" }}>
															{profile.name}
														</h6>
														<h6
															style={{
																margin: 0,
																color: "#777777",
																fontWeight: "normal",
															}}
														>
															{profile.company}
														</h6>
													</div>
												</div>
											</div>
										</li>
										<li onClick={() => setSwitchToggle(true)}>
											<img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/queue_24px_rounded.svg" />
											Switch Mode{" "}
											<MdKeyboardArrowRight
												style={{
													fontSize: "18px",
													alignSelf: "bottom",
													marginLeft: "auto",
												}}
											/>
										</li>
										<li onClick={() => history.push("/settings")}>
											<img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/settings.svg" />
											Settings
										</li>
										<li onClick={logout}>
											<img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/logout.svg" />
											Log Out
										</li>
									</ul>
								) : (
									<ul
										className="menu styledlinktoggle"
										style={{
											left: "inherit",
											right: 0,
											marginTop: 20,
											padding: "0",
										}}
									>
										{" "}
										<li
											style={{ cursor: "pointer" }}
											onClick={() => setSwitchToggle(false)}
										>
											{" "}
											<img
												style={{ height: "14px" }}
												src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/keyboard_backspace_24px.svg"
											/>
											Switch Mode
										</li>
										<li
											onClick={() => {
												if (context.stage === "prod") {
													context.updateStage("dev");
													setSwitchToggle(false);
													timer();
												}
											}}
										>
											Dev Mode{" "}
											{context.stage === "dev" ? (
												<img
													style={{ height: "14px" }}
													src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/check_24px.svg"
												/>
											) : (
												" "
											)}
										</li>
										<li
											onClick={() => {
												if (context.stage === "dev") {
													context.updateStage("prod");
													setSwitchToggle(false);
													timer();
												}
											}}
										>
											Prod Mode{" "}
											{context.stage === "prod" ? (
												<img
													style={{ height: "14px" }}
													src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/check_24px.svg"
												/>
											) : (
												""
											)}
										</li>
									</ul>
								)}
							</div>
						</div>
					)}
				</div>
			</Wrapper>
			{seconds > 0 ? <LoadingSwitch seconds={seconds} prod={context.stage} /> : ""}
		</>
	);
};
