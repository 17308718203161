import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  BoldHeader,
  NormalDesc,
  ModalButton,
  Main,
} from "../components/common";

function PageNotFound(props) {
  const history = useHistory();
  return (
    <Main style={{ justifyContent: "center", alignItems: "center" }}>
      <div>
        <img src=" https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/404.svg " />
      </div>
      <BoldHeader>Page not found</BoldHeader>
      <div
        style={{
          width: "20%",
          textAlign: "center",
          marginTop: 10,
        }}
      >
        <NormalDesc>
          The page you are looking for doesn’t exist or an other error occurred.
        </NormalDesc>
      </div>
      <ModalButton onClick={() => history.push("/home")}>Home</ModalButton>
    </Main>
  );
}

export default PageNotFound;
