import AppContext from "./AppContext";
import React, { useState } from "react";

const AppProvider = (props) => {
  const [token, setToken] = useState(
    localStorage.getItem("finboxToken") || null
  );
  const [bankConnectApiKey, setBankConnectApiKey] = useState(
    localStorage.getItem("finboxBankConnectApiKey") || null
  );
  const [timep, setTimep] = useState(localStorage.getItem("time")||6
  );
  const [askflag, setAskflag] = useState(localStorage.getItem("ask")||false
  );
  const [any, setAny] = useState(localStorage.getItem("any") || false);
  const [companyName, setcompanyName] = useState(
    localStorage.getItem("companyName") || "FinBox"
  );
  const [deviceConnectApiKey, setDeviceConnectApiKey] = useState(
    localStorage.getItem("finboxDeviceConnectApiKey") || null
  );
  const [daysLeft, setDaysLeft] = useState(
    localStorage.getItem("finboxDaysLeft") || 1
  );
  const [firstLogin, setFirstLogin] = useState(
    JSON.parse(localStorage.getItem("finboxFirstLogin")) || false
  );
  const [productStage, setProductStage] = useState(
    localStorage.getItem("finboxProductStage") || null
  );
  const [stage, setStage] = useState(
    localStorage.getItem("finboxStage") || "dev"
  );
  const [isBankConnectTrialUser, setBankConnectTrialUser] = useState(
    JSON.parse(localStorage.getItem("finboxBankConnectTrialUser")) || false
  );
  const [unreadNotif, setUnreadNotif] = useState(0);
  const updateToken = (newToken) => {
    localStorage.setItem("finboxToken", newToken);
    setToken(newToken);
  };

  const removeToken = () => {
    setToken(null);
    setProductStage(null);
    localStorage.removeItem("finboxToken");
    localStorage.removeItem("finboxDaysLeft");
    localStorage.removeItem("finboxFirstLogin");
    localStorage.removeItem("finboxStage");
    localStorage.removeItem("ask");
    localStorage.removeItem("any");
    localStorage.removeItem("companyName");
    localStorage.removeItem("time");
    localStorage.removeItem("finboxProductStage");
    localStorage.removeItem("finboxBankConnectApiKey");
    localStorage.removeItem("finboxDeviceConnectApiKey");
    localStorage.removeItem("finboxBankConnectTrialUser");
  };

  const updateDaysLeft = (newDaysLeft) => {
    localStorage.setItem("finboxDaysLeft", newDaysLeft);
    setDaysLeft(newDaysLeft);
  };

  const updateBankConnectApiKey = (newApiKey) => {
    localStorage.setItem("finboxBankConnectApiKey", newApiKey);
    setBankConnectApiKey(newApiKey);
  };

  const updateDeviceConnectApiKey = (newApiKey) => {
    localStorage.setItem("finboxDeviceConnectApiKey", newApiKey);
    setDeviceConnectApiKey(newApiKey);
  };

  const updateFirstLogin = (newFirstLogin) => {
    localStorage.setItem("finboxFirstLogin", newFirstLogin);
    setFirstLogin(newFirstLogin);
  };

  const updateProductStage = (newProductStage) => {
    console.log(newProductStage);
    localStorage.setItem("finboxProductStage", newProductStage);
    setProductStage(newProductStage);
  };
  const updateStage = (newStage) => {
    localStorage.setItem("finboxStage", newStage);
    setStage(newStage);
  };

  const updateBankConnectTrialUser = (newBankConnectTrialUser) => {
    localStorage.setItem("finboxBankConnectTrialUser", newBankConnectTrialUser);
    setBankConnectTrialUser(newBankConnectTrialUser);
  };
  const updateaskFlag = (value) => {
    localStorage.setItem("ask",value);
    setAskflag(value);
  };
   const updateAnyFlag = (value) => {
     localStorage.setItem("any", value);
     setAny(value);
   };
    const updatecomapnyName = (value) => {
      localStorage.setItem("companyName", value);
      setcompanyName(value);
    };
  const updateTimep=(val)=>{
    localStorage.setItem("time",val);
    setTimep(val);
  };
  return (
    <AppContext.Provider
      value={{
        token,
        updateToken,
        daysLeft,
        updateDaysLeft,
        firstLogin,
        updateFirstLogin,
        productStage,
        updateProductStage,
        isBankConnectTrialUser,
        updateBankConnectTrialUser,
        bankConnectApiKey,
        updateBankConnectApiKey,
        deviceConnectApiKey,
        updateDeviceConnectApiKey,
        removeToken,
        stage,
        updateStage,
        unreadNotif,
        setUnreadNotif,
        askflag,
        updateaskFlag,
        any,
        updatecomapnyName,
        companyName,
        updateAnyFlag,
        timep,
        updateTimep,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
