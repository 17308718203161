import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container, Main, BlackButton } from "../components/common";
import AppContext from "../context/AppContext";
import { FinBoxBankConnect } from "finbox-bank-connect-js";

const Contain = styled.div`
	border: 1px solid #ececec;
	min-width: 980px;
	height: 80%;
	display: flex;
	margin-top: 40px;
	display: flex;
	border-radius: 4px;

	@media (max-width: 786px) {
		min-width: 100%;
		width: 100%;
		flex-direction: column;
	}
`;
const ErrorBanner = styled.div`
	border-bottom: 2px solid #ff5757;
	width: 100%;
	background-color: #e8d3d3;
	color: #ff5757;
	display: flex;
	align-items: center;
	padding: 18px 0;
	justify-content: center;
	img {
		margin-right: 10px;
	}
	h1 {
		font-size: 16px;
		font-weight: 700;
	}
	p {
		font-size: 14px;
		font-weight: 400;
		margin: 0;
	}

	.button {
		margin-left: 15px;
	}
	@media (max-width: 786px) {
		flex-direction: column;
		img {
			margin: 0;
		}
		.button {
			margin: 0;
		}
		.text {
			margin: 15px;
			display: flex;
			flex-direction: column;
			text-align: center;
		}
	}
`;

const Half = styled.div`
  /* background: ${(props) => (props.bg == "blue" ? " #F4F8FC" : "white")}; */
  background:white;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.image ? "space-around" : "center")};
  align-items: ${(props) => (props.image ? "center" : "baseline")};
  padding: ${(props) => (props.image ? "" : "3rem")};
  h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  p {
    font-weight: 400;
    color: #777777;
  }
  @media (max-width: 786px) {
    width: 100%;
    height:fit-content;
    order:${(props) => (props.image ? "-1" : "0")};
    padding: ${(props) => (props.image ? "" : "20px")};
    img{
      margin-top: 20px;
    width:65%;
    }
  }
`;

const Line = styled.div`
	flex-grow: 1;
	height: 3px;
	background-color: #24ca7a;
	width: 100%;
	margin-left: 35px;
`;

function getProductString(productName) {
	if (productName == "DeviceConnect") {
		return <>
		DeviceConnect enriches mobile device data for customer onboarding, underwriting and collections.
		<br /><br />
		Clients can integrate DeviceConnect SDK with their android app and access the data via explicit user consent.
		</>
	}
	return <>
	BankConnect enriches transaction data from bank account statements.
	<br /><br />
	Users can upload PDF manually or automatically via net banking login.
	</>
}

function EmptyProdError({ match }) {
	const [productName, setProductName] = useState("");
	const context = useContext(AppContext);
	const history = useHistory();

	useEffect(() => {
		setProductName(
			match.params.productName === "device_connect"
				? "DeviceConnect"
				: match.params.productName === "bank_connect"
				? "BankConnect"
				: ""
		);
	}, []);

	return (
		<Main>
			<ErrorBanner>
				<img
					src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/warning_24px_rounded.svg"
					alt="warning"
				></img>
				<div className="text">
					<h1>Your account does not have production access to {productName} yet</h1>
					<p>
						You are currently using dashboard in production mode. Please switch back to Dev
						mode to use {productName}
					</p>
				</div>
				<BlackButton
					className="button"
					onClick={() => {
						context.updateStage("dev");
						history.push(`/${productName}`);
					}}
				>
					Switch to Dev
				</BlackButton>
			</ErrorBanner>
			<Container>
				<Contain>
					<Half>
						<div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
							<h2>{productName}</h2>
							<Line />
						</div>
						<p>
							{getProductString(productName)}
						</p>
					</Half>
					<Half bg="blue" image="yes">
						<img
							src={
								productName === "BankConnect"
									? "https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/signup2.svg"
									: productName === "DeviceConnect"
									? "https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/dcempty.svg"
									: ""
							}
						/>
					</Half>
				</Contain>
			</Container>
		</Main>
	);
}

export default EmptyProdError;
