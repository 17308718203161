import React from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  makeVisFlexible,
  DiscreteColorLegend,
  Crosshair,
} from "react-vis";
export default class Stacked extends React.Component {
  state = {
    crosshairValues: [],
  };
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };
  _onNearestX = (value, { index }) => {
    // const crosshairValues = this.props.items.map(
    //   status => this.props.data[status][index]
    // );
    const crosshairValues = [value];
    console.log(crosshairValues);
    this.setState({
      crosshairValues,
    });
  };
  render() {
    const Flexible = makeVisFlexible(XYPlot);
    const BarSeries = VerticalBarSeries;

    return (
      <>
        <DiscreteColorLegend
          className="viz-offset flexLegend"
          orientation="horizontal"
          items={this.props.items}
        />
        <Flexible
          height={270}
          stackBy="y"
          className="viz-offset"
          xType="ordinal"
          onMouseLeave={this._onMouseLeave}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          {this.props.items.map((item, index) => {
            console.log(item, this.props.data);
            const data = this.props.data[item].map((elem) => ({
              x: elem.X,
              y: elem.Y,
            }));
            console.log(data);
            return (
              <BarSeries
                key={index}
                onNearestX={this._onNearestX}
                data={data}
              />
            );
          })}
          <Crosshair values={this.state.crosshairValues}>
            <div className="label-wrapper">
              {this.state.crosshairValues[0] &&
                this.props.items.map((item, index) => {
                  const tomake = this.state.crosshairValues[0];
                  const service = tomake.x;
                  return (
                    <div key={index}>
                      <div className="label-title">{item}</div>
                      <div className="label-value">
                        {
                          this.props.data[item].find((ser) => ser.X === service)
                            .Y
                        }
                      </div>
                    </div>
                  );
                })}
            </div>
          </Crosshair>
          <Crosshair values={this.state.crosshairValues}>
            <div>
              {this.state.crosshairValues[1] && this.state.crosshairValues[1].x}
            </div>
          </Crosshair>
        </Flexible>
      </>
    );
  }
}
