import fetchIntercept from "fetch-intercept";
import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AppContext from "../context/AppContext";

function PrivateRoute({ component: Component, ...rest }) {
  const [isAuthenticated, setAuthenticated] = useState(true);
  const context = useContext(AppContext);

  useEffect(() => {
    if (isAuthenticated) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "6a345d1c-6b3d-4ee3-a6b1-9e312b627a73";

      (function () {
        var d = document;
        var s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }
  }, []);

  const unregister = fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      if (context.token == null) {
        setAuthenticated(false);
      }
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if (response.url.includes("https://dashboardapi.finbox.in/")) {
        if (response.status === 403 || response.status === 401) {
          context.removeToken();
        }
        setAuthenticated(response.status != 403 && response.status != 401);
      }
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && context.token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default PrivateRoute;
