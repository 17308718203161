import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  GradientDefs,
  makeWidthFlexible,
  Crosshair
} from 'react-vis';
import { Card } from '../common';

export default class Category extends React.Component {
  state = {
    crosshairValues: [],
  }
  _onNearestX = (value, { index }) => {
    this.setState({crosshairValues:[value]});
  };
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };
  render() {
    const data = this.props.data.map(elem=>({y:elem.x,x:elem.y}));
    const FlexibleXYPlot = makeWidthFlexible(XYPlot); 
    return (

        <FlexibleXYPlot onMouseLeave={this._onMouseLeave} className="viz-offset" height={270} stackBy="x" yType="ordinal" margin={{left:100}}>
        <GradientDefs>
        <linearGradient id="CoolGradient" x1="0" x2="1.5" y1="0" y2="0">
          <stop offset="0%" stopColor="#00b6ff" stopOpacity={0.9} />
          <stop offset="100%" stopColor="#00b6ff" stopOpacity={0.01} />
        </linearGradient>
      </GradientDefs>
          <VerticalGridLines />
          <XAxis />
          <YAxis />
          <HorizontalBarSeries color="url(#CoolGradient)" data={data} onNearestX={this._onNearestX}/>
          <Crosshair values={this.state.crosshairValues}>
        {this.state.crosshairValues[0] && (
          <div className="label-wrapper">
            <div className="label-header">
              {this.state.crosshairValues[0].y}
            </div>
            <div className="label-value">{parseInt(this.state.crosshairValues[0].x)}</div>
          </div>
        )}
      </Crosshair>
        </FlexibleXYPlot>
    );
  }
}