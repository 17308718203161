import React, { useContext, useEffect } from "react";
import "spectre.css/dist/spectre.min.css";
import { ButtonCore } from "../components/common";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const Label = styled.div`
  font-size: 14;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
const ErrorLabel = styled.div`
  background: red;
  height: 30px;
  margin-top: -35px;
  border-radius: 10px;
  height: 59px;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  z-index: 2;
`;
const Text = styled.div`
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding-bottom: 5px;
  padding-left: 11px;
`;

const Input = styled.input`
  padding: 0.6em 1em;
  font-size: 0.9em;
  margin-top: 0.7em;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
`;
const StyledLink = styled(Link)`
  color: #24ca7a;
  font-size: 12;
  margin-top: 15px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: #24ca7a;
  }
`;

function ResetPass({ match }) {
  const [pass, setPass] = React.useState(null);
  const [rePass, setRePass] = React.useState(null);
  const [show, setShow] = React.useState(null);
  const [TokenValidity, setTokenValidity] = React.useState(true);

  const history = useHistory();
  let token = match.params.token;

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async (event) => {
    const myHeaders = new Headers();
    myHeaders.append("token", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://dashboardapi.finbox.in/v1/user/checktoken",
      requestOptions
    );
    const result = await response.text();
    const json = JSON.parse(result);
    if (json.Error == "Unauthorized Request") {
      setTokenValidity(false);
    }
  };
  const handleSubmit = async (event) => {
    if (pass == rePass && pass != null) {
      event.preventDefault();

      const myHeaders = new Headers();
      myHeaders.append("token", token);

      const raw = { password: pass, retypePassword: rePass };

      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow",
      };

      const response = await fetch(
        "https://dashboardapi.finbox.in/v1/user/resetpassword",
        requestOptions
      );
      const result = await response.text();
      history.push("/updatedPass");
    } else if (pass == null) {
      event.preventDefault();
      setShow(2);
    } else {
      event.preventDefault();
      setShow(1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#F7FAFD",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flexStart",
          paddingTop: "80px",
          paddingLeft: "80px",
          width: "100%",
        }}
      >
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/finbox.svg"
          className="App-logo"
          alt="logo"
        />
      </div>
      {TokenValidity ? (
        <>
          <div style={{ width: "25%", marginTop: "60px" }}>
            <h2 style={{ fontWeight: "600", fontSize: "24", color: "#3e3e3e" }}>
              Reset Password
            </h2>

            <form style={{ marginTop: "10px" }}>
              <Label>
                <text style={{ fontWeight: "550" }}>Password</text>
                <Input
                  type="password"
                  name="password"
                  placeholder="Enter a password"
                  onChange={(event) => setPass(event.target.value)}
                ></Input>
              </Label>
              <Label>
                <text style={{ fontWeight: "550" }}>Retype Password</text>
                <Input
                  type="password"
                  name="rePassword"
                  placeholder="Retype your password"
                  style={{
                    zIndex: "3",
                  }}
                  onChange={(event) => setRePass(event.target.value)}
                ></Input>
                {show == 1 ? (
                  <ErrorLabel>
                    <Text>Passwords don't match. Try Again</Text>
                  </ErrorLabel>
                ) : show == 2 ? (
                  <ErrorLabel>
                    <Text>Password can't be empty. Try Again</Text>
                  </ErrorLabel>
                ) : (
                  ""
                )}
              </Label>

              <div
                style={{
                  display: "flex",
                  alignItems: " center",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <ButtonCore onClick={handleSubmit}>Submit</ButtonCore>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: "25%", marginTop: "60px", textAlign: "center" }}>
            <img
              src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/expired.svg"
              alt="expired"
              width="200"
            />
            <h2
              style={{
                fontWeight: "600",
                fontSize: "24",
                color: "#3e3e3e",
                marginTop: "40px",
              }}
            >
              Expired Link
            </h2>
            <p
              style={{
                fontWeight: "400",
                fontSize: "14",
                color: "#777777",
                marginTop: "20px",
              }}
            >
              Rest link has been expired. Please{" "}
              <StyledLink onClick={() => history.push("/forgotpass")}>
                Try Again
              </StyledLink>
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default ResetPass;
