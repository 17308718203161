import React from "react";
import styled from "styled-components";
import { ButtonCore } from "./common";
import {
  Bold14,
  Bold16,
  Card,
  OutlineButton,
  ProductText,
  ProductContainerDev,
  ProductButton,
  ProductCard,
} from "./common";

function ProductCardDev(props) {
  return (
    <ProductContainerDev>
      <ProductCard>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {" "}
          <img src={props.src} height={150} style={{ margin: 16 }} />
          <Bold14 style={{ fontWeight: 600, fontSize: 20, marginTop: 16 }}>
            {props.title}
          </Bold14>
          <Bold16
            style={{
              color: "#777777",
              fontSize: 16,
              marginTop: 16,
              fontWeight: "400",
            }}
          >
            {props.desc}
          </Bold16>
        </div>
        <ProductButton>
          <OutlineButton onClick={props.onClick} width="full">
            {props.buttonText}
          </OutlineButton>
        </ProductButton>
      </ProductCard>
    </ProductContainerDev>
  );
}

export default ProductCardDev;
