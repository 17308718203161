import moment from 'moment';
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { FiMaximize2 } from "react-icons/fi";

export default class LineTabular extends Component {
  state = {
    isModalShowing: false
  };
  onClick = () => {
    this.setState({
      isModalShowing: true
    });
  };
  closeModal = () => {
    this.setState({
      isModalShowing: false
    });
  };

  getHeaders = (title) => {
    return [
      { label: "Date", key: "x" },
      { label: title, key: "y" }
    ];
  }

  renderRow = (elem, ind) => {
    return (
      <tr key={ind}>
        <td>{moment(elem.x).format(this.props.tabularFormat)}</td>
        <td>{elem.y}</td>
      </tr>
    );
  };
  render() {
    return (
      <div>
        <div className="flex-table-actions">
          {/* <div className="close-wrapper tabular" onClick={this.props.toggleTabular}>← Close</div> */}

          <div />
          <div className="flex-table-actions__right">
            {/* <FiDownload onClick={this.download} /> */}
            <FiMaximize2 onClick={this.onClick} />
          </div>
        </div>
        <div className="scrollable">
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>{this.props.title}</th>
              </tr>
            </thead>
            <tbody>{this.props.data.map((v, i) => this.renderRow(v, i))}</tbody>
          </table>
        </div>

        <div
          className={`modal ${this.state.isModalShowing && "active"}`}
          id="modal-id"
        >
          <a
            onClick={this.closeModal}
            className="modal-overlay"
            aria-label="Close"
          ></a>

          <div className="modal-container">
            <div className="modal-header">
              <a
                onClick={this.closeModal}
                className="btn btn-clear float-right"
                aria-label="Close"
              ></a>
              <div className="modal-title h5">Tabular View</div>
            </div>
            <div className="modal-body">
              <div className="scrollable-large">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>{this.props.title}</th>
                    </tr>
                  </thead>
                  <tbody>{this.props.data.map((v, i) => this.renderRow(v, i))}</tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CSVLink
                  data={this.props.data}
                  filename={this.props.title + ".csv"}
                  headers={this.getHeaders(this.props.title)}
                  className="btn btn-success"
                  target="_blank"
                >
                  Download
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
