import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ThemeContext from "../context/ThemeContext";
import Shimmer from "react-shimmer-effect";
import classNames from "classnames";
// import { BsPerson, BsPeople } from "react-icons/bs";
import { MdPersonOutline, MdPeopleOutline, MdCode } from "react-icons/md";
import "./Navbar.scss";

import {
  initializeName,
  convertUTCToIST,
  calcTime,
} from "../components/common";
import AppContext from "../context/AppContext";

const TabBody = styled.div`
  height: 100%;
  flex-grow: 1;
  padding: 32px 32px 15px 32px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 12px 0px 0px 0px;
  /* overflow: scroll; */
`;
const TabButton = styled.button`
  /* color: ${(props) => props.theme.txt}; */
  margin-bottom: 10px;
`;
const TabWrapper = styled.div`
min-width:275px;
width:275px;
padding: 32px 45px 32px 32px;
align-items:baseline;
padding-left: 30px;
display: flex;
flex-direction: column;
/* background: ${(props) => (props.demo ? props.theme.main : "white")}; */
transition: 0.2s all ease-in;
`;

export const Tabs = (props) => {
  const context = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const { theme } = useContext(ThemeContext);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const resp = context.token;
    if (!resp) return;
    const respJSON = JSON.parse(resp);
    setProfile({
      name: respJSON.UserData.Name,
      company: respJSON.UserData.CompanyName,
    });
  }, []);

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <TabButton
        theme={theme}
        className={classNames("nav-label", {
          "nav-label-active": activeIndex === index,
          "nav-dark": theme.name === "dark",
        })}
        onClick={() => {
          props.onTabChange && props.onTabChange(child.props.label);
          setActiveIndex(index);
        }}
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <img
          src={`../images/settings/${child.props.label}.svg`}
          style={{ marginRight: "16px",fill:"white" }}
        /> */}
        {/* <img src={child.props.label == "Profile" ? Profile : ""} />{" "} */}
        {child.props.label == "Profile" ? (
          <MdPersonOutline />
        ) : child.props.label == "Members" ? (
          <MdPeopleOutline />
        ) : child.props.label == "Configuration" ? (
          <MdCode />
        ) : (
          ""
        )}
        {child.props.label}
      </TabButton>
    );
  });

  return (
    <>
      <TabWrapper demo={props.demo} theme={theme}>
        <div style={{ fontSize: "24px", color: "#3e3e3e", fontWeight: "700" }}>
          Settings
        </div>
        {profile && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
              boxShadow: "none",
              border: "none",
              outline: "none",
              marginTop: "35px",
            }}
          >
            <figure
              className="avatar"
              data-initial={initializeName(profile.name || "")}
              style={{ backgroundColor: "#5755d9", minWidth: "1.6rem" }}
            ></figure>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: 15,
                color: " white",
              }}
            >
              <h6 style={{ margin: 0, color: "#3e3e3e" }}>{profile.name}</h6>
              <h6 style={{ margin: 0, color: "#777777" }}>{profile.company}</h6>
            </div>
          </div>
        )}
        <div
          style={{
            height: "2px",
            background: "#DEE2E9",
            width: "30%",
            margin: "25px 0",
          }}
        />
        {tabs}
      </TabWrapper>
      {props.children[activeIndex]}
    </>
  );
};
export const Tab = (props) => {
  const { theme } = useContext(ThemeContext);
  // console.log("Tab props childern", props.children);
  return props.children == null ? (
    <div
      style={{
        border: "0px solid rgba(255, 255, 255, 1)",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
        borderRadius: "4px",
        backgroundColor: "#f4f8fc",
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Shimmer>
        <div
          style={{
            width: "92%",
            height: "100px",
            alignSelf: "center",
            marginTop: "16px",
            borderRadius: "8px",
          }}
        />
        <div
          style={{
            width: "92%",
            height: "200px",
            alignSelf: "center",
            marginTop: "16px",
            borderRadius: "8px",
          }}
        />
      </Shimmer>
    </div>
  ) : (
    <TabBody demo={props.demo} theme={theme}>
      {props.children}
    </TabBody>
  );
};
