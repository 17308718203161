import React, { useState, useEffect, useContext } from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import {
  FillerText,
  ModalButton,
  SmallText,
  CenterCard,
} from "./common";
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 2;
`;

const Overlay = styled.div`
  background-color: black;
  position: absolute;
  opacity: 0.5;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  z-index: 3;
`;
const ModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Banner = styled.div`
  width: fit-content;
  height: fit-content;
  background-image: linear-gradient(#ffffff, #f4f7fa, #dae5ef);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 50px 150px;
  @media (max-width: 1024px) {
    width: 90%;
    padding: 20px;
    text-align: center;
    img {
      height: 120px;
    }
  }
`;

const BasicBanner = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: "white";
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  @media (max-width: 1024px) {
    width: 90%;
    padding: 20px;
    text-align: center;
    img {
      height: 120px;
    }
  }
`;

const RequestTrialContent = (props) => {
  return (
    <Banner>
      <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/modal.svg" />
      <h1
        style={{
          fontWeight: "600",
          fontSize: "30px",
          margin: "30px 0 15px 0",
        }}
      >
        Build with FinBox BankConnect
      </h1>
      <FillerText>
        Get enriched, clean bank account transactional data of your users in
        seconds.{" "}
      </FillerText>
      <ModalBottom>
        <ModalButton onClick={props.onPrimaryClick}>
          Begin BankConnect Trial
        </ModalButton>
        <div
          style={{
            display: "flex",
            padding: "0 26px",
            justifyContent: "space-between",
          }}
        >
          <SmallText>14 days trial</SmallText>
          <div style={{ width: "1.8px", backgroundColor: "#3e3e3e" }}></div>
          <SmallText>No Credit Card Required</SmallText>
        </div>
      </ModalBottom>
      <div
        style={{
          color: "#24ca7a",
          fontSize: "14px",
          fontWeight: "600",
          marginTop: "38px",
          cursor: "pointer",
        }}
        onClick={props.onSecondaryClick}
      >
        MIGRATE TO PRODUCTION
      </div>
    </Banner>
  );
};

const TrialActivatedContent = (props) => {
  return (
    <Banner>
      <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/modal.svg" />
      <h1
        style={{
          fontWeight: "600",
          fontSize: "30px",
          margin: "30px 0 15px 0",
        }}
      >
        Congratulations
      </h1>
      <FillerText>
        Your trial has now begun. Please go ahead and explore dashboard{" "}
      </FillerText>

      <div
        style={{
          color: "#24ca7a",
          fontSize: "14px",
          fontWeight: "600",
          marginTop: "38px",
          cursor: "pointer",
        }}
        onClick={props.onExploreClick}
      >
        Explore Dashboard
      </div>
    </Banner>
  );
};

const TrialExpiredContent = (props) => {
  return (
    <BasicBanner>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "95%",
          margin: 10,
        }}
        className="float-right"
        onClick={props.onClose}
      >
        <FiX style={{ marginTop: 10, cursor: "pointer" }} />
      </div>
      <Banner>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/ic-trail-end.svg" />
          <h1
            style={{
              fontWeight: "600",
              fontSize: "30px",
              margin: "30px 0 15px 0",
            }}
          >
            Upgrade now to get full access.
          </h1>
          <FillerText>
            Feel free to call us, we are happy to help you upgrade your
            dashboard.{" "}
          </FillerText>
          <ModalBottom>
            <ModalButton
              onClick={(e) =>
                window.open(
                  "https://support643206.typeform.com/to/yFcJ9O",
                  "_blank"
                )
              }
            >
              Talk to Sales
            </ModalButton>
          </ModalBottom>
        </div>
      </Banner>
    </BasicBanner>
  );
};

function StartingModal(props) {
  console.log("props", props);
  return (
    <Container style={{ display: props.showmodal ? "flex" : "none" }}>
      <Overlay />
      {props.trialExpired ? (
        <TrialExpiredContent
          onClose={props.onClose}
          onPrimaryClick={props.onPrimaryClick}
        />
      ) : props.trailActivated ? (
        <TrialActivatedContent onExploreClick={props.onExploreClick} />
      ) : (
        <RequestTrialContent
          onPrimaryClick={props.onPrimaryClick}
          onSecondaryClick={props.onSecondaryClick}
        />
      )}
    </Container>
  );
}

export default StartingModal;
