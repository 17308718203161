import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Main, ResponsiveContainer } from "../components/common";
import ProductCardProd from "../components/ProductCardProd";
import StartingModal from "../components/StartingModal";
import AppContext from "../context/AppContext";

function Home(props) {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [trailActivated, setTrailActivated] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    console.log("Context inside -> ", context.firstLogin);
    const resp = context.token;
    if (!resp) return;
    const respJSON = JSON.parse(resp);
    setProfile({
      name: respJSON.UserData.Name,
    });
  }, []);

  const startTrial = async () => {
    let fT = context.token;
    const ftData = JSON.parse(fT);
    var myHeaders = new Headers();
    myHeaders.append("token", ftData.Token);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    let response = await fetch(
      "https://dashboardapi.finbox.in/v1/user/starttrial",
      requestOptions
    );
    const result = await response.text();
    const json = JSON.parse(result);
    if (json.Status) {
      setTrailActivated(true);
    } else {
      setTrailActivated(false);
      alert(json.Error);
    }
  };

  return (
    <>
      {context.firstLogin ? (
        <StartingModal
          trailActivated={trailActivated}
          onPrimaryClick={() => startTrial()}
          onSecondaryClick={() =>
            window.open(
              "https://support643206.typeform.com/to/TZ0Joj",
              "_blank"
            )
          }
          onExploreClick={() => window.location.reload()}
          onFailedAction={() =>
            window.open(
              "https://support643206.typeform.com/to/TZ0Joj",
              "_blank"
            )
          }
        />
      ) : (
        ""
      )}
      {profile && (
        <Main style={{ background: "#f9f9f9" }}>
          <Container>
            <>
              <div style={{ margin: "40px 0 30px 0", width: "100%" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "7%",
                }}
              >
                <ProductCardProd
                  src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/dcempty.svg"
                  alt="DeviceConnect"
                  title="DeviceConnect"
                  desc={
                    <>
                    DeviceConnect enriches mobile device data for customer onboarding, underwriting and collections via our DeviceConnect integration.
                    <br />
                    </>
                  }
                  buttonText="Explore DeviceConnect"
                  onClick={() => history.push("/deviceconnect")}
                />
              </div>
            </>
          </Container>
        </Main>
      )}
    </>
  );
}
export default Home;
