import React, { useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import "spectre.css/dist/spectre.min.css";
import "./Signup.css";

import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { useHistory } from "react-router-dom";

import SignupForm from "../components2/SignupForm";

const TopLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  color: #3e3e3e;
  a {
    font-weight: 400;
    color: #24ca7a;
    :hover,
    &:hover,
    &:visited,
    &.is-hovered &:focus:not(:active),
    &.is-focused:not(:active) {
      color: #24ca7a;
      text-decoration: underline;
    }
  }
  p {
    /* margin-bottom: 0; */

    margin: 0 0 0 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin: 8px 0 0 0;
    }
  }
`;
const Half1 = styled.div`
  padding: 3em 9.5em;
  display: flex;
  flex-direction: column;
  /* max-height: 100vh; */
  overflow-y: scroll;
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 7% 15%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 40px 25px;
  }
`;
const Half2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  height: 100%;
  background: #f7fafd;
  width: 50%;
  .image {
    width: 100%;
    padding: 0 5rem;
  }
  img {
    width: 100%;
  }
  h2 {
    font-weight: 700;
    font-size: 1.2rem;
    color: #3e3e3e;
    margin-top: 20px;
    transition: 0.5s all ease-in;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
  }

  @media (max-width: 1000px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
    width: 100%;
    padding: 40px 25px;
  }
`;

const ProgressCircle = styled.div`
  border-radius: 1em;
  width: ${(props) => (props.number == props.state ? "1.5em" : "0.7em")};
  background-color: ${(props) =>
    props.number == props.state ? "#24ca7a" : "#B2ECD0"};
  height: 0.7em;
  transition: width 0.5s;
  margin: 15px 0;
`;

function Signup({ match }) {
  const [progressState, setProgressState] = React.useState(1);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [organisation, setOrganisation] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [accountExist, setaccountExist] = React.useState(false);
  const [validationError, setValidationError] = React.useState("");
  const [submittingState, setSubmittingState] = React.useState("OTP short");

  const history = useHistory();
  let mailID;

  useEffect(() => {
    mailID = match.params.email;
    mailID && setEmail(mailID);
  }, []);

  const signUp = (event, email, password, phone, organisation, name, otp) => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
      mobile: phone,
      companyname: organisation,
      name: name,
      otp: otp.toString(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var data = {
      data: {
        name: name,
        email: email,
        contact_number: phone,
        organization: organisation,
      },
    };

    fetch("https://dashboardapi.finbox.in/v1/user/signup", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        const json = JSON.parse(result);
        if (json.Status) {
          axios
            .post(
              "https://zkn6vxxf2h.execute-api.ap-south-1.amazonaws.com/prod/api",
              data
            )
            .then((resp) => {
              console.log("submitted!");
              if (resp.status === 200) {
                history.push("/accountCreated");
              }
            });
        } else {
          // setaccountExist(true);
          setValidationError(json.Error);
          setSubmittingState("Initial");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div
      id="container"
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        padding: 0,
        overflowY: "hidden",
      }}
    >
      <Half1>
        <TopLogo>
          <img
            src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/finbox.svg"
            className="App-logo"
            alt="logo"
          />
        </TopLogo>

        {progressState == 1 ? (
          <SignupForm
            heading="See FinBox In Action"
            subheading="Build financial services for the Next Billion Indians. Sign up to get trial access to FinBox. No credit card required."
            progressState={progressState}
            setProgressState={setProgressState}
            setEmail={setEmail}
            email={email}
            password={password}
            setPassword={setPassword}
            setName={setName}
            name={name}
            organisation={organisation}
            setOrganisation={setOrganisation}
            mailID={mailID}
            validationError={validationError}
            setValidationError={setValidationError}
            submittingState={submittingState}
            setSubmittingState={setSubmittingState}
          />
        ) : progressState == 2 ? (
          <SignupForm
            heading="One last step"
            subheading="We use phone number to contact you further. No need to worry about promotional spams. We don’t do that here"
            progressState={progressState}
            setProgressState={setProgressState}
            phone={phone}
            email={email}
            password={password}
            name={name}
            organisation={organisation}
            setPhone={setPhone}
            signUp={signUp}
            mailID={mailID}
            validationError={validationError}
            setValidationError={setValidationError}
            submittingState={submittingState}
            setSubmittingState={setSubmittingState}
          />
        ) : (
          ""
        )}
        {progressState == 1 ? (
          <TopLogo>
            <p>
              Already have an account?{" "}
              <span>
                <a href="/">Log In</a>
              </span>
            </p>
          </TopLogo>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 42%",
            marginTop: "10px",
          }}
        >
          <ProgressCircle number="1" state={progressState} />
          <ProgressCircle number="2" state={progressState} />
        </div>
      </Half1>

      <Half2>
        {/* <div
        style={{
          width: "50%",
          
        }}
      > */}
        <Carousel autoPlay={4000} animationSpeed={1000} infinite dots>
          <div className="centerCoumn">
            <div className="image">
              <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/signup1.svg" />
            </div>
            <h2>Integrate 50+ Banks with a single API</h2>
            <p>
              BankConnect supports multiple PDF formats, time periods and
              narrations for account statements across all major banks in India.
            </p>
          </div>
          <div className="centerCoumn">
            <div className="image">
              <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/signup2.svg" />
            </div>
            <h2>Sign up to get free access for 14 days</h2>
            <p>
              Sign up to try all features of BankConnect on dashboard and in
              sandbox environment for 14 days. No credit card required
            </p>
          </div>
          <div className="centerCoumn">
            <div
              className="image"
              style={{ padding: "0 2rem", marginBottom: "3rem" }}
            >
              <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/signup3.svg" />
            </div>
            <h2>Trusted by leading fintech companies</h2>
            <p>
              FinBox BankConnect is integrated with mobile and web applications
              of top digital lenders
            </p>
          </div>
        </Carousel>
      </Half2>
    </div>
  );
}

export default Signup;
