import React from "react";
import Loader from "react-dots-loader";
import "react-dots-loader/index.css";
import styled from "styled-components";
import { ButtonCore } from "./common";

const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
	background: white;
	justify-content: space-around;
	top: 0;
	z-index: 999;
	left: 0;
	text-align: center;
`;

function LoadingSwitch(props) {
	return (
		<Modal>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "50%",
				}}
			>
				{" "}
				<Loader distance={30} />
				<h3 style={{ marginTop: "35px" }}>
					Switching your account to {props.prod.charAt(0).toUpperCase() + props.prod.slice(1)}{" "}
					mode
				</h3>
				<p>
					All services will work in {props.prod.charAt(0).toUpperCase() + props.prod.slice(1)}{" "}
					mode for this session. Your can change it back from the profile
				</p>
				<p style={{ fontSize: "12px" }}>Please allow us {props.seconds} seconds</p>
			</div>
		</Modal>
	);
}

export default LoadingSwitch;
