import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "./Navbar.scss";
import { useHistory } from "react-router-dom";
import { Wrapper } from "./common";

const Crumb = styled.div`
  margin-right: 15px;
  font-weight: 400;
  transition: 0.2s all ease-in;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:nth-last-child(2) {
    color: #3e3e3e;
    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }
`;

const Arrow = styled.div`
  margin-right: 15px;
  font-weight: 700;
  transition: 0.2s all ease-in;
  &:last-child {
    display: none;
  }
`;

function BreadCrumbsNavbar(props) {
  const [location, setlocation] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // if (props.loc.length == 1) {
    //   setlocation([props.loc]);
    // } else {
    setlocation([...props.loc]);
    // }
  }, []);
  // console.log(location);
  return (
    <Wrapper color={props.color}>
      {location.map((loc) => (
        <>
          <Crumb onClick={() => history.push(loc.link)}>{loc.name}</Crumb>{" "}
          <Arrow>
            <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/breadcrumbArrow.svg" />
          </Arrow>
        </>
      ))}
    </Wrapper>
  );
}
export default BreadCrumbsNavbar;
