import React from "react";
import styled from "styled-components";
import { ButtonCore } from "./common";

const Container = styled.div`
  border: 1px solid #ececec;
  min-width: 980px;
  height: 80%;
  display: flex;
  margin-top: 40px;
  display: flex;
  border-radius: 4px;
  @media (max-width: 786px) {
    min-width: 100%;
    width: 100%;
    flex-direction: column;
  }
`;

const Half = styled.div`
  /* background: ${(props) => (props.bg == "blue" ? " #F4F8FC" : "white")}; */
  background:white;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.image ? "space-around" : "center")};
  align-items: ${(props) => (props.image ? "center" : "baseline")};
  padding: ${(props) => (props.image ? "" : "3rem")};
  h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  p {
    font-weight: 400;
    color: #777777;
  }
  @media (max-width: 786px) {
    width: 100%;
    height:fit-content;
    order:${(props) => (props.image ? "-1" : "0")};
    padding: ${(props) => (props.image ? "" : "20px")};
    img{
      margin-top: 20px;
    width:65%;
    }
  }
`;

const Line = styled.div`
  flex-grow: 1;
  height: 3px;
  background-color: #24ca7a;
  width: 100%;
  margin-left: 35px;
`;

function EmptyContainer({ heading, content, link, image }) {
  return (
    <Container>
      <Half>
        <div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
          <h2>{heading}</h2>
          <Line />
        </div>
        <p>{content}</p>
        <ButtonCore
          style={{ padding: "12px 33px", fontSize: "16px" }}
          onClick={() => window.open(link, "_blank")}
        >
          Request Access
        </ButtonCore>
      </Half>
      <Half bg="blue" image="yes">
        <img src={image} />
      </Half>
    </Container>
  );
}

export default EmptyContainer;
