import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "spectre.css/dist/spectre.min.css";
import styled from "styled-components";
import {
  ButtonCore,
  humanize,
} from "../components/common";
import AppContext from "../context/AppContext";

const TopLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  color: #3e3e3e;
  a {
    font-weight: 400;
    color: #24ca7a;
    :hover,
    &:hover,
    &:visited,
    &.is-hovered &:focus:not(:active),
    &.is-focused:not(:active) {
      color: #24ca7a;
      text-decoration: underline;
    }
  }
  p {
    /* margin-bottom: 0; */

    margin: 20px 0 0 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin: 10px 0 0 0;
    }
  }
`;
const Half1 = styled.div`
  padding: 4em 9.5em;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 7% 15%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 40px 25px;
  }
`;
const ResponsiveForm = styled.div`
  margin-top: 60px;
  width: 80%;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 20px;
    padding: 7% 15%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    padding: 40px 0;
  }
`;
const Half2 = styled.div`
  padding: 4em 9.5em;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 50%;
  @media (max-width: 1000px) {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 40px 25px;
  }
`;
const Label = styled.div`
  font-size: 14;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
const StyledLink = styled(Link)`
  color: #24ca7a;
  font-size: 12;
  font-weight: 400;
  &:visited {
    color: #24ca7a;
  }
`;

const Input = styled.input`
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
`;
const Submit = styled(ButtonCore)`
  width: 161px;
  cursor: ${(props) =>
    props.loginState == "empty"
      ? "default"
      : props.loginState == "filled"
      ? "pointer"
      : ""};
  background-color: ${(props) =>
    props.loginState == "empty"
      ? "#cacaca"
      : props.loginState == "filled"
      ? "#24ca7a"
      : ""};
  &:hover {
    background-color: ${(props) =>
      props.loginState == "empty"
        ? "#cacaca"
        : props.loginState == "filled"
        ? "#05a16d"
        : ""};
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Login() {
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loginState, setloginState] = React.useState("empty");
  const [err, setErr] = React.useState(null);
  const history = useHistory();
  const context = useContext(AppContext);

  useEffect(() => {
    console.log("Context -> ", context);
    const token = context.token;
    if (token) {
      const parsedToken = JSON.parse(token);
      const product = humanize(parsedToken.UserData.Products[0]);
      console.log(product.replace(" ", ""));
      if (parsedToken.UserData.Products.length == 1) {
        history.push(`/${product.replace(" ", "").toLowerCase()}`);
        // else history.push("/home");
      } else {
        history.push("/home");
      }
    }
  }, []);
  const handleSubmit = async (event, password, email) => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let response;
    try {
      response = await fetch(
        "https://dashboardapi.finbox.in/v1/user/login",
        requestOptions
      );
    } catch (err) {
      setErr("Incorrect Password. Please Retry.");
      return;
    }

    const result = await response.text();
    const json = JSON.parse(result);
    if (json.Status) {
      context.updateStage(
        json.Data.ProductWiseStage.bank_connect === "prod" ||
          json.Data.ProductWiseStage.device_connect === "prod"
          ? "prod"
          : "dev"
      );

      setErr(null);
      context.updatecomapnyName(json.Data.UserData.CompanyName);
      context.updateToken(JSON.stringify(json.Data));
      context.updateBankConnectTrialUser(
        json.Data.UserData.Products.includes("bank_connect")
      );
      if (json.Data.UserData.Products.length == 1) {
        const product = humanize(json.Data.UserData.Products[0]);
        if(product === "Bank Connect" ) history.push("/home") ;
        else history.push(`/${product.replace(" ", "").toLowerCase()}`);
      } else {
        history.push("/home");
      }
    } else {
      setErr(json.Error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Half1>
        <TopLogo>
          <img
            src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/finbox.svg"
            className="App-logo"
            alt="logo"
          />
        </TopLogo>
        <ResponsiveForm>
          <h4 style={{ color: "#3e3e3e", fontWeight: "700" }}>Sign In</h4>
          <p
            style={{
              color: "#777777",
              marginTop: "15",
            }}
          >
            Sign in to access your FinBox Dashboard.
          </p>
          <form style={{ marginTop: "10px" }}>
            <Label style={{}}>
              Email
              <Input
                type="text"
                name="email"
                placeholder="Enter your user email ID"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  if (event.target.value != "" && password != "") {
                    setloginState("filled");
                    console.log("imacalled");
                  }
                }}
              ></Input>
            </Label>
            {err == "Account doesn't exists" ? (
              <small style={{ color: "red" }}>Account doesn't exist</small>
            ) : (
              ""
            )}
            <Label>
              Password
              <Input
                type="password"
                name="password"
                placeholder="Enter your password"
                // value={state.pass}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setErr("");
                  if (event.target.value != "" && email != "") {
                    setloginState("filled");
                    console.log("passweod");
                  }
                }}
              ></Input>
            </Label>
            {err == "Incorrect Password" ? (
              <small style={{ color: "red" }}>{err}</small>
            ) : err != "Incorrect Password" &&
              err != "Account doesn't exists" &&
              err ? (
              <small style={{ color: "red" }}>
                Something went wrong please try again
              </small>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                alignItems: " center",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <StyledLink onClick={() => history.push("/forgotPass")}>
                Forgot Password?
              </StyledLink>
              <Submit
                loginState={loginState}
                onClick={(event) => {
                  event.preventDefault();
                  if (email != "" && password != "") {
                    handleSubmit(event, password, email);
                  }
                }}
              >
                {" "}
                Log In
              </Submit>
            </div>
          </form>
          <TopLogo>
            <p>
              Contact us to get access{" "}
              <span>
                <a target="_blank" href="https://finbox.in/contact-us/">
                  Request Access
                </a>
              </span>
            </p>
          </TopLogo>
        </ResponsiveForm>
      </Half1>
      <Half2
        style={{
          background: "#F7FAFD",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/loginim.svg"
          className="App-logo"
          alt="logo"
        />
        <div style={{ textAlign: "center" }}>
          {/* <div style={{ fontSize: 20, marginBottom: 20 }}>Clean Dashboard</div>
          <div style={{ fontSize: 16, color: "#708DAA", maxWidth: 300 }}>
            This is an amazingly clear description about our amazing Dashboard.
          </div> */}
          {/* <Dots num={3}></Dots> */}
        </div>
      </Half2>
    </div>
  );
}
const Dots = (props) => {
  return (
    <div>
      {[...Array(props.num)].map((v) => (
        <div
          style={{
            display: "inline-block",
            width: 8,
            height: 8,
            borderRadius: 5,
            margin: 2,
            marginTop: 25,
            background: "#708DAA",
          }}
        ></div>
      ))}
    </div>
  );
};
export default Login;
