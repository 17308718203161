import React, { Component } from "react";
import { FiMaximize2 } from "react-icons/fi";
import { exportCSVFile } from "../common"
export default class StackedTabular extends Component {
  state = {
    isModalShowing: false
  };
  onClick = () => {
    this.setState({
      isModalShowing: true
    });
  };
  closeModal = () => {
    this.setState({
      isModalShowing: false
    });
  };
  renderRows = data => {
    const services = data[this.props.items[0]].map(services => services.X);
    return services.map((service, serviceIndex) => {
      return (
        <tr key={serviceIndex}>
          <td>{service}</td>
          {this.props.items.map((item, itemIndex) => (
            <td>{data[item][serviceIndex].Y}</td>
          ))}
        </tr>
      );
    });
  };
  getRowData = (data) => {
    const services = data[this.props.items[0]].map(services => services.X);
    return services.map((service, serviceIndex) =>
      ({
        [data.length]: service,
        ...this.props.items.map((item, itemIndex) => (
          data[item][serviceIndex].Y
        ))
      })
    )
  }
  getHeaders = (props) => {
    return ({
      [props.items.length]: "Status",
      ...props.items.map((item) => item)
    })
  }
  render() {
    return (
      <div>
        <div className="flex-table-actions">
          {/* <div
            className="close-wrapper tabular"
            onClick={this.props.toggleTabular}
          >
            ← Close
          </div> */}
          <div />
          <div className="flex-table-actions__right">
            {/* <FiDownload onClick={this.download} /> */}
            <FiMaximize2 onClick={this.onClick} />
          </div>
        </div>
        <div className="scrollable">
          <table className="table">
            <thead>
              <tr>
                <th>Status</th>
                {this.props.items.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>{this.renderRows(this.props.data)}</tbody>
          </table>
        </div>

        <div
          className={`modal ${this.state.isModalShowing && "active"}`}
          id="modal-id"
        >
          <a
            onClick={this.closeModal}
            className="modal-overlay"
            aria-label="Close"
          ></a>

          <div className="modal-container">
            <div className="modal-header">
              <a
                onClick={this.closeModal}
                className="btn btn-clear float-right"
                aria-label="Close"
              ></a>
              <div className="modal-title h5">Tabular View</div>
            </div>
            <div className="modal-body">
              <div className="scrollable-large">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Status</th>
                      {this.props.items.map((item, i) => (
                        <th key={i}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{this.renderRows(this.props.data)}</tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button className="btn btn-success"
                  onClick={() => {
                    exportCSVFile(this.getHeaders(this.props), this.getRowData(this.props.data), "status_data")
                  }}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
