import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../images/dchome/fish.json";
import "./deviceconnect.scss";
import { createRef, useState, useEffect } from "react";

function IllustrationRequest({ imgsrc, heading, subHeading }) {
  const [isStopped, setStatus] = useState(false);
  const [isPaused, setStepper] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="centerColumn requestIllus">
      {/* <Lottie options={defaultOptions} height={120} width={120} /> */}
      <img src={imgsrc} width="130px" />
      <h1
        style={{
          fontSize: "1.3rem",
          fontWeight: "700",
          color: "#3e3e3e",
          marginTop: "15px",
        }}
      >
        {heading}
      </h1>
      <p
        style={{
          textAlign: "center",
          fontWeight: "400",
          color: "#777777",
          fontSize: "15px",
        }}
      >
        {subHeading}
      </p>
    </div>
  );
}
export default IllustrationRequest;
