import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import PrivateRouter from "./components/PrivateRouter";
import AppProvider from "./context/AppProvider";
import { ThemeProvider } from "./context/ThemeContext";
import PageNotFound from "./screens/404";
import AccountCreated from "./screens/AccountCreated";
import EmailSent from "./screens/EmailSent";
import ForgotPass from "./screens/ForgotPass";
import Login from "./screens/Login";
import UpdatedPass from "./screens/PassUpdated";
import ResetPass from "./screens/ResetPass";
import Signup from "./screens/Signup";


const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  max-width: 100vw;
`;

const App = () => {
  let params = new URL(document.location).searchParams;
  let token = params.get("token");
  if (token) {
    localStorage.setItem("finboxToken", token);
    window.location.href = '/home';
  }

  return (
    <AppProvider>
      <Wrapper>
        <ThemeProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/" exact component={Login} />
              {/* <Route path="/signup" exact component={Signup} />
              <Route path="/signup/:email" exact component={Signup} /> */}
              <Route path="/forgotPass" exact component={ForgotPass} />
              <Route path="/resetPass/:token" exact component={ResetPass} />
              <Route path="/accountCreated" exact component={AccountCreated} />
              <Route path="/updatedPass" exact component={UpdatedPass} />
              <Route path="/emailSent" exact component={EmailSent} />
              <PrivateRouter />
              <Route component={PageNotFound} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Wrapper>
    </AppProvider>
  );
};

export default App;
