import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import "spectre.css/dist/spectre.min.css";
import styled from "styled-components";
import "../../node_modules/react-vis/dist/style.css";
import BreadCrumbsNavbar from "../components/BreadCrumbsNavbar";
import {
  humanize,
  VizContainer,
  Main,
  Container,
  VizCard,
  Content,
} from "../components/common";
import Graph from "../components/visualizations";
import RequestAccess from "./RequestAccess";
import AppContext from "../context/AppContext";

function Usage({ match }, props) {
  const redirectToHome = (productName) => {
    if (productName == "device_connect") {
      {
        history.push("/deviceconnect");
      }
    }
    if(history) history.push('/home');
  };

  const [notSubscibed, setNotSubscribed] = useState(false);
  const [productName, setProductName] = useState("");
  const [products, setProducts] = useState([]);
  const [viz, setViz] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("");
  const [dateRangeSubText, setDateRangeSubText] = useState("Last 6 months");
  const [frequencyType, setFrequencyType] = useState("Monthly");
  const [datePeriod, setDatePeriod] = useState("6m");
  const [dateRangeText, setDateRangeText] = useState(
    moment().subtract(6, "months").startOf("month").format("MMM DD") +
      " - " +
      moment().format("MMM DD YYYY")
  );

  const context = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    setProductName(match.params.productName);
    console.log(match.params.productName);
    setCurrentProduct(match.params.productName);
    fetchData(datePeriod, frequencyType, match.params.productName);
  }, [context.stage]);

  const fetchData = async (period, frequency, productName) => {
    let fT = context.token;
    if (fT == null) return;
    const ftData = JSON.parse(fT);
    var myHeaders = new Headers();
    console.log(productName);

    setProducts(ftData.UserData.Products);
    setCurrentProduct(productName);
    myHeaders.append("token", ftData.Token);
    ftData.UserData.Products.includes(productName);
    // var products = ftData.UserData.Products.includes(productName);
    const productobj = JSON.parse(context.productStage);

    console.log(productobj[`${productName}`], context.stage);

    if (!ftData.UserData.Products.includes(productName)) {
      redirectToHome(productName);
    } else if (
      context.stage == "prod" &&
      productobj[`${productName}`] != "prod"
    ) {
      history.push(`/empty/${match.params.productName}`);
    }

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let response;
    let url;
    let startDate;
    var endDate = moment().format("YYYY-MM-DD");
    if (period == "1m") {
      startDate = moment().startOf("month").format("YYYY-MM-DD");
      url = `https://dashboardapi.finbox.in/v1/usage/${productName}?startdate=${startDate}&enddate=${endDate}&frequency=${frequency.toLowerCase()}&stage=${
        context.stage
      }`;
    } else if (period == "1w") {
      startDate = moment().startOf("week").format("YYYY-MM-DD");
      url = `https://dashboardapi.finbox.in/v1/usage/${productName}?startdate=${startDate}&enddate=${endDate}&frequency=${frequency.toLowerCase()}&stage=${
        context.stage
      }`;
    } else if (period == "1d") {
      startDate = moment().format("YYYY-MM-DD");
      url = `https://dashboardapi.finbox.in/v1/usage/${productName}?startdate=${startDate}&enddate=${endDate}&frequency=${frequency.toLowerCase()}&stage=${
        context.stage
      }`;
    } else if (period == "1y") {
      startDate = moment().subtract(1, "years").format("YYYY-MM-DD");
      url = `https://dashboardapi.finbox.in/v1/usage/${productName}?startdate=${startDate}&enddate=${endDate}&frequency=${frequency.toLowerCase()}&stage=${
        context.stage
      }`;
    } else if (period == "6m") {
      startDate = moment().subtract(6, "months").format("YYYY-MM-DD");
      url = `https://dashboardapi.finbox.in/v1/usage/${productName}?startdate=${startDate}&enddate=${endDate}&frequency=${frequency.toLowerCase()}&stage=${
        context.stage
      }`;
    } else {
      startDate = moment()
        .subtract(6, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      url = `https://dashboardapi.finbox.in/v1/usage/${productName}?frequency=${frequency.toLowerCase()}&stage=${
        context.stage
      }`;
    }

    try {
      response = await fetch(url, requestOptions);
      if (response.status == 400) {
        window.location.href = `/home`;
        return;
      }
    } catch (err) {
      // removeToken();
      window.location.href = "/";
      return;
    }
    const resp = await response.text();
    const json = JSON.parse(resp);

    setViz(json.Data.Reports);
    setDatePeriod(period);
    setFrequencyType(frequency);
  };

  const setDateRange = (type, text) => {
    console.log("dateRange", type, text);
    if (type == 0) {
      var fromDate = moment().format("MMM DD");
      fetchData("1d", frequencyType, currentProduct);
    } else if (type == 1) {
      var fromDate = moment().startOf("week").format("MMM DD");
      fetchData("1w", frequencyType, currentProduct);
    } else if (type == 2) {
      var fromDate = moment().startOf("month").format("MMM DD");
      fetchData("1m", frequencyType, currentProduct);
    } else if (type == 3) {
      var fromDate = moment()
        .subtract(6, "months")
        .startOf("month")
        .format("MMM DD");
      fetchData("6m", frequencyType, currentProduct);
    } else if (type == 4) {
      var fromDate = moment().subtract(1, "years").format("MMM DD");
      fetchData("1y", frequencyType, currentProduct);
    } else if (type == 5) {
      fetchData("at", frequencyType, currentProduct);
    }
    var toDate = moment().format("MMM DD YYYY");
    // this.setState({
    //   viz: "",
    //   dateRangeText: type == 5 ? `Till-${toDate}` : fromDate + " - " + toDate,
    //   dateRangeSubText: text,
    //   viz: "",
    // });

    setViz("");
    setDateRangeText(type == 5 ? `Till-${toDate}` : fromDate + " - " + toDate);
    setDateRangeSubText(text);
  };
  const setFrequency = (type) => {
    console.log("Frequency", type);

    setViz("");
    fetchData(datePeriod, type, currentProduct);
  };
  const _renderKeys = (prod) => {
    var activeProd = currentProduct;
    return (
      activeProd && (
        <VizContainer>
          {viz.map((vi, viz_ind) => (
            <VizCard key={viz_ind} product={currentProduct}>
              <Graph
                duration={dateRangeSubText}
                frequencyType={frequencyType.toLowerCase()}
                {...vi}
              />
            </VizCard>
          ))}
        </VizContainer>
      )
    );
  };

  return (
    <>
      <Main style={{ background: "#f9f9f9" }}>
        {currentProduct && (
          <BreadCrumbsNavbar
            loc={[
              {
                name: humanize(currentProduct).replace(" ", ""),
                link: `/${humanize(currentProduct)
                  .replace(" ", "")
                  .toLowerCase()}`,
              },
              {
                name: "Usage ",
                link: `https://dashboard.finbox.in/usage/${productName}`,
              },
            ]}
          />
        )}
        {notSubscibed ? (
          <RequestAccess />
        ) : (
          <Container
            style={{
              height: "fit-content",
            }}
          >
            {viz == "" ? (
              <Shimmer>
                <div
                  style={{
                    height: "60px",
                    width: "100%",
                    // borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    height: "478px",
                    alignSelf: "center",
                    marginTop: "16px",
                    borderRadius: "8px",
                  }}
                />
              </Shimmer>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: 0,
                    alignItems: "end",
                    margin: "0 0 30px 0",
                  }}
                >
                  <div
                    className="dropdown"
                    style={{
                      display: "flex",
                      marginRight: 20,
                      cursor: "pointer",
                      position: "relative",
                      top: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        background: "white",
                        borderRadius: 4,
                        padding: `8px 16px`,
                      }}
                      id="menua"
                      className="dropdown-toggle"
                      tabIndex="0"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: 24,
                        }}
                      >
                        <h6 style={{ fontSize: 12 }}>Frequency</h6>
                        <div style={{ fontSize: 16 }}>{frequencyType}</div>
                      </div>
                      <span style={{ marginLeft: 20 }}>▾</span>
                    </div>
                    <ul className="menu" style={{ left: "inherit", right: 0 }}>
                      <li onClick={() => setFrequency("Daily")}>Daily</li>
                      <li onClick={() => setFrequency("Monthly")}>Monthly</li>
                    </ul>
                  </div>
                  <div
                    className="dropdown"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      position: "relative",
                      top: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        background: "white",
                        borderRadius: 4,
                        padding: `8px 16px`,
                      }}
                      id="menua"
                      className="dropdown-toggle"
                      tabIndex="0"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: 24,
                        }}
                      >
                        <h6 style={{ fontSize: 12 }}>{dateRangeText}</h6>
                        <div style={{ fontSize: 16 }}>{dateRangeSubText}</div>
                      </div>
                      <span style={{ marginLeft: 20 }}>▾</span>
                    </div>
                    <ul className="menu" style={{ left: "inherit", right: 0 }}>
                      <li onClick={() => setDateRange(0, "Today")}>Today</li>
                      <li onClick={() => setDateRange(1, "This week")}>
                        This week
                      </li>
                      <li onClick={() => setDateRange(2, "This month")}>
                        This month
                      </li>
                      <li onClick={() => setDateRange(3, "Last 6 months")}>
                        Last 6 months
                      </li>
                      <li onClick={() => setDateRange(4, "Last year")}>
                        Last year
                      </li>
                      <li onClick={() => setDateRange(5, "All time")}>
                        All time
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <VizContainer> */}
                <div>{_renderKeys(productName)}</div>{" "}
              </>
            )}
          </Container>
        )}
      </Main>
    </>
  );
}

export default Usage;
