import React from "react";
import { BoldHeader, NormalDesc } from "../common";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  margin-top: 150px;
  justify-content: center;
  align-items: center;
`;

function NotificationEmpty(props) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Container>
        <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/ic_empty_notif.svg" />
        <BoldHeader style={{ fontSize: 14, fontWeight: "bold", marginTop: 10 }}>
          No notifications yet
        </BoldHeader>
        <NormalDesc style={{ fontSize: 12, marginTop: 4 }}>
          Stay tuned! Notifications about your activity will show up here
        </NormalDesc>
      </Container>
    </div>
  );
}

export default NotificationEmpty;
