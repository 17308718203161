import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Bold16,
  Container,
  Main,
  ResponsiveContainer,
} from "../components/common";
import EmptyContainer from "../components/EmptyContainer";
import ProductCardDev from "../components/ProductCardDev";
import StartingModal from "../components/StartingModal";
import AppContext from "../context/AppContext";

function DCHome(props) {
  const history = useHistory();
  const context = useContext(AppContext);
  const [profile, setProfile] = useState(null);
  const [trailActivated, setTrailActivated] = useState(false);

  useEffect(() => {
    const resp = context.token;
    if (!resp) return;
    const respJSON = JSON.parse(resp);
    setProfile({
      name: respJSON.UserData.Name,
    });
    // console.log(context.productStage.device_connect);
  }, [context]);

  const startTrial = async () => {
    console.log("Start trial api called !!");
    let fT = context.token;
    const ftData = JSON.parse(fT);
    var myHeaders = new Headers();
    myHeaders.append("token", ftData.Token);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    let response = await fetch(
      "https://dashboardapi.finbox.in/v1/user/starttrial",
      requestOptions
    );
    const result = await response.text();
    const json = JSON.parse(result);
    if (json.Status) {
      setTrailActivated(true);
    } else {
      setTrailActivated(false);
      alert(json.Error);
    }
  };
  // console.log(context.productStage.device_connect);
  return (
    <>
      {context.firstLogin ? (
        <StartingModal
          trailActivated={trailActivated}
          onPrimaryClick={() => startTrial()}
          onSecondaryClick={() =>
            window.open(
              "https://support643206.typeform.com/to/TZ0Joj",
              "_blank"
            )
          }
          onExploreClick={() => window.location.reload()}
          onFailedAction={() =>
            window.open(
              "https://support643206.typeform.com/to/TZ0Joj",
              "_blank"
            )
          }
        />
      ) : (
        ""
      )}
      {profile && context.productStage && (
        <Main> 
          {/* <BreadCrumbsNavbar
            loc={[{ name: "Bank Connect", link: props.location.pathname }]}
          /> */}
          <Container>
            { JSON.parse(context.productStage).device_connect == "dev" ||
            JSON.parse(context.productStage).device_connect == "prod" ? (
              // productStage.device_connect == "dev" ||
              // productStage.device_connect == "prod" ? (
              <>
                <div style={{ margin: "40px 0 30px 0" }}>
                  <Bold16 style={{ fontWeight: 600, fontSize: 22 }}>
                    Hi {profile.name}!<br />
                    Welcome to FinBox DeviceConnect
                  </Bold16>
                </div>
                <ResponsiveContainer>
                  <ProductCardDev
                    src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/Batch+Upload.svg"
                    alt="batch requests"
                    title="Batch Requests"
                    desc="Create a batch request to get score & predictors for users in bulk"
                    buttonText="See All Requests"
                    onClick={() => history.push("deviceconnect/list")}
                  />
                  <ProductCardDev
                    src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/Dashboard.svg"
                    alt="usage"
                    title="Usage"
                    desc="Track your consumed API quota for DeviceConnect. You can also download usage reports across time."
                    buttonText="Track Usage"
                    onClick={() => history.push("/usage/device_connect")}
                  />
                  <ProductCardDev
                    src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/API+Health.svg"
                    title="API Health"
                    desc="Check availability and latency of DeviceConnect API endpoints"
                    buttonText="Check Status"
                    onClick={() => history.push("/deviceconnect/health")}
                  />
                </ResponsiveContainer>
              </>
            ) :  JSON.parse(context.productStage).device_connect == "" ? (
              <EmptyContainer
                heading="DeviceConnect"
                content={
                  <>
                  DeviceConnect enriches mobile device data for customer onboarding, underwriting and collections.
                  <br /><br />
                  Clients can integrate DeviceConnect SDK with their android app and access the data via explicit user consent.
                  </>
                }
                image="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/dcempty.svg"
                link="https://support643206.typeform.com/to/yFcJ9O"
              />
            ) : (
              ""
            )}
            {/* )} */}
          </Container>
        </Main>
      )}
    </>
  );
}
export default DCHome;
