import styled from "styled-components";
import React from "react";
export const Bold18 = styled.span`
  font-weight: 400;
  font-size: 18px;
`;

export const Bold16 = styled.span`
  font-weight: 400;
  font-size: 16px;
`;
export const Bold14 = styled.span`
  font-weight: 400;
  color: #3e3e3e;
  font-size: 14px;
`;
export const Bold12 = styled.span`
  font-weight: 400;
  color: #3e3e3e;
  font-size: 12px;
`;
export const Card = styled.div`
  background: white;
  border-radius: 10px;
  padding: 15px 25px;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 20px;
  }
`;
export const CenterCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 20px;
  flex-direction: column;
`;
const ButtonCore = styled.div`
  background: ${(props) => (props.isGray ? "#eee" : "#24ca7a")};
  display: inline-block;
  border-radius: 2px;
  font-weight: 400;
  font-size: ${(props) => (props.isSmall ? "12px" : "14px")};
  color: ${(props) => (props.isGray ? "#222" : "white")};
  padding: 7px 20px;
`;
export const Button = (props) => {
  return (
    <div
      style={{ marginTop: props.mt || 14, cursor: "pointer" }}
      onClick={() => (window.location.href = props.href)}
    >
      <ButtonCore>
        <span style={{ display: "flex", alignItems: "center" }}>
          {props.children}
        </span>
      </ButtonCore>
    </div>
  );
};
export const TriggerButton = (props) => {
  return (
    <div
      style={{
        marginTop: props.mt || 14,
        marginLeft: props.ml || 0,
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <ButtonCore isGray={props.isGray} isSmall={props.small}>
        <span style={{ display: "flex", alignItems: "center" }}>
          {props.children}
        </span>
      </ButtonCore>
    </div>
  );
};
export const Code = styled.span`
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 6px 16px;
  color: #3e3e3e;
`;

export const Container = styled.div`
  flex-direction: column;
  background: ${(props) => props.theme.main};
  overflow: hidden;
`;

export const InputContainer = styled.input`
  flex: 1;
  height: 32px;
  margin-top: 8px;
  padding: 4px;
  background: #ffffff;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const TextAreaContainer = styled.textarea`
  margin-top: 8px;
  background: #ffffff;
  padding: 4px;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 10px;
`;
export const Upgrade = styled.div``;
export const ContactSales = styled.a`
  font-weight: 700;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  :visited {
    color: #000;
  }
`;

export const TrialDays = styled.span`
  /* font-style: italic; */
`;

export const TrialExpired = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: white;
  /* font-style: italic; */
`;

export const LabelWithInput = (props) => {
  return (
    <>
      <div style={{ marginTop: 15 }}>
        <Bold14>{props.label}</Bold14>
      </div>
      <InputContainer value={props.value} />
    </>
  );
};
