import React, { createContext, useState } from "react";
const ThemeContext = createContext();

export default ThemeContext;

const common = {
  credit: "#34CDEA",
  invalid: "#333",
  debit: "#e7e7f7",
};
const darkTheme = {
  name: "dark",
  main: "#2A2F8C",
  txt: "#fff",
  dull: "#404499",
  pill: "#24CA7A",
  clear: "#3369F4",
  faded: "#9B9EDF",
  fraud: "#7635C8",
  ...common,
};
const lightTheme = {
  name: "light",
  main: "#f9f9f9",
  txt: "#333",
  dull: "#fff",
  pill: "#3E3E3E",
  faded: "#777777",
  clear: "#24CA7A",
  fraud: "#EB5353",
  ...common,
};
export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(lightTheme);

  function toggleTheme() {
    setTheme((theme) => (theme.name === "light" ? darkTheme : lightTheme));
  }
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
