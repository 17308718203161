import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import {
  Bold14,
  ButtonCore,
  Content,
  LinkStyle,
  Main,
} from "../components/common";
import AppContext from "../context/AppContext";
import "./deviceconnect.scss";

var myHeaders = new Headers();
myHeaders.append("content-type", "application/json");
myHeaders.append("Accept", "application/json");

let fileChunckCount;

function NewRequest() {
  const [upload_called, setupload_called] = useState(0);
  const [selectedFile, setselectedFile] = useState(null);
  const [uploadId, setuploadId] = useState("");
  const [request_id, setrequest_id] = useState("");
  const [fileName, setfileName] = useState("");
  const [fileSize, setfileSize] = useState("");
  const [backendUrl, setbackendUrl] = useState("https://dashboardapi.finbox.in/");
  const [fileProgress, setfileProgress] = useState(0);
  const [errorModalVisible, seterrorModalVisible] = useState(false);
  const [APIKey, setAPIKey] = useState("");
  const context = useContext(AppContext);

  const history = useHistory();

  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    onDrop: (files) => {
      let selectedFile = files[0];
      if (!selectedFile) return;

      setselectedFile(selectedFile);
      setfileName(selectedFile.name);
      setfileSize(selectedFile.size);
    },
    noClick: true,
    accept: ".csv",
  });

  useEffect(() => {
    const key = context.deviceConnectApiKey;
    if (key == null || key == "") {
      fetchKey();
    } else {
      setAPIKey(context.deviceConnectApiKey);
    }
  }, []);

  const fetchKey = async () => {
    let fT = context.token;
    const ftData = JSON.parse(fT);

    var myHeaders = new Headers();
    myHeaders.append("token", ftData.Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let response;
    try {
      response = await fetch(
        `https://dashboardapi.finbox.in/v1/developer/device_connect`,
        requestOptions
      );
    } catch (err) {
      context.removeToken();
      window.location.href = "/";
      return;
    }
    const resp = await response.text();
    const json = JSON.parse(resp);

    console.log(json.Data.Env.prod[1].Keys[0].Value);

    if (json.Data.Env.prod) {
      setAPIKey(json.Data.Env.prod[1].Keys[0].Value);
      context.updateDeviceConnectApiKey(json.Data.Env.prod[1].Keys[0].Value);
    } else {
      setAPIKey(json.Data.Env.dev[1].Keys[0].Value);
      context.updateDeviceConnectApiKey(json.Data.Env.dev[1].Keys[0].Value);
    }
  };

  const closemodal = () => {
    // hashHistory.push("/Requests");
    // hashHistory.push({pathname: "/Requests", '123'});
    // this.props.change(0)
  };

  const uploadcomplete = () => {
    history.push("/deviceconnect/list");
  };

  const startUpload = (event) => {
    event.preventDefault();
    performUpload();
  };

  const performUpload = async () => {
    setupload_called(1);
    let token = context.token;
    const parsedToken = JSON.parse(token);
    try {
      if (!token) {
      window.location.href = "/";
    }
      const respJSON = JSON.parse(token);
    var myHeaders = new Headers();
    myHeaders = {
      "content-type": "application/json",
      Accept: "application/json",
      "token": respJSON.Token
    };
     
      var config = {
        headers: myHeaders,
      };

      let resp = await axios.post(
        `${backendUrl}risk_sdk/requests`,
        {
          // TODO handle the filetype here
          // fileType: "csv",
          email: parsedToken.UserData.Email.toString(),
          file_name: fileName,
          user: 1,
        },
        config
      );
	
      if (resp.data?.url) {	
        var config = {	
          method: "PUT",	
          url: resp.data.url,	
          headers: {	
            "Content-Type": "application/json",	
          },	
          data: selectedFile,	
        };	
        // setLoading("loading");	
        axios(config)	
          .then(function (response) {	
            if (response.status == 200) {	
              uploadcomplete();	
              //alert.show('Uploaded Successfully');	
            } else {	
              //alert.show('Failed to Upload');	
            }	
            // setLoading('Not Found');	
          })	
      }
      // console.log(resp.data.uploadId);
      if (resp.data.uploadId) {	
      setuploadId(resp.data.uploadId);
      setrequest_id(resp.data["request_id"]);
      setfileName(resp.data["file_name"]);
      // console.log(resp.data["uploadId"]);
      uploadMultipartFile(
        resp.data.uploadId,
        resp.data.file_name,
        resp.data.request_id
      );
    }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadMultipartFile = async (upload_id, file_name, request_id) => {
    console.log(upload_id);
    try {
      const FILE_CHUNK_SIZE = 10000000; // 10MB
      // const fileSize = selectedFile.size;
      const NUM_CHUNKS = Math.round(fileSize / FILE_CHUNK_SIZE) + 1;
      let promisesArray = [];
      let start, end, blob;
      fileChunckCount = 0;

      // console.log(NUM_CHUNKS);

      for (let index = 1; index < NUM_CHUNKS + 1; index++) {
        // console.log("settt");
        start = (index - 1) * FILE_CHUNK_SIZE;
        end = index * FILE_CHUNK_SIZE;
        blob =
          index < NUM_CHUNKS
            ? selectedFile.slice(start, end)
            : selectedFile.slice(start);
            const resp = context.token;
            if (!resp) {
              window.location.href = "/";
            }

            const respJSON = JSON.parse(resp);
            var myHeaders = new Headers();
            myHeaders = {
              "content-type": "application/json",
              Accept: "application/json",
              "token": respJSON.Token
            };
       
        var config = {
          headers: myHeaders,
        };
        // (1) Generate presigned URL for each part
        let getUploadUrlResp = await axios.post(
          `${backendUrl}risk_sdk/requests/1/get_part_url/`,
          {
            fileName: file_name,
            partNumber: index,
            uploadId: upload_id,
          },
          config
        );

        if ((index / NUM_CHUNKS) * 100 < 20) {
          setfileProgress(parseInt((index / NUM_CHUNKS) * 100));
        }

        let { presignedUrl } = getUploadUrlResp.data;

        // (2) Puts each file part into the storage server
        let uploadResp = axios.put(presignedUrl, blob, {
          headers: { "Content-Type": selectedFile.type },
        });
        uploadResp.then((response) => {
          fileChunckCount++;
          if ((fileChunckCount / NUM_CHUNKS) * 100 > 20) {
            setfileProgress(parseInt((fileChunckCount / NUM_CHUNKS) * 100));
          }

          // if(fileProgress === 100){this.closemodal()}
        });
        promisesArray.push(uploadResp);
      }

      let resolvedArray = await Promise.all(promisesArray);

      let uploadPartsArray = [];
      resolvedArray.forEach((resolvedPromise, index) => {
        uploadPartsArray.push({
          ETag: resolvedPromise.headers.etag,
          PartNumber: index + 1,
        });
      });

      // (3) Calls the CompleteMultipartUpload endpoint in the backend server
      console.log(upload_id);
      const resp = context.token;
    if (!resp) {
      window.location.href = "/";
    }
    const respJSON = JSON.parse(resp);
    var myHeaders = new Headers();
    myHeaders = {
      "content-type": "application/json",
      Accept: "application/json",
      "token": respJSON.Token
    };
      
      var config = {
        headers: myHeaders,
      };
      let completeUploadResp = await axios.post(
        `${backendUrl}risk_sdk/requests/${request_id}/complete_upload/`,
        {
          fileName: file_name,
          parts: uploadPartsArray,
          uploadId: upload_id,
        },
        config
      );
      let completeapi = completeUploadResp.data;
      if (completeapi["statusCode"] === 200) {
        uploadcomplete();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const closemodal1 = () => {
  //   setState({ modalVisible: false });
  //   var modal = document.getElementById("myModal1");
  //   modal.style.display = "none";
  // };

  return (
    <Main>
      <div className="container">
        <div className="mainContainer">
          {/* <div style={{ marginTop: 20, marginBottom: 20 }} className="secondCon"> */}
          <div className="stepper">
            <Stepper
              steps={[
                { title: "Create" },
                { title: "Upload" },
                { title: "Process" },
                { title: "Complete" },
              ]}
              size={20}
              activeStep={upload_called === 0 ? 0 : 1}
              activeColor="#ffffff"
              completeColor="#24ca7a"
              activeBorderColor="#24ca7a"
              defaultBorderStyle="solid"
              completeBarColor="#24ca7a"
              activeBorderStyle="solid"
              activeTitleColor="#24ca7a"
              completeTitleColor="#3e3e3e"
              defaultTitleColor="#3e3e3e"
              circleFontSize="0"
            />
          </div>

          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              flexGrow: 1,
              background: "white",
            }}
          >
            {upload_called === 0 ? (
              <>
                <div
                  style={{
                    flexGrow: "1",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <h3>Upload User IDs</h3>
                  {fileName.length > 0 ? (
                    <section
                      className="centerColumn"
                      style={{
                        display: "flex",
                        flexGrow: "1",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className="dropzone"
                        style={{
                          padding: "10%",
                          justifyContent: "space-around",
                        }}
                      >
                        <input {...getInputProps()} />
                        <span style={{ fontSize: "18px", fontWeight: "400" }}>
                          {fileName}
                        </span>
                        {upload_called === 0 ? (
                          <div style={{ marginTop: "25px" }}>
                            {fileName.length > 0 ? (
                              <ButtonCore onClick={(e) => startUpload(e)}>
                                Upload
                              </ButtonCore>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </section>
                  ) : (
                    <section
                      className="centerColumn"
                      style={{
                        flexGrow: "1",
                      }}
                    >
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className="dropzone"
                        accept=".csv"
                      >
                        <div className="centerColumn">
                          <input {...getInputProps()} />
                          <div style={{ fontWeight: "700", fontSize: "18px" }}>
                            Drag and Drop
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              margin: "15px",
                            }}
                          >
                            OR
                          </div>
                          <div
                            style={{
                              background: "#E9E9EA",
                              padding: "8px 16px",
                              borderRadius: "2px",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "#777777",
                              cursor: "pointer",
                            }}
                            onClick={open}
                          >
                            <img
                              src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/upload_24px.svg"
                              style={{ marginRight: "8px" }}
                            />{" "}
                            Choose a file
                          </div>
                          <div
                            style={{
                              display: "flex",
                              textAlign: "center",
                              margin: "18px",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: " #777777",
                            }}
                          >
                            to upload the list of customer IDs for preocessing.
                          </div>
                          <div>
                            *Only .csv and .xls file formats are supported.
                          </div>
                        </div>{" "}
                      </div>
                    </section>
                  )}
                </div>
                <Content style={{ margin: "30px" }}>
                  <Bold14 style={{ fontSize: 18 }}>
                    We have updated the sample csv format.
                  </Bold14>
                  <Bold14
                    style={{
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    Please find the document sample below.
                  </Bold14>
                  <div style={{ display: "flex" }}>
                    <LinkStyle
                      as="a"
                      download
                      href="https://s3.ap-south-1.amazonaws.com/finbox-cdn/sample_finbox_input.csv"
                      style={{ fontSize: 12 }}
                    >
                      Download sample statement
                    </LinkStyle>
                  </div>
                </Content>
              </>
            ) : (
              <div className="uploading">
                <div className="uploadingBanner">
                  <b
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Uploading Public IDs
                  </b>
                  <span style={{ fontWeight: "300" }}>1 file uploading</span>
                </div>

                <div style={{ display: "flex", width: "100%" }}>
                  <img src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/csv.svg" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "20px",
                      padding: "4px",
                      flexGrow: 1,
                    }}
                  >
                    <span>{fileName}</span>
                    <div className="bar" style={{ background: "#D5ECF9" }}>
                      <div
                        className="bar-item tooltip"
                        data-tooltip={fileProgress + "%"}
                        style={{
                          width: fileProgress + "%",
                          backgroundColor: "#24ca7a",
                        }}
                      >
                        {fileProgress} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Main>
  );
}
export default NewRequest;
