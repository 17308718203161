import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { BoldHeader, ButtonCore, NormalDesc } from "../components/common";

const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
`;

function RequestEmptyState(props) {
  const [showBankConnect, setShowBankConnect] = useState(false);
  const history = useHistory();

  return (
    <div>
      <Main>
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/dc-empty-state.svg"
          height={200}
        />
        <TextContainer>
          <BoldHeader>Create your first Batch Request</BoldHeader>
          <NormalDesc style={{ marginTop: 10 }}>
            Upload a CSV file to make the frst Batch Request.
          </NormalDesc>
          <ButtonCore
            style={{ marginTop: 30 }}
            onClick={() => history.push("./newrequest")}
          >
            Upload Now
          </ButtonCore>
        </TextContainer>
      </Main>
    </div>
  );
}

export default RequestEmptyState;
