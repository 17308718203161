import React, { useContext } from "react";
import "spectre.css/dist/spectre.min.css";
import { FiUpload, FiCheck, FiX } from "react-icons/fi";
import styled from "styled-components";
import ThemeContext from "../context/ThemeContext";
import { ButtonCore } from "../components/common";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Label = styled.div`
  font-size: 14;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const Input = styled.input`
  padding: 0.6em 1em;
  font-size: 0.9em;
  margin-top: 0.7em;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;
`;

function ForgotPass() {
  const [email, setEmail] = React.useState(null);

  const history = useHistory();

  const handleSubmit = async (event, email) => {
    console.log(event);
    event.preventDefault();

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      `https://dashboardapi.finbox.in/v1/user/resetrequest/${email}`,
      requestOptions
    );
    history.push("/emailSent");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#F7FAFD",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flexStart",
          paddingTop: "80px",
          paddingLeft: "80px",
          width: "100%",
        }}
      >
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/finbox.svg"
          className="App-logo"
          alt="logo"
        />
      </div>
      <div style={{ width: "25%", marginTop: "60px" }}>
        <h2 style={{ fontWeight: "600", fontSize: "24", color: "#3e3e3e" }}>
          Reset Password
        </h2>

        <form style={{ marginTop: "10px" }}>
          <Label>
            <text style={{ fontWeight: "550" }}>Email</text>
            <Input
              type="text"
              name="email"
              placeholder="Enter your user email ID"
              onChange={(event) => setEmail(event.target.value)}
            ></Input>
          </Label>

          <div
            style={{
              display: "flex",
              alignItems: " center",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <ButtonCore onClick={(event) => handleSubmit(event, email)}>
              Send Password Reset Link
            </ButtonCore>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPass;
