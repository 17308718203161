import React, { useContext, useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import BreadCrumbsNavbar from "../components/BreadCrumbsNavbar";
import { Main } from "../components/common";
import AppContext from "../context/AppContext";
import "./deviceconnect.scss";
import IllustrationRequest from "./IllustrationRequest";
import RequestDetailTable from "./RequestDetailTable";

var myHeaders = new Headers();

function Requestdetails({ match }, props) {
	const [data, setData] = useState({});
	const [totalRows, setTotalRows] = useState("");
	const [status, setStatus] = useState(0);
	const [stepper, setStepper] = useState(0);
	const [userID, setuserID] = useState("");
	const context = useContext(AppContext);
	let userId;

	useEffect(() => {
		userId = match.params.userId;
		userId && setuserID(userId);
		apicall();
	}, []);

	const apicall = () => {
		const key = context.deviceConnectApiKey;
	const resp = context.token;
	if (!resp) {
		window.location.href = "/";
	}
	const respJSON = JSON.parse(resp);
    var myHeaders = new Headers();
    myHeaders = {
      "content-type": "application/json",
      Accept: "application/json",
      "token": respJSON.Token
    };
		
		var myInit100 = {
			method: "Get",
			headers: myHeaders,
			cache: "default",
		};
		fetch("https://dashboardapi.finbox.in/risk_sdk/requests/" + match.params.userId, myInit100)
			// .then(handleErrors)
			.then((response) => response.json())
			.catch(function (error) {
				// if (error.message === "Unauthorized") {
				//   logout();
				// }
				alert(error.message);
			})
			.then((result) => {
				setData(result);
				if (
					(result["status"] === "validation_complete") |
					(result["status"] === "processing_in_progress") |
					(result["status"] === "processing_requested")
				) {
					setStatus(3);
					setStepper(2);
				} else if (result["status"] === "processing_complete") {
					setStatus(4);
					setStepper(4);
				} else if (result["status"] === "aborted") {
					setStatus(2);
					setStepper(1);
				} else if (result["status"] === "validating") {
					setStatus(1);
					setStepper(1);
				}

				const total_rows = result.invalid_rows
					? result.invalid_rows + result.valid_rows
					: result.valid_rows;

				setTotalRows(total_rows);
			});
	};

	return (
		<Main>
			<BreadCrumbsNavbar
				color="blue"
				loc={[
					{ name: "DeviceConnect", link: "/deviceconnect" },
					{ name: "Requests", link: "/deviceconnect/list" },
					{
						name: match.params.userId,
						link: "/deviceconnect/requestdetails/" + match.params.userId,
					},
				]}
			/>
			<div className="container" style={{ overflowY: "scroll" }}>
				<div className="mainContainer" style={{ paddingBottom: "25px", height: "fit-content" }}>
					<div className="stepper">
						<Stepper
							steps={[
								{ title: "Create" },
								{ title: "Upload" },
								{ title: "Process" },
								{ title: "Complete" },
							]}
							size={20}
							activeStep={stepper}
							activeColor="#ffffff"
							completeColor="#24ca7a"
							activeBorderColor="#24ca7a"
							defaultBorderStyle="solid"
							completeBarColor="#24ca7a"
							activeBorderStyle="solid"
							activeTitleColor="#24ca7a"
							completeTitleColor="#3e3e3e"
							defaultTitleColor="#3e3e3e"
							circleFontSize="0"
						/>
					</div>
					<div className="requestID">
						Request ID: <span style={{ color: "#3e3e3e", fontWeight: "700" }}>{userID}</span>
					</div>
					{status == 3 ? (
						<>
							<IllustrationRequest
								imgsrc="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/19576-isometric-loader-1.gif"
								heading="We are processing your file"
								subHeading={
									"You have uploaded " +
									totalRows +
									" users." +
									(totalRows != data.valid_rows
										? " We have started processing the data for " +
										  data.valid_rows +
										  " valid users."
										: " All users were in your file were valid.") +
									" Sit back and relax, we are processing the data for these users. You’ll get an email once processing is complete."
								}
							/>
							{/* <div
                style={{
                  overflowX: "scroll",
                  height: "fit-content",
                  marginTop: "20px",
                }} 
              > */}
							<RequestDetailTable
								id={data.request_id}
								customers={data.valid_rows}
								invalidRows={data.invalid_rows}
								createdAt={data.created_at}
								status="Processing"
								validatedLink={data.validated_csv_s3_url}
								invalidLink={data.invalid_csv_s3_url}
							/>
							{/* </div> */}
						</>
					) : status == 4 ? (
						<>
							<IllustrationRequest
								imgsrc="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/completed.svg"
								heading="Your request has been processed"
								subHeading={
									"We have processed features for " +
									data.processed_rows +
									" out of " +
									totalRows +
									" customers. Go ahead and download the excel file."
								}
							></IllustrationRequest>
							{/* <div
                style={{
                  overflowX: "scroll",
                  height: "fit-content",
                  marginTop: "20px",
                }}
              > */}
							<RequestDetailTable
								id={data.request_id}
								customers={data.valid_rows}
								completed={data.processed_rows}
								createdAt={data.created_at}
								invalidRows={data.invalid_rows}
								completedAt={data.processing_completed_at}
								validatedLink={data.validated_csv_s3_url}
								invalidLink={data.invalid_csv_s3_url}
								outputLink={data.output_csv_s3_url}
								status="Completed"
							/>
							{/* </div> */}
						</>
					) : (
						""
					)}
				</div>
			</div>
		</Main>
	);
}
export default Requestdetails;
