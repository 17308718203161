import React from "react";
import styled from "styled-components";
import { BoldHeader, NormalDesc } from "../components/common";

const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  border-radius: 4px;
  background-color: white;
  display: flex;
  padding: 16px;
  border-radius: 12px 0px 0px 0px;
  flex-direction: column;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
`;

const CreateNewButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  background-color: #24ca7a;
  color: white;
  border-radius: 2px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  &:hover {
    background-color: #139e66;
  }
`;

const LinkStyle = styled.button`
  display: flex;
  background: none;
  font-weight: 400;
  align-items: center;
  font-size: 14px;
  margin: 15px 0 3px 0;
  color: #24ca7a;
  cursor: pointer;
  :hover {
    color: #24ca7a;
    text-decoration: underline;
  }
  :link {
    color: #24ca7a;
    /* text-decoration: underline; */
  }
  :visited {
    color: #24ca7a;
    /* text-decoration: underline; */
  }
  :active {
    color: #24ca7a;
    /* text-decoration: underline; */
  }
`;

function RequestAccess(props) {
  return (
    <div>
      <Main>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/bc-empty-state.svg"
            height={200}
            style={{
              marginTop: 60,
            }}
          />
          <TextContainer>
            <BoldHeader>Not Subscribed to product</BoldHeader>
            <NormalDesc style={{ marginTop: 10 }}>
              Please contact sales to get subscription.
            </NormalDesc>

            <CreateNewButton
              style={{ marginTop: 30 }}
              onClick={(e) =>
                window.open(
                  "https://support643206.typeform.com/to/yFcJ9O",
                  "_blank"
                )
              }
            >
              Contact Sales
            </CreateNewButton>
          </TextContainer>
        </div>
      </Main>
    </div>
  );
}

export default RequestAccess;
