import React, { useState } from "react";
import {
  ProductText
} from "./common";
import ArrowRight from "../images/dchome/arrowRight.svg"
function ProductCardProd(props) {
  const [showIcon, setShowIcon] = useState(false);

  return (
    <div style={{width: "300px"}} className="product-card-hover" onClick={props.onClick} onMouseOver={() => setShowIcon(true)} onMouseOut={() => setShowIcon(false)}>
      <div>
        <div>
          <div style={{height: "130px"}}>
          <img src={props.src} height={120} style={{ marginTop: "32px", marginLeft: "auto", marginRight: "auto", display: "block" }} />
          </div>
          <ProductText style={{ margin: 16, marginTop: 0}}>
            <div
            className="product-title"
              style={{
                fontWeight: 600,
                fontSize: 20,
                marginTop: 16,
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex"
              }}
            >
              {props.title}
              <div className="product-icon-wrapper" style={{marginTop: "-3px"}}>
                <img className="product-icon" src={ArrowRight} />
              </div>
            </div>
            <div
              style={{
                color: "#777777",
                fontSize: 16,
                marginTop: 16,
                fontWeight: "400",
                textAlign: "center"
              }}
            > 
              {props.desc}
            </div>
          </ProductText>
        </div>
      </div>
    </div>
  );
}

export default ProductCardProd;
