import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import ThemeContext from "../context/ThemeContext";
import Shimmer from "react-shimmer-effect";
import classNames from "classnames";
import "./TabNavigation.scss";

const TabBody = styled.div`
	/* height: 100%; */
	/* padding: 32px; */
	margin-top: 15px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 12px 0px 0px 0px;
`;

const TabButton = styled.button`
	color: ${(props) => props.theme.txt};
`;

const TabWrapper = styled.div`
	/* padding-left: 30px; */
	margin: 0 -25px;
	padding: 0 25px;
	background: white;
	transition: 0.2s all ease-in;
	border-bottom: 1px solid #ececec;
`;
export const Tabs = (props) => {
	// const [activeIndex, setActiveIndex] = useState(0);
	const { theme } = useContext(ThemeContext);
	// console.log(props);
	let tabs = React.Children.map(props.children, (child, index) => {
		return (
			<TabButton
				theme={theme}
				className={classNames("tab-label", {
					"tab-label-active": props.activeIndex === index,
					"tab-dark": theme.name === "dark",
				})}
				onClick={() => {
					props.onTabChange && props.onTabChange(child.props.label);
					props.setActiveIndex(index);
				}}
			>
				{child.props.label}
			</TabButton>
		);
	});

	return (
		<>
			<TabWrapper demo={props.demo} theme={theme}>
				{tabs}
			</TabWrapper>
			{props.children[props.activeIndex]}
		</>
	);
};
export const Tab = (props) => {
	const { theme } = useContext(ThemeContext);
	// console.log("Tab props childern", props.children);
	return props.children == null ? (
		<div
			style={{
				border: "0px solid rgba(255, 255, 255, 1)",
				boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
				borderRadius: "4px",
				backgroundColor: "#f4f8fc",
				display: "flex",
				padding: "16px",
				flexDirection: "column",
				width: "100%",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Shimmer>
				<div
					style={{
						width: "92%",
						height: "100px",
						alignSelf: "center",
						marginTop: "16px",
						borderRadius: "8px",
					}}
				/>
				<div
					style={{
						width: "92%",
						height: "200px",
						alignSelf: "center",
						marginTop: "16px",
						borderRadius: "8px",
					}}
				/>
			</Shimmer>
		</div>
	) : (
		<TabBody demo={props.demo} theme={theme}>
			{props.children}
		</TabBody>
	);
};
