import React, { useContext } from "react";
import "spectre.css/dist/spectre.min.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: #24ca7a;
  font-size: 12;
  margin-top: 15px;
  &:visited {
    color: #24ca7a;
  }
`;
const ContainIMG = styled.div`
  display: flex;
  align-items: flexStart;
  padding-top: 80px;
  padding-left: 80px;
  width: 100%;
  @media (max-width: 786px) {
    padding-left: 5%;
    padding-top: 5%;
  }
`;
const Contain = styled.div`
  width: 25%;
  margin-top: 60px;
  text-align: center;
  @media (max-width: 786px) {
    width: 90%;
  }
`;

function AccountCreated() {
  const history = useHistory();

  function handleChange(evt) {
    const value = evt.target.value;
    setState({ ...state, [evt.target.name]: value });
  }

  const [state, setState] = React.useState({
    userName: "",
    pass: "",
    token: "",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#F7FAFD",
        alignItems: "center",
      }}
    >
      <ContainIMG>
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/finbox.svg"
          className="App-logo"
          alt="logo"
        />
      </ContainIMG>

      <Contain>
        <img
          src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/account.svg"
          alt="picture"
          width="300"
        />
        <h2
          style={{
            fontWeight: "600",
            fontSize: "24",
            color: "#3e3e3e",
            marginTop: "40px",
          }}
        >
          Account Created
        </h2>
        <p
          style={{
            fontWeight: "400",
            fontSize: "14",
            color: "#777777",
            marginTop: "20px",
          }}
        >
          Your account has been created successfully.
        </p>
      </Contain>
      <StyledLink onClick={() => history.push("/")}>
        Click here to Login
      </StyledLink>
    </div>
  );
}

export default AccountCreated;
