import React, { useState } from "react";
import {
  AreaSeries,
  MarkSeries,
  HorizontalGridLines,
  GradientDefs,
  VerticalGridLines,
  XAxis,
  XYPlot,
  makeVisFlexible,
  YAxis,
  Crosshair,
} from "react-vis";
import Moment from "react-moment";
import moment from "moment";
const Flexible = makeVisFlexible(XYPlot);
export default (props) => {
  const DATA = props.data.map((elem) => {
    return { ...elem, x: new Date(elem.x) };
  });

  const max = Math.max(...DATA.map((v) => v.y));

  const [crosshairValues, setCrosshairValues] = useState([]);

  const _onNearestX = (value, { index }) => {
    setCrosshairValues([value]);
  };
  const _onMouseLeave = () => {
    setCrosshairValues([]);
  };
  const trimSet = (arr) => {
    const doneM = [];
    return arr.filter(function (v) {
      if (doneM.includes(moment(v).format("MM-YYYY"))) return false;
      else {
        doneM.push(moment(v).format("MM-YYYY"));
        return true;
      }
    });
  };

  return (
    <Flexible
      className="viz-offset"
      height={270}
      margin={{ left: 60, right: 40, bottom: 70, top: 40 }}
      stackBy="y"
      xType="time"
      yDomain={[0, max]}
      onMouseLeave={_onMouseLeave}
      tickFormat
    >
      <GradientDefs>
        <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1.5">
          <stop offset="0%" stopColor="#00b6ff" stopOpacity={0.24} />
        </linearGradient>
      </GradientDefs>

      <HorizontalGridLines />

      {!props.hideXAxis && (
        <XAxis
          tickFormat={(v) => moment(v).format(props.format)}
          tickValues={trimSet(DATA.map((d) => d.x))}
          tickLabelAngle={-90}
        />
      )}

      <YAxis />
      <MarkSeries data={DATA} color="url(#CoolGradient)" size="2" />
      <AreaSeries
        onNearestX={_onNearestX}
        color="url(#CoolGradient)"
        data={DATA}
      />
      <Crosshair values={crosshairValues}>
        {crosshairValues[0] && (
          <div className="label-wrapper">
            <div className="label-header">
              <Moment format={props.format}>{crosshairValues[0].x}</Moment>
            </div>
            <div className="label-title">{props.title}</div>
            <div className="label-value">{parseInt(crosshairValues[0].y)}</div>
          </div>
        )}
      </Crosshair>
    </Flexible>
  );
};
