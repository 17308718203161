import React, { useContext, useState, useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import "spectre.css/dist/spectre.min.css";
import styled from "styled-components";
import "react-dropdown/style.css";
import Divider from "@material-ui/core/Divider";
import {
  Bold16,
  ScrollingCard,
  Code,
  humanize,
  dehumanize,
  LinkStyle,
  DeveloperContainer,
  SwitchDeveloper,
} from "../components/common";
import Showhide from "../components/Showhide";
import { Tab, Tabs } from "../components/TabNavigation";
import "./Developers.scss";
import AppContext from "../context/AppContext";

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
`;
function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}
const ProductKeys = (props) => {
  const [save, setSave] = useState(false);
  const [apifail, setApiFail] = useState(false);

  useEffect(() => {
    if (save)
      setTimeout(() => {
        setSave(false);
      }, 3000);
    if (apifail)
      setTimeout(() => {
        setApiFail(false);
      }, 3000);
  }, [apifail, save]);
  var obj = props.tabs.find((tab) => tab.name === props.product);

  const [env, setEnv] = useState("dev");

  return obj ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Bold16>Integration Keys ({humanize(obj.name)}) </Bold16>

        <div>
          {/* <label className="form-switch">
            <input
              onChange={() => setEnv(env === "dev" ? "prod" : "dev")}
              type="checkbox"
              checked={env === "prod"}
            />
            <i className="form-icon"></i>{" "}
            {env == "prod"
              ? "Showing Prod Credentials"
              : env == "dev"
              ? "Showing Dev Credentials"
              : ""}
          </label> */}
        </div>
      </div>
      <p style={{ color: "#777777", fontWeight: "400", marginTop: "5px" }}>
        Developers can copy the below Integration keys and use them in their
        workflow. For security reasons, keys are hidden by default.
      </p>

      {Object.entries(obj.env).map((envBlock, i) => (
        <div key={i}>
          {env === envBlock[0] &&
            envBlock[1].map((keyBlock, keyIndex) => (
              <div key={keyIndex}>
                <div className="mt15 mb10">
                  <Bold16>{keyBlock.Category || "Default"}</Bold16>
                </div>
                <div className="columns" style={{ flexDirection: "column" }}>
                  {keyBlock.Keys.map((apiKeyEntry, id) => (
                    <RowContainer key={id}>
                      <div className="column col-4 mt5 mb5">
                        <Code>{apiKeyEntry.Name}</Code>
                      </div>
                      <div className="column col-6 mt5 mb5">
                        <Showhide password={apiKeyEntry.Value}></Showhide>
                      </div>
                    </RowContainer>
                  ))}
                </div>
              </div>
            ))}
        </div>
      ))}
      <Divider />
      <Bold16>Resources</Bold16>
      <div></div>
      <LinkStyle as="a" href={obj.documentation} target="_blank">
        {humanize(obj.name)} Docs <FiExternalLink style={{ marginLeft: 5 }} />
      </LinkStyle>
      {obj.postmanLink && (
        <LinkStyle as="a" href={obj.postmanLink} target="_blank">
          Postman Collection <FiExternalLink style={{ marginLeft: 5 }} />
        </LinkStyle>
      )}
    </div>
  ) : null;
};

const Developers = () => {
  const context = useContext(AppContext);
  const [currentProduct, setCurrentProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    callAPIs();
  }, []);

  const callAPIs = async () => {
    let fT = context.token;
    const ftData = JSON.parse(fT);

    if(ftData.UserData.Products?.includes("device_connect")){
    setCurrentProduct("device_connect");
    setProducts(["device_connect"]);
    }

    var myHeaders = new Headers();
    myHeaders.append("token", ftData.Token);
    ftData.UserData.Products.map(async (product) => {
      if(product === "bank_connect")
      return;
      console.log("Doing it for", product);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      let response;
      try {
        response = await fetch(
          `https://dashboardapi.finbox.in/v1/developer/${product}`,
          requestOptions
        );
      } catch (err) {
        localStorage.removeItem("finboxToken");
        window.location.href = "/";
        return;
      }
      const resp = await response.text();
      const json = JSON.parse(resp);

      setTabs((tabs) => [
        ...tabs,
        {
          name: product,
          documentation: json.Data.DocLink,
          postmanLink: json.Data.PostmanLink,
          env: json.Data.Env,
        },
      ]);
    });
  };

  const onTabChange = (v) => {
    console.log("Tab changed", v);
    setCurrentProduct(dehumanize(v));
  };

  console.log(tabs);
  return (
    <>
      <ScrollingCard style={{ paddingTop: "0", height: "97%" }}>
        <Tabs
          defaultIndex={0}
          onTabChange={onTabChange}
          setActiveIndex={setTabIndex}
          activeIndex={tabIndex}
        >
          {products.map((product, i) => {
            return (
              <Tab key={i} label={humanize(product)}>
                <ProductKeys tabs={tabs} product={product} context={context} />
              </Tab>
            );
          })}
        </Tabs>
      </ScrollingCard>
    </>
  );
};
export default Developers;
