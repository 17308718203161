import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContext";
import { FiX } from "react-icons/fi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import styled from "styled-components";
import { DeveloperContainer, SwitchDeveloper, SwitchMaster, initializeName } from "./common";
import LoadingSwitch from "./LoadingSwitch";

import "./NavSideBar.scss";

const Topbar = styled.div`
	display: flex;
	flex-direction: column;
	align-items: baseline;
	background: #0a1622;
	color: white;
	padding: 1.2rem 1rem 1.2rem 1.5rem;
`;
const ProfileBar = styled.div`
	display: flex;
	align-items: top;
	width: 100%;
	justify-content: space-between;
	box-shadow: none;
	border: none;
	outline: none;
	margin-bottom: 20px;
`;
const BottomArea = styled.div`
	display: flex;
	flex-direction: column;
	margin: 1.5rem;
`;

const NavSideBar = (props) => {
	const context = useContext(AppContext);
	const [seconds, setSeconds] = useState(0);
	const [productStage, setProductStage] = useState(false);
	const [profile, setProfile] = useState(null);

	useEffect(() => {
		const resp = context.token;
		if (!resp) return;
		const respJSON = JSON.parse(resp);
		setProfile({
			name: respJSON.UserData.Name,
			company: respJSON.UserData.CompanyName,
		});
		isTrial();
		console.log(productStage);
	}, [context]);

	const isTrial = async () => {
		let fT = context.token;
		const ftData = JSON.parse(fT);
		var myHeaders = new Headers();
		myHeaders.append("token", ftData.Token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let response = await fetch("https://dashboardapi.finbox.in/v1/user/config", requestOptions);
		const result = await response.text();
		const json = JSON.parse(result);
		if (json.Status) {
			setProductStage(
				json.Data.ProductWiseStage.bank_connect == "prod" ||
					json.Data.ProductWiseStage.device_connect == "prod"
			);
		}
	};

	const timer = () => {
		var sec = 5;
		setSeconds(sec);
		var timer = setInterval(function () {
			console.log(sec);
			sec--;
			setSeconds(sec);
			if (sec < 0) {
				clearInterval(timer);
			}
		}, 1000);
	};

	const logout = async () => {
		var myHeaders = new Headers();
		const resp = context.token;
		myHeaders.append("token", JSON.parse(resp).Token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		await fetch("https://dashboardapi.finbox.in/v1/user/logout", requestOptions);
		context.removeToken();
		window.location.href = "/";
	};

	let sideBarClasses = "nav-side-bar";
	if (props.show) {
		sideBarClasses = "nav-side-bar open";
	}
	return (
		<>
			<nav className={sideBarClasses}>
				<Topbar>
					<ProfileBar>
						{profile && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									boxShadow: "none",
									border: "none",
									outline: "none",
								}}
							>
								<figure
									className="avatar"
									data-initial={initializeName(profile.name || "")}
									style={{
										backgroundColor: "#11A1F1",
										minWidth: "1.8rem",
										minHeight: "1.8rem",
									}}
								></figure>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										marginLeft: 15,
									}}
								>
									<h6
										style={{
											margin: 0,
											color: "#3white",
											fontWeight: "500",
											fontSize: "16px",
										}}
									>
										{profile.name}
									</h6>
									<h6
										style={{
											margin: 0,
											fontSize: "14px",
											color: "white",
											fontWeight: "normal",
										}}
									>
										{profile.company}
									</h6>
								</div>
							</div>
						)}
						<div className="nav-side-bar-close" onClick={props.anchorClickHandler}>
							<IoIosCloseCircleOutline />
						</div>
					</ProfileBar>
					{productStage && (
						<>
							Account Mode
							<DeveloperContainer style={{ marginTop: "5px" }}>
								<SwitchMaster
									onClick={() => {
										context.updateStage("dev");
										props.anchorClickHandler();
										timer();
									}}
									stage={context.stage}
								>
									Dev
								</SwitchMaster>
								<SwitchMaster
									onClick={() => {
										context.updateStage("prod");
										props.anchorClickHandler();
										timer();
									}}
									stage={context.stage}
								>
									Prod
								</SwitchMaster>
							</DeveloperContainer>
						</>
					)}
				</Topbar>
				<BottomArea>
					<ul className="firstList">
						<li>
							<Link to="/home" onClick={props.anchorClickHandler} className="alignCenter">
								<img
									src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/account_balance_24px_rounded.svg"
									alt=""
								/>{" "}
								Home
							</Link>
						</li>
						<li>
							<Link
								to="/deviceconnect"
								onClick={props.anchorClickHandler}
								className="alignCenter "
							>
								<img
									src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/store_mall_directory_24px_rounded.svg"
									alt=""
								/>{" "}
								DeviceConnect
							</Link>
						</li>
					</ul>
					<div className="sidebarLine" />
					<ul className="lastList">
						<li>
							<Link
								onClick={() => window.open("https://docs.finbox.in", "blank")}
								className="alignCenter "
							>
								<img
									src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/description_24px_rounded.svg"
									alt=""
								/>{" "}
								Documentation
							</Link>
						</li>
						<li>
							<Link to="#" onClick={logout} className="alignCenter ">
								<img
									src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/dashboard/save_alt_24pxgrey.svg"
									alt=""
								/>{" "}
								Log Out
							</Link>
						</li>
					</ul>
				</BottomArea>
			</nav>
			{seconds > 0 ? <LoadingSwitch seconds={seconds} prod={context.stage} /> : ""}
		</>
	);
};

export default NavSideBar;
