import moment from "moment";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { exportCSVFile } from "../common"
import { FiMaximize2 } from "react-icons/fi";
export default class MultipleTabular extends Component {
  state = {
    isModalShowing: false
  };
  onClick = () => {
    this.setState({
      isModalShowing: true
    });
  };
  closeModal = () => {
    this.setState({
      isModalShowing: false
    });
  };
  renderRows = (data) => {
    const points = data[0];
    return points.map((point, pointIndex) => {
      return <tr key={pointIndex}>
        <td>{moment(point.X).format(this.props.tabularFormat)}</td>
        {data.map(service => <td>{service[pointIndex].Y}</td>)}
      </tr>
    });
  };
  getRowData = (data) => {
    const points = data[0]
    return points.map((point, pointIndex) =>
      ({
        [data.length]: moment(point.X).format(this.props.tabularFormat),
        ...data.map((service, i) => (service[pointIndex].Y)),
      })
    )
  }
  getHeaders = (props) => {
    return ({
      [props.items.length]: "Date",
      ...props.items.map((item) => item)
    })
  }

  render() {

    return (
      <div>
        <div className="flex-table-actions">
          {/* <div className="close-wrapper tabular" onClick={this.props.toggleTabular}>← Close</div> */}
          <div />
          <div className="flex-table-actions__right">
            {/* <FiDownload onClick={this.download} /> */}
            <FiMaximize2 onClick={this.onClick} />
          </div>
        </div>
        <div className="scrollable">
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                {this.props.items.map((item, i) => <th key={i}>{item}</th>)}
              </tr>
            </thead>
            <tbody>{this.renderRows(this.props.data)}</tbody>
          </table>
        </div>

        <div
          className={`modal ${this.state.isModalShowing && "active"}`}
          id="modal-id"
        >
          <a
            onClick={this.closeModal}
            className="modal-overlay"
            aria-label="Close"
          ></a>

          <div className="modal-container">
            <div className="modal-header">
              <a
                onClick={this.closeModal}
                className="btn btn-clear float-right"
                aria-label="Close"
              ></a>
              <div className="modal-title h5">Tabular View</div>
            </div>
            <div className="modal-body">
              <div className="scrollable-large">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      {this.props.items.map((item, i) => <th key={i}>{item}</th>)}
                    </tr>
                  </thead>
                  <tbody>{this.renderRows(this.props.data)}</tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button className="btn btn-success"
                  onClick={() => {
                    exportCSVFile(this.getHeaders(this.props), this.getRowData(this.props.data), "request_type")
                  }}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
