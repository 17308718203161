import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import LinkedInTag from "react-linkedin-insight";

LinkedInTag.init(978659);
LinkedInTag.track(1098595);
// Sentry.init({dsn: "https://2730576d421a4da5aa2447aa3be5b5d2@sentry.io/4458826"});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
