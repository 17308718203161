import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import BreadCrumbsNavbar from "../components/BreadCrumbsNavbar";
import Shimmer from "react-shimmer-effect";
import {
  Bold16,
  ButtonCore,
  calcTime,
  Card,
  Main,
  Container,
} from "../components/common";
// import StartingModal from "../components/StartingModal";
import RequestEmptyState from "./RequestEmptyState";
import RequestTable from "./RequestTable";
import DeleteModal from "../components/DeleteModal";
import GenericErrorState from "../components/GenericErrorState";
import AppContext from "../context/AppContext";
import Axios from "axios";

function BulkRequests(props) {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [countRequests, setCountRequests] = useState("");
  const [APIKey, setAPIKey] = useState("");
  const [genericError, setGenericError] = useState(false);
  const [deleteId, setDeleteID] = useState("");
  const context = useContext(AppContext);

  const history = useHistory();

  useEffect(() => {
    const key = context.deviceConnectApiKey;

    fetchKey();
  }, [context.stage]);

  const fetchKey = async () => {
    let fT = context.token;
    const ftData = JSON.parse(fT);

    var myHeaders = new Headers();
    myHeaders.append("token", ftData.Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let response;
    try {
      response = await fetch(
        `https://dashboardapi.finbox.in/v1/developer/device_connect`,
        requestOptions
      );
    } catch (err) {
      context.removeToken();
      window.location.href = "/";
      return;
    }

    // const result = response.json;

    const resp = await response.text();
    const json = JSON.parse(resp);
    const envs = json.Data.Env;

    // console.log(json.Data.Env.prod[1].Keys[0].Value);

    if (context.stage in envs) {
      const myAPIKey = envs[context.stage][1]["Keys"][0].Value;
      setAPIKey(myAPIKey);
      context.updateDeviceConnectApiKey(myAPIKey);
      fetchRequestList(1, myAPIKey);
    } else {
      history.push("/empty/device_connect");
    }
  };

  const deleteItem = (id) => {
    const key = context.deviceConnectApiKey;
    const resp = context.token;
    if (!resp) {
      window.location.href = "/";
    }
    const respJSON = JSON.parse(resp);
    var myHeaders = new Headers();
    myHeaders = {
      "content-type": "application/json",
      Accept: "application/json",
      "token": respJSON.Token
    };
    var myInit100 = {
      method: "Post",
      headers: myHeaders,
      cache: "default",
      // body: {}
    };
    Axios.delete("https://dashboardapi.finbox.in/risk_sdk/requests/" + id  , {headers: myHeaders, data : {request_id :  id}})
      .then((result) => fetchRequestList())
      .catch(function (error) {
        
        if(error)alert("Please Try again later");
      })
      
    setDeleteID("");
  };

  const fetchRequestList = async (page = 1, key) => {
    if (!key) key = context.deviceConnectApiKey;
    console.log(currentPage, totalPages);
    const resp = context.token;
    if (!resp) {
      window.location.href = "/";
    }
    const respJSON = JSON.parse(resp);
    var myHeaders = new Headers();
    myHeaders = {
      "content-type": "application/json",
      Accept: "application/json",
      "token": respJSON.Token
    };
    var myInit100 = {
      method: "Get",
      headers: myHeaders,
    };
    let response = await fetch(
      "https://dashboardapi.finbox.in/risk_sdk/requests" + "?page=" + page + "&size=" + 10,
      myInit100
    )

    if (response.ok){
      let result = await response.json();
        setData(result["results"]);
        setCountRequests(result.count);
        setTotalPages(
          Math.ceil(result.count / 10) == 0 ? 1 : Math.ceil(result.count / 10)
        );
    }else if(response.status=="401"){
      setGenericError(true);
    }

    setLoading(false);
  };

  return (<>
{  !genericError ?
    <Main>
      <BreadCrumbsNavbar
        color="blue"
        loc={[
          { name: "DeviceConnect", link: "/deviceconnect" },
          { name: "Requests", link: props.location.pathname },
        ]}
      />
      <Container style={{ background: "white", overflowY: "scroll" }}>
        {data === null ? (
          <Shimmer>
            <div
              style={{
                width: "92%",
                height: "100px",
                alignSelf: "center",
                marginTop: "16px",
                borderRadius: "8px",
              }}
            />
            <div
              style={{
                width: "92%",
                height: "200px",
                alignSelf: "center",
                marginTop: "16px",
                borderRadius: "8px",
              }}
            />
          </Shimmer>
        ) : data.length != "" && data.length != 0 ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px ",
              }}
            >
              <Bold16>Batch Requests</Bold16>
              <ButtonCore
                onClick={() => history.push("/deviceconnect/newrequest")}
              >
                Create new
              </ButtonCore>
            </div>
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 10,
                border: "1px solid #ECECEC",
                padding: 0,
                height: "fit-content",
                overflowX: "scroll",
              }}
            >
              <RequestTable
                loading={loading}
                data={data}
                currentPage={currentPage}
                totalPages={totalPages}
                setDeleteID={(id) => setDeleteID(id)}
                fetchData={(page) => {
                  setCurrentPage(page);
                  fetchRequestList(page);
                }}
              />
            </Card>
          </div>
        ) : data.length == 0 ? (
          <RequestEmptyState />
        ) : (
          ""
        )}
      </Container>
      {deleteId && (
        <DeleteModal
          setDeleteID={(id) => setDeleteID(id)}
          deleteItem={deleteItem}
          deleteId={deleteId}
        />
      )}
    </Main>: genericError? <GenericErrorState heading="You do not have access to Bulk Requests." message="Please contact FinBox team to get access"/>:""
    }
    </>
  );
}

export default BulkRequests;
